import React from 'react'
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { Box, Typography } from '@mui/material';

const MatchNotFound = ({ title, newMatch }) => {
  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "12%",
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "0px",
            }}
          >

            <Box
              component={"img"}
              sx={{
                marginTop: "0px",
                paddingTop: "0px",
                height: 150,
                width: 150,
                maxHeight: {
                  xs: 60, // 0px ve üzeri ekran genişlikleri için
                  sm: 70, // 600px ve üzeri ekran genişlikleri için
                  md: 70, // 900px ve üzeri ekran genişlikleri için
                  lg: 90, // 1200px ve üzeri ekran genişlikleri için
                  xl: 90, // 1536px ve üzeri ekran genişlikleri için,
                },
                maxWidth: {
                  xs: 60, // 0px ve üzeri ekran genişlikleri için
                  sm: 70, // 600px ve üzeri ekran genişlikleri için
                  md: 70, // 900px ve üzeri ekran genişlikleri için
                  lg: 90, // 1200px ve üzeri ekran genişlikleri için
                  xl: 90, // 1536px ve üzeri ekran genişlikleri için,
                },
              }}
              alt="."
              src={noMatchFound}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#001542",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "0px",
                }}
              >
                {newMatch && newMatch == true ? "Eşleşme Bulunamadı" : title}
              </p>
              {newMatch && (
                <div className="card">
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "10px", // 0px ve üzeri ekran genişlikleri için
                        sm: "12px", // 600px ve üzeri ekran genişlikleri için
                        md: "12px", // 900px ve üzeri ekran genişlikleri için
                        lg: "13px", // 1200px ve üzeri ekran genişlikleri için
                        xl: "12px", // 1536px ve üzeri ekran genişlikleri için,
                      },
                      fontWeight: 400,
                      textAlign:"center",
                      paddingLeft:"100px",
                      paddingRight:"100px",
                      color: {
                        xs: "#001542", // 0px ve üzeri ekran genişlikleri için
                        sm: "#001542", // 600px ve üzeri ekran genişlikleri için
                        md: "#001542", // 900px ve üzeri ekran genişlikleri için
                        lg: "#001542", // 1200px ve üzeri ekran genişlikleri için
                        xl: "#001542" // 1536px ve üzeri ekran genişlikleri için
                      },
                    }}
                  >

                    Talebiniz ile eşleşme olduğunda size bildirimler göndereceğiz.


                  </Typography>

                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default MatchNotFound