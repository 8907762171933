
//export var API_URL = "https://saklasamani.westeurope.cloudapp.azure.com";


export var API_URL = "https://api.bitonis.com";
export var MEET_URL ="jitsitest.bitonis.com"// "meet.sokak.com.tr";





