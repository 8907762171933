import React, { useEffect, useState } from "react";
import api from "../../../../../../../../utility/Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomButton from "../../../../../../../widgets/buttons/CustomButton";
const ChangeDemandStatus = ({ jobId, defaultJobStatus }) => {
  const [demandStatus, setDemandStatus] = useState(defaultJobStatus);
  useEffect(() => {
    if (jobId) {
      getDemandStatusValue(jobId).then((res) => {
        if (res.succeeded == true) {
          setDemandStatus(res.data.isActive);
        } else {
          setDemandStatus(defaultJobStatus); //hata vermesi durumunda orjinal talep detayına gelirkenki durumu aldım
        }
      });
    }
  }, [jobId]);

  function getDemandStatusValue(jobId) {
    // Talep Pasive Alma
    return api
      .get("/job/status/" + jobId)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  function passiveBtn(e) {
    e.preventDefault();
    demandChangeStatus({
      jobId: jobId,
      isActive: false,
    }).then((res) => {
      if (res.succeeded == true) {
        toast.info(res.result.message);
        setDemandStatus(false);
      } else {
        //  alert("res ** * " + JSON.stringify(res))
        toast.info(res.error.message);
      }
    });
  }
  function demandChangeStatus(arg) {
    // Talep Pasive Alma
    return api
      .post("/job/status", arg)
      .then((response) => {
        //  alert("res* * * " + JSON.stringify(response));
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  const activeBtn = (event) => {
    event.preventDefault();
    demandChangeStatus({
      jobId: jobId,
      isActive: true,
    }).then((res) => {
      if (res.succeeded == true) {
        setDemandStatus(true);
        toast.info(res.result.message);
      } else {
        //  alert("res ** * " + JSON.stringify(res))
        toast.info(res.error.message);
      }
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {demandStatus && demandStatus ? (
        <CustomButton
          onTap={passiveBtn}
          backgroundColor={"red"}
          hoverBackgroundColor={"rgb(231, 32, 32)"}
          border={"none"}
          title={"Pasife Al"}
          width={{
            xs: "350px",
            sm: "400px",
            md: "400px",
            lg: "400px",
            xl: "400px",
          }}
          height={55}
        />
      ) : (
        <CustomButton
          onTap={activeBtn}
          border={"none"}
          title={"Aktife Al"}
          width={{
            xs: "350px",
            sm: "400px",
            md: "400px",
            lg: "400px",
            xl: "400px",
          }}
          height={55}
        />
      )}
    </div>
  );
};

export default ChangeDemandStatus;
