import React, { Fragment, useState, Suspense, useEffect } from "react";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { connect, useDispatch } from "react-redux";
import { DropListAction, DashboardAction } from "../../../../action";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { setSelectPacketData } from "../../../../redux/redux-toolkit/packetSlice";
import "../../../../css/bitoniscss/packets.css"; //login csslarımiz
import LoadingDotsAnimation from "../../../widgets/LoadingDotsAnimation";

const Packets = (props) => {
  const [show, setShow] = useState(false);
  const [selectPlan, setSelectPlan] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (!props.companyList.success) {
      props.dispatch(DropListAction.companyMainList());
    }
    if (!props.planList.success) {
      props.dispatch(DropListAction.planListFunction());
    }
  }, []);

  const planOnClick = (plan, val) => {
    setShow(true);
    setSelectPlan(val);
  };

  const Plans = () => {
    var planList = [];

    return (
      <div className="packetsContainer">
        {props.planList.planList === null ||
        props.planList.planList == [] ||
        props.planList.planList == undefined ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : (
          <div>
            <Row className="top-equal">
              {props.planList.planList.map((item) => {
                return item.planId == 1 ? (
                  <h1></h1>
                ) : (
                  <Plan
                    key={item.planId}
                    header={item.planName}
                    price={item.price}
                    features={item.properties}
                    buttonLabel={"Satın Al"}
                    outline={false}
                    click={item.planId}
                    item={item}
                  />
                );
              })}
            </Row>
          </div>
        )}
      </div>
    );
  };
  const Plan = (props) => {
    const dispatch = useDispatch();

    return (
      <Col md={4} className="tab-equal">
        <div
          className="cardPacket"
          style={{
            cursor: "pointer",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition:
              "transform 0.2s, box-shadow 0.2s, background 0.2s, border 0.2s",
            borderRadius: "8px",
            background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
            border: "2px solid #001542",
          }}
          onClick={() => {}}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = "translateY(-5px)";
            e.currentTarget.style.boxShadow = "0 10px 20px rgba(0, 0, 0, 0.2)";
            e.currentTarget.style.background =
              "linear-gradient(145deg, #e6e6e6, #ffffff)";
            e.currentTarget.style.border = "2px solid #fdc303";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = "translateY(0)";
            e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
            e.currentTarget.style.background =
              "linear-gradient(145deg, #ffffff, #e6e6e6)";
            e.currentTarget.style.border = "2px solid #001542";
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
          >
            <div className="pricetitle">{`₺${props.price}`} </div>
          </div>
          <div
            className="cardHeader"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <h4 className="headerTitle">
              {props.header && props.header.toLocaleString("tr-TR")}
            </h4>
          </div>
          <div className="cardBody">
            <ul
              className="list-unstyled mt-0 mb-4"
              style={{
                fontSize: "14px",
                color: "#001542",
                fontWeight: 400,
              }}
            >
              {props.features.map((feature, i) => (
                <li key={i}>
                  <i
                    className="fa-solid fa-tag"
                    style={{
                      fontSize: "15px",
                      color: "#FDC303",
                      margin: "0px 5px 8px 0px",
                    }}
                  ></i>{" "}
                  {feature.value}
                </li>
              ))}
            </ul>
          </div>

          <div className="text-center" >
            <button
              type="submit"
              className="btn btn-primary btn-block"
              id="buttonBody"
              onClick={(event) => {
                planOnClick(event, props.click);
                const planIdSend = props.click;
                const dataMap = new Map();
                dataMap.set("planIdSend", planIdSend);
                dataMap.set("planItem", props.item);
                history.push({
                  pathname: "/packetsBuy",
                  state: { dataMap },
                });

                //      history.push(`/packetsBuy/${planIdSend}`);
              }}
            >
              {props.buttonLabel}
            </button>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="row center-items ">
        <Plans />
      </div>
    </Fragment>
  );
};

function mapState(state) {
  let payment = state.payment;
  let companyList = state.companyListMain;
  let planList = state.planList;
  return {
    payment,
    companyList,
    planList,
  };
}
export default connect(mapState)(Packets);
