import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import FilterModal from "./Modal/FilterModal";
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MatchNotFound from "./MatchNotFound";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import TabelItem from "../../MatchDetail/blueMatchDetail/components/TabelItem";
import BlueDemandItem from "../../DemandAllShow/widgets/BlueDemandItem";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import DemandNotFound from "../../DemandAllShow/Common/DemandNotFound";
import BlueDemandMatchPageItem from "../../DemandAllShow/widgets/BlueDemandMatchPageItem";
import { toast } from "react-toastify";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { DoneOutline, DoneOutlineRounded } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import AvatarGlow from "../../../../../../../widgets/components/AvatarGlow";
import PackageCandidateDemandUsageScreen from "../../DemandAllShow/PackageCandidateDemandUsageScreen";

const ShowBlueMatchShow = () => {
  useCurrentUserActionPageLog(18, null); //loglama bilgisini apiye gönderir
  const [blueDemandList, setBlueDemandList] = useState(null);
  const [showDemandCopy, setShowDemandCopy] = useState(null);
  const [demandItem, setDemandItem] = useState(null);
  const dispatch = useDispatch();
  function handleCloseDemandCopy() {
    setShowDemandCopy(false);
  }
  const [showPackageUseScreen, setShowPackageUseScreen] = useState(null);

  function handlePackageUseScreenClose() {
    setShowPackageUseScreen(false);
  }
  const [show, setShow] = useState(null);
  const [search, setSearch] = useState("");

  var demandFilterList = [
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState([
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ]);

  useEffect(() => {
    getAllShowBlue(null);
  }, []);

  function getAllShowBlue(isActive) {
    var sendData = { isActive: isActive, positionType: 1 };
    DemandsServices.demandsBlue(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setBlueDemandList([]);
          demand.data.map((value) => {
            setBlueDemandList((prev) => [...prev, value]);
          });
        }
      },
      (error) => { }
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  function handleClose() {
    setShow(false);
  }
  const history = useHistory();

  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    blueDemandList && page === null
      ? blueDemandList
      : blueDemandList &&
      blueDemandList.slice((page - 1) * pageSize, page * pageSize);

  return (
    <>

      <div>
        {blueDemandList && blueDemandList.length >= 0 && (
          <Tooltip
            title="Tıklayın"
            arrow
            sx={{
              "&:hover": {
                color: "white", // Hover sırasında metin rengi değiştir
              },
            }}
          >
            <Fab
              onClick={() => {
                setShowPackageUseScreen(true);
              }}
              sx={{
                position: "fixed", // absolute yerine fixed kullanıldı
                bottom: (theme) => theme.spacing(3), // Alt köşeye sabitler
                right: {
                  xs: "0px",
                  sm: "0px",
                  md: "20px",
                  lg: "35px",
                  xl: "35px",
                },
                backgroundColor: "white",
                border: "1px dotted #001542",
              }}
              color="transparent"
            >
              <AvatarGlow
                initialRadius={25}
                valueMaxScale={4}
                glowColor="#001542"
                waveDuration={3}
                startColor="#001542"
                endColor="#001542"
                width={50}
                height={50}
              >
                <Fab
                  sx={{
                    position: "relative", // İç bileşende position relative kullanımı
                    backgroundColor: "white",
                    width: "50px",
                    height: "50px",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#001542",
                    },
                  }}
                  color="primary"
                >
                  <DoneOutlineRounded
                    sx={{
                      color: "#001542",
                      "&:hover": {
                        color: "#001542",
                      },
                    }}
                  />
                </Fab>
              </AvatarGlow>
            </Fab>
          </Tooltip>
        )}
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="Pozisyona göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />


          </InputGroup>
        </div>
        {blueDemandList === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : blueDemandList && paginatedList && paginatedList.length > 0 ? (
          <div>
            {paginatedList &&
              paginatedList.map((demand, index) => {
                return paginatedList &&
                  paginatedList[index].positionName
                    .toLowerCase()
                    .includes(search.toLowerCase()) ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <BlueDemandMatchPageItem
                      positionName={demand.positionName}
                      demand={demand}
                      onTap={() => {
                        if (demand.didDemandExpired === false) {
                          const dataMap = new Map();

                          dataMap.set("jobId", demand.jobId);
                          dataMap.set("positionName", demand.positionName);
                          dataMap.set("paymentPlanType", demand.paymentPlanType);

                          history.push({
                            pathname: "/demands/blueCurrentDemandMatchShow",
                            state: { dataMap },
                          });
                        } else {
                          toast.error(
                            "Talebinizle eşleşen adayları görme süreniz doldu. Yeni bir talep oluşturarak eşleşme sağlayabilirsiniz.",
                            {
                              autoClose: 5000,
                              onClose: () => {
                                setTimeout(() => { }, 1000);
                              },
                            }
                          );
                        }
                      }}


                    />



                  </div>
                ) : (
                  <div></div>
                );
              })}
          </div>
        ) : (
          <MatchNotFound newMatch={true} />
        )}
        {blueDemandList &&
          blueDemandList.length > 0 &&
          blueDemandList.length > pageSize && (
            <PaginationPage
              items={blueDemandList}
              page={page}
              pageSize={pageSize}
              onChange={(value) => {
                if (value) {
                  setPage(value);
                }
              }}
              onTapAllShowButton={() => {
                setPage(null);
              }}
            />
          )}

      </div>
      <PackageCandidateDemandUsageScreen
        show={showPackageUseScreen}
        handleClose={handlePackageUseScreenClose}
        candidateType={"blue"}
      />

    </>
  );
};


export default ShowBlueMatchShow;



/*

import React, { useState, useEffect, useRef } from "react";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../../services";
import FilterModal from "./Modal/FilterModal";
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MatchNotFound from "./MatchNotFound";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import TabelItem from "../../MatchDetail/blueMatchDetail/components/TabelItem";

const ShowBlueMatchShow = () => {
  useCurrentUserActionPageLog(18, null); //loglama bilgisini apiye gönderir
  var matchSelectedList = [
    { title: "İşe Alındı", status: 2 },
    { title: "İşe Alınmadı", status: 3 },
    { title: "Yeni Eşleşme", status: 1 },
    { title: "Görüşmeye Bekleniyor", status: 9 },
  ];
  const [demandBlueMatchData, setDemandBlueMatchData] = useState(null);
  const [search, setSearch] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([
    { title: "Yeni Eşleşme", status: 1 },
    { title: "Görüşmeye Bekleniyor", status: 9 },
  ]);

  useEffect(() => {
    getMatchBlue(selectedStatus);
  }, []);

  function getMatchBlue(selectedStatus) {
    DemandsServices.demandMatchBlue(selectedStatus).then(
      (demandMatch) => {
        //  alert("demandMatch * *" + JSON.stringify(demandMatch));
        if (demandMatch && demandMatch.succeeded === true) {
          setDemandBlueMatchData([]);
          setDemandBlueMatchData(demandMatch.data.pagedData);
        }
      },
      (error) => {}
    );
  }

  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    demandBlueMatchData && page === null
      ? demandBlueMatchData
      : demandBlueMatchData &&
        demandBlueMatchData.slice((page - 1) * pageSize, page * pageSize);

  return (
    <>
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "25px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="İsme göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />
          </InputGroup>
        </div>

        {demandBlueMatchData === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : demandBlueMatchData && paginatedList && paginatedList.length > 0 ? (
          paginatedList && (
            <div
              style={{
                marginBottom: "10%",
                marginTop: "3%",
              }}
            >
              {paginatedList.map((matchData, index) => {
                return paginatedList[index].candidateNameSurname
                  .toLowerCase()
                  .includes(search.toLowerCase()) ? (
                  <div
                    onClick={() => {
                      const dataMap = new Map();
                      dataMap.set("candidateId", matchData.candidateId);
                      dataMap.set("matchId", matchData.matchId);
                      dataMap.set("status", matchData.status);
                      dataMap.set("jobId", matchData.jobId);
                      history.push({
                        pathname: "matchShow/blueMatchDetail",

                        state: { dataMap },
                      });
                    }}
                  >
                    <TabelItem
                      key={matchData.candidateId}
                      candidateNameSurname={matchData.candidateNameSurname}
                      positionName={matchData.positionName}
                      status={matchData.status}
                      candidateId={matchData.candidateId}
                      matchCreateDate={matchData.matchCreateDate}
                    />
                  </div>
                ) : (
                  <div></div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {demandBlueMatchData &&
                  demandBlueMatchData.length > 0 &&
                  demandBlueMatchData.length > pageSize && (
                    <PaginationPage
                      items={demandBlueMatchData}
                      page={page}
                      pageSize={pageSize}
                      onChange={(value) => {
                        if (value) {
                          setPage(value);
                        }
                      }}
                      onTapAllShowButton={() => {
                        setPage(null);
                      }}
                    />
                  )}
              </div>
            </div>
          )
        ) : (
          <MatchNotFound title={""} newMatch={true} />
        )}
      </div>
      <FilterModal
        show={show}
        handleClose={handleClose}
        approval={(value) => {
          if (value) {
            setSelectedStatus([]);
            value.map((val) => {
              setSelectedStatus((prev) => [...prev, val]);
            });
            getMatchBlue(value);
            setShow(false);
          } else {
            setShow(false);
          }
        }}
        matchSelectedList={matchSelectedList}
        selectedStatus={selectedStatus}
      />
    </>
  );
};

export default ShowBlueMatchShow;

*/