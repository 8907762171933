import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { AccountServices } from "../../../../../../services/AccountServices";
import "../../../../../../css/bitoniscss/Home/cardsettings.css";
import { UserService } from "../../../../../../services/UserService";
import LoadingDotsAnimation from "../../../../../widgets/LoadingDotsAnimation";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

const DeleteAccount = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [reasonList, setReasonList] = useState(null);
  const [selectReasonWhy, setSelectReasonWhy] = useState(null);
  useEffect(() => {
    disableReasonsListGet();
  }, []);

  function disableReasonsListGet() {
    AccountServices.disableReasonsListGet(2).then(
      async (getResponse) => {
        if (getResponse.succeeded === true) {
          if (getResponse.data && getResponse.data.length > 0) {
            setReasonList([]);
            getResponse.data.map((val) => {
              setReasonList((prev) => [...prev, val]);
            });
          } else {
            setReasonList([]);
          }
        } else {
          setReasonList([]);
        }
      },
      (error) => { }
    );
  }

  function onSubmit(form) {
    if (
      selectReasonWhy === null ||
      selectReasonWhy === undefined ||
      selectReasonWhy === ""
    ) {
      toast.error("Lütfen Hesabınızı Neden Silmek İstediğinizi Seçin", {
        autoClose: 2000,
        onClose: () => {
          setTimeout(() => { }, 100);
        },
      });
    } else {
      var sendData = {
        userName: form.emailDelete,
        password: form.passwordDelete,
        employerDisableReason: {
          disableReasonId: selectReasonWhy.id,
          disableReasonDescription: selectReasonWhy.description,
        },
      };

      AccountServices.deleteAccount(sendData).then(
        async (getResponse) => {
          if (getResponse.succeeded === true) {
            toast.info(getResponse.result.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  UserService.logout();
                  history.push("/");
                  window.location.href = window.location.href;
                }, 100);
              },
            });
          } else {
            toast.info(getResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => { }, 100);
              },
            });
          }
        },
        (error) => { }
      );
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        id="settingBody"
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        {" "}
        <div
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            textAlign: "center"
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "600",
              fontSize:"20px",
            }}
          >Hesap Sil</p>
        </div>
        <div
          className="card"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <div
            className="card-header"
            style={{
              borderBottom: "none",
              marginLeft: "18px",
              paddingLeft: "18px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p></p>
            </div>
          </div>
          <div
            className="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group col-md-12">
                <label className="mb-1">
                  <strong className="labelClass" style={{color:"#001542"}}>
                    Hesap Bilgileri{" "}
                    <label
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </label>
                  </strong>
                </label>
                <input
                  type="email"
                  className="form-control"
                  name="emailDelete"
                  key={"emailDelete"}
                  style={{
                    border: "1px solid rgb(203, 200, 200)",
                    padding: "10px",
                    marginLeft: "0px",
                    backgroundColor: "#f8f8f9",
                    borderRadius: "6px",
                    width: "100%",
                    color: "#001542",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                  {...register("emailDelete", { required: true })}
                  placeholder="E-Posta Adresiniz"
                />
                {errors.emailDelete && (
                  <div className="text-danger fs-12">Email Boş Bırakılamaz</div>
                )}
                <div style={{ position: "relative" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    key={"passwordDelete"}
                    autoComplete="new-password"
                    style={{
                      border: "1px solid rgb(203, 200, 200)",
                      padding: "10px",
                      marginLeft: "0px",
                      backgroundColor: "#f8f8f9",
                      borderRadius: "6px",
                      width: "100%",
                      color: "#001542",
                      fontWeight: "500",
                      fontSize: "15px",
                      marginTop: "10px",
                    }}
                    name="passwordDelete"
                    {...register("passwordDelete", { required: true })}
                    placeholder="Şifre"
                  />
                  <span
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <i className="far fa-eye"></i>
                    ) : (
                      <i className="far fa-eye-slash"></i>
                    )}
                  </span>
                </div>
                {errors.passwordDelete && (
                  <div className="text-danger fs-12">Şifre Boş Bırakılamaz</div>
                )}
              </div>
              <div className="form-group col-md-12">
                <label className="mb-1">
                  <strong className="labelClass" style={{color:"#001542"}}>
                    Hesabını Neden Silmek İstiyorsun?{" "}
                    <label
                      style={{
                        color: "red",
                      }}
                    >
                      {" "}
                      *
                    </label>
                  </strong>
                </label>
                {reasonList === undefined ||
                  (reasonList === null ? (
                    <div >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <LoadingDotsAnimation />
                      </div>


                    </div>
                  ) : (
                    reasonList &&
                    reasonList.map((value, index) => {
                      return (
                        <div style={{}}>
                          <div
                            onClick={() => {
                              setSelectReasonWhy(value);
                            }}
                            style={
                              selectReasonWhy && selectReasonWhy.id === value.id
                                ? {
                                  display: "flex",
                                  border: "1px solid #F6F5F5",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  height: "50px",
                                  margin: "10px",
                                  backgroundColor: "#F3F8FF",
                                  cursor: "pointer",
                                }
                                : {
                                  display: "flex",
                                  border: "1px solid #F6F5F5",
                                  padding: "10px",
                                  borderRadius: "8px",
                                  height: "50px",
                                  margin: "10px",
                                  cursor: "pointer",
                                }
                            }
                          >
                            <RadioButtonWithTitle
                              title={value.description}
                              onChange={() => {
                                setSelectReasonWhy(value);
                              }}
                              checked={
                                selectReasonWhy &&
                                selectReasonWhy.id === value.id
                              }
                            />
                          </div>
                        </div>
                      );
                    })
                  ))}
              </div>
              {reasonList && reasonList.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",   marginTop:"15px"
                  }}
                >
                  {" "}
                
                  <CustomButton title={"Hesabını Sil"} width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }} />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
function RadioButtonWithTitle({ title, checked, onChange }) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      />
      <label
        className="form-check-label"
        style={{
          fontSize: "13px",
          color: "#001542",
          fontWeight: 450,
          margin: "5px",
          cursor: "pointer",
        }}
      >
        {title}
      </label>
    </div>
  );
}
