import {
  YearOfExperienceContants,
  PositionListContants,
  WayOfWorkingContants,
  NationalityListContants,
  AgeRangeContants,
  DriverLicenseTypeContants,
  ProgramListContants,
  CertificatesListContants,
  CompetencesListContants,
  CountryListContants,
  CityListContants,
  CountyListContants,
  DistrictListContants,
  CompanyListConstants,
  CompanyLocationConstants,
  SectorListContants,
  DepartmentsListContants,
  CompanyMListConstants,
  JobConstants,
  PlanListConstants,
  CompanyItemLocationConstants,
  CountyJobSeekerListContants,
  SearchCompanyListConstants,
  DisabilityOfDegreeList,
} from "../constants";
import { DemandsServices, DropListServices } from "../services";
import { toast } from "react-toastify";

export const DropListAction = {
  positionList,
  yearOfExperience,
  wayOfWorking,
  nationalityList,
  ageRangeList,
  driverLicenceType,
  programList,
  certificatesList,
  competencesList,
  countryList,
  cityList,
  countyList,
  districtList,
  companyList,
  companyLocation,
  clearCompanyList,
  sectorList,
  departmentList,
  blueNewDemand,
  blueUpdateDemand,
  companyMainList,
  planListFunction,
  companyItemLocation,
  countyJobSeekerList,
  allCityList,
  allCountyList,
  getProfileFillPcc,
  searchCompanyList,
  getDisabilityOfDegreeList,
};
// Mavi Yaka Bilgileri

function companyList(arg) {
  //Şirket Bilgileri
  return (dispatch) => {
    dispatch(request());
    DropListServices.companyList(arg).then(
      (companyList) => {
        var list = [];
        var searchCompanyListN = [];
        list = companyList;
        list.forEach((element) => {
          if (element.isActive == true) {
            //  alert("element " + JSON.stringify(element));
            searchCompanyListN.push(element);
          }
        });
        dispatch(success(searchCompanyListN));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: CompanyListConstants.GET_ALL_REQUEST,
    };
  }

  function success(companyList) {
    return {
      type: CompanyListConstants.GET_ALL_SUCCESS,
      companyList,
    };
  }

  function failure(error) {
    return {
      type: CompanyListConstants.GET_ALL_FAILURE,
      error,
    };
  }
}

function searchCompanyList() {
  //Şirket Bilgileri
  return (dispatch) => {
    dispatch(request());
    DropListServices.searchCompanyList().then(
      (searchCompanyList) => {
        var list = [];
        var searchFindCompanyList = [];
        list = searchCompanyList;
        list.forEach((element) => {
          searchFindCompanyList.push(element);
        });
        dispatch(success(searchFindCompanyList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: SearchCompanyListConstants.GET_ALL_REQUEST,
    };
  }

  function success(searchCompanyList) {
    return {
      type: SearchCompanyListConstants.GET_ALL_SUCCESS,
      searchCompanyList,
    };
  }

  function failure(error) {
    return {
      type: SearchCompanyListConstants.GET_ALL_FAILURE,
      error,
    };
  }
}

function companyLocation(arg) {
  //Şirket Lokasyon Bilgileri
  return (dispatch) => {
    dispatch(request());
    DropListServices.companyLocation(arg).then(
      (companyLocation) => {
        dispatch(success(companyLocation));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: CompanyLocationConstants.GET_ALL_REQUEST,
    };
  }

  function success(companyLocation) {
    return {
      type: CompanyLocationConstants.GET_ALL_SUCCESS,
      companyLocation,
    };
  }

  function failure(error) {
    return {
      type: CompanyLocationConstants.GET_ALL_FAILURE,
      error,
    };
  }
}
//-  - - -- -- - - - - - -

function companyItemLocation(arg) {
  //talep yayınlarken kullanılır
  //Şirket company seçilince ilgili şirketin şirket lokasyonları
  return (dispatch) => {
    dispatch(request());
    DropListServices.companyItemLocation(arg).then(
      (companyItemLocation) => {
        dispatch(success(companyItemLocation));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: CompanyItemLocationConstants.GET_ALL_REQUEST,
    };
  }

  function success(companyItemLocation) {
    return {
      type: CompanyItemLocationConstants.GET_ALL_SUCCESS,
      companyItemLocation,
    };
  }

  function failure(error) {
    return {
      type: CompanyItemLocationConstants.GET_ALL_FAILURE,
      error,
    };
  }
}

//- - - - - - - - -- - - -
function companyMainList(arg) {
  //Şirket Bilgileri
  return (dispatch) => {
    dispatch(request());
    DropListServices.companyListM(arg).then(
      (companyList) => {
        dispatch(success(companyList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: CompanyMListConstants.GET_ALL_REQUEST,
    };
  }

  function success(companyList) {
    return {
      type: CompanyMListConstants.GET_ALL_SUCCESS,
      companyList,
    };
  }

  function failure(error) {
    return {
      type: CompanyMListConstants.GET_ALL_FAILURE,
      error,
    };
  }
}

function planListFunction(arg) {
  //Şirket Bilgileri
  return (dispatch) => {
    dispatch(request());
    DropListServices.planList(arg).then(
      (planList) => {
        //alert(JSON.stringify (planList))
        var list=[];
        planList.map((val)=>{
          if(
            val.planId===5
          ){}
          else{
            list.push(val)
          }
        })
        dispatch(success(list));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return {
      type: PlanListConstants.GET_ALL_REQUEST,
    };
  }

  function success(planList) {
    return {
      type: PlanListConstants.GET_ALL_SUCCESS,
      planList,
    };
  }

  function failure(error) {
    return {
      type: PlanListConstants.GET_ALL_FAILURE,
      error,
    };
  }
}

function positionList(arg) {
  // Pozisyon listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.positionList(arg).then(
      (positionList) => {
        dispatch(success(positionList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: PositionListContants.GET_ALL_REQUEST };
  }

  function success(positionList) {
    return { type: PositionListContants.GET_ALL_SUCCESS, positionList };
  }

  function failure(error) {
    return { type: PositionListContants.GET_ALL_FAILURE, error };
  }
}
function yearOfExperience(arg) {
  // Tecrübe Yılı listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.yearOfExperience(arg).then(
      (yearOfExperience) => {
        dispatch(success(yearOfExperience));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: YearOfExperienceContants.GET_ALL_REQUEST };
  }

  function success(yearOfExperience) {
    return { type: YearOfExperienceContants.GET_ALL_SUCCESS, yearOfExperience };
  }

  function failure(error) {
    return { type: YearOfExperienceContants.GET_ALL_FAILURE, error };
  }
}
function getDisabilityOfDegreeList() {
  // engellilik seviyesi listesini getirir.
  return (dispatch) => {
    dispatch(request());
    DropListServices.getDisabilityOfDegreeList().then(
      (disabilityOfDegreeList) => {
        dispatch(success(disabilityOfDegreeList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DisabilityOfDegreeList.GET_ALL_REQUEST };
  }

  function success(disabilityOfDegreeList) {
    return {
      type: DisabilityOfDegreeList.GET_ALL_SUCCESS,
      disabilityOfDegreeList,
    };
  }

  function failure(error) {
    return { type: DisabilityOfDegreeList.GET_ALL_FAILURE, error };
  }
}
function wayOfWorking(arg) {
  // Çalışma Şekli
  return (dispatch) => {
    dispatch(request());
    DropListServices.wayOfWorking(arg).then(
      (wayOfWorking) => {
        dispatch(success(wayOfWorking));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: WayOfWorkingContants.GET_ALL_REQUEST };
  }

  function success(wayOfWorking) {
    return { type: WayOfWorkingContants.GET_ALL_SUCCESS, wayOfWorking };
  }

  function failure(error) {
    return { type: WayOfWorkingContants.GET_ALL_FAILURE, error };
  }
}
function nationalityList(arg) {
  // Uyruk
  return (dispatch) => {
    dispatch(request());
    DropListServices.nationalityList(arg).then(
      (nationalityList) => {
        dispatch(success(nationalityList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: NationalityListContants.GET_ALL_REQUEST };
  }

  function success(nationalityList) {
    return { type: NationalityListContants.GET_ALL_SUCCESS, nationalityList };
  }

  function failure(error) {
    return { type: NationalityListContants.GET_ALL_FAILURE, error };
  }
}
function ageRangeList(arg) {
  // Yaş Aralığı Listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.ageRangeList(arg).then(
      (ageRangeList) => {
        dispatch(success(ageRangeList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: AgeRangeContants.GET_ALL_REQUEST };
  }

  function success(ageRangeList) {
    return { type: AgeRangeContants.GET_ALL_SUCCESS, ageRangeList };
  }

  function failure(error) {
    return { type: AgeRangeContants.GET_ALL_FAILURE, error };
  }
}
function driverLicenceType(arg) {
  // Sürücü Belgesi Türü
  return (dispatch) => {
    dispatch(request());
    DropListServices.driverLicenceType(arg).then(
      (driverLicenceType) => {
        dispatch(success(driverLicenceType));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DriverLicenseTypeContants.GET_ALL_REQUEST };
  }

  function success(driverLicenceType) {
    return {
      type: DriverLicenseTypeContants.GET_ALL_SUCCESS,
      driverLicenceType,
    };
  }

  function failure(error) {
    return { type: DriverLicenseTypeContants.GET_ALL_FAILURE, error };
  }
}

function programList(arg) {
  return (dispatch) => {
    if (arg !== undefined) {
      arg = {
        positionGroupCode: arg,
      };

      dispatch(request());
      DropListServices.programList(arg).then(
        (programList) => {
          dispatch(success(programList));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    }
    return null;
  };

  function request() {
    return { type: ProgramListContants.GET_ALL_REQUEST };
  }

  function success(programList) {
    return { type: ProgramListContants.GET_ALL_SUCCESS, programList };
  }

  function failure(error) {
    return { type: ProgramListContants.GET_ALL_FAILURE, error };
  }
}
function certificatesList(arg) {
  // Sertifika Listesi
  return (dispatch) => {
    if (arg !== undefined) {
      arg = {
        positionGroupCode: arg,
      };

      dispatch(request());
      DropListServices.certificatesList(arg).then(
        (certificatesList) => {
          dispatch(success(certificatesList));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    }
    return null;
  };

  function request() {
    return { type: CertificatesListContants.GET_ALL_REQUEST };
  }

  function success(certificatesList) {
    return { type: CertificatesListContants.GET_ALL_SUCCESS, certificatesList };
  }

  function failure(error) {
    return { type: CertificatesListContants.GET_ALL_FAILURE, error };
  }
}
function competencesList(arg) {
  // Yetkinlikler Listesi
  return (dispatch) => {
    if (arg !== undefined) {
      arg = {
        positionGroupCode: arg,
      };

      dispatch(request());
      DropListServices.competencesList(arg).then(
        (competencesList) => {
          dispatch(success(competencesList));
        },
        (error) => {
          dispatch(failure(error));
        }
      );
    }
    return null;
  };

  function request() {
    return { type: CompetencesListContants.GET_ALL_REQUEST };
  }

  function success(competencesList) {
    return { type: CompetencesListContants.GET_ALL_SUCCESS, competencesList };
  }

  function failure(error) {
    return { type: CompetencesListContants.GET_ALL_FAILURE, error };
  }
}

function getProfileFillPcc(arg) {
  return (dispatch) => {
    if (arg !== undefined) {
      arg = {
        positionGroupCode: arg,
      };
      dispatch(requestPro());
      dispatch(requestCer());
      dispatch(requestCom());
      DropListServices.profileFillPcc(arg).then(
        (responseProfileFillPcc) => {
          dispatch(successPro(responseProfileFillPcc.programResponses));
          dispatch(successCer(responseProfileFillPcc.certificateResponses));
          dispatch(successCom(responseProfileFillPcc.competenceResponses));
        },
        (error) => {
          dispatch(failurePro(error));
          dispatch(failureCer(error));
          dispatch(failureCom(error));
        }
      );
    }
    return null;
  };

  function requestPro() {
    return { type: ProgramListContants.GET_ALL_REQUEST };
  }

  function successPro(programList) {
    return { type: ProgramListContants.GET_ALL_SUCCESS, programList };
  }

  function failurePro(error) {
    return { type: ProgramListContants.GET_ALL_FAILURE, error };
  }
  function requestCer() {
    return { type: CertificatesListContants.GET_ALL_REQUEST };
  }

  function successCer(certificatesList) {
    return { type: CertificatesListContants.GET_ALL_SUCCESS, certificatesList };
  }

  function failureCer(error) {
    return { type: CertificatesListContants.GET_ALL_FAILURE, error };
  }
  function requestCom() {
    return { type: CompetencesListContants.GET_ALL_REQUEST };
  }

  function successCom(competencesList) {
    return { type: CompetencesListContants.GET_ALL_SUCCESS, competencesList };
  }

  function failureCom(error) {
    return { type: CompetencesListContants.GET_ALL_FAILURE, error };
  }
}

function countryList(arg) {
  // Ülke Listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.countryList(arg).then(
      (countryList) => {
        dispatch(success(countryList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CountryListContants.GET_ALL_REQUEST };
  }

  function success(countryList) {
    return { type: CountryListContants.GET_ALL_SUCCESS, countryList };
  }

  function failure(error) {
    return { type: CountryListContants.GET_ALL_FAILURE, error };
  }
}
function cityList(arg) {
  // Şehir Listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.cityList(arg).then(
      (cityList) => {
        dispatch(success(cityList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CityListContants.GET_ALL_REQUEST };
  }

  function success(cityList) {
    return { type: CityListContants.GET_ALL_SUCCESS, cityList };
  }

  function failure(error) {
    return { type: CityListContants.GET_ALL_FAILURE, error };
  }
}
function allCityList(arg) {
  //farketmez de dahil Şehir Listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.allCityList(arg).then(
      (cityList) => {
        dispatch(success(cityList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CityListContants.GET_ALL_REQUEST };
  }

  function success(cityList) {
    return { type: CityListContants.GET_ALL_SUCCESS, cityList };
  }

  function failure(error) {
    return { type: CityListContants.GET_ALL_FAILURE, error };
  }
}
function countyList(arg) {
  // İlçe Listesi

  return (dispatch) => {
    dispatch(request());
    DropListServices.countyList(arg).then(
      (countyList) => {
        dispatch(success(countyList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CountyListContants.GET_ALL_REQUEST };
  }

  function success(countyList) {
    return { type: CountyListContants.GET_ALL_SUCCESS, countyList };
  }

  function failure(error) {
    return { type: CountyListContants.GET_ALL_FAILURE, error };
  }
}
function allCountyList(arg) {
  // İlçe  farketmez dahil listesi Listesi

  return (dispatch) => {
    dispatch(request());
    DropListServices.allCountyList(arg).then(
      (countyList) => {
        dispatch(success(countyList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CountyListContants.GET_ALL_REQUEST };
  }

  function success(countyList) {
    return { type: CountyListContants.GET_ALL_SUCCESS, countyList };
  }

  function failure(error) {
    return { type: CountyListContants.GET_ALL_FAILURE, error };
  }
}

function countyJobSeekerList(arg) {
  // İlçe Listesi

  return (dispatch) => {
    dispatch(request());
    DropListServices.countyJobList(arg).then(
      (countyJobSeekerList) => {
        dispatch(success(countyJobSeekerList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: CountyJobSeekerListContants.GET_ALL_REQUEST };
  }

  function success(countyJobSeekerList) {
    return {
      type: CountyJobSeekerListContants.GET_ALL_SUCCESS,
      countyJobSeekerList,
    };
  }

  function failure(error) {
    return { type: CountyJobSeekerListContants.GET_ALL_FAILURE, error };
  }
}

function districtList(arg) {
  // Semt Listesi
  return (dispatch) => {
    dispatch(request());
    //console.log("districtList", arg)
    DropListServices.districtList(arg).then(
      (districtList) => {
        // alert("districtList* * * " + JSON.stringify(districtList));
        dispatch(success(districtList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DistrictListContants.GET_ALL_REQUEST };
  }

  function success(districtList) {
    return { type: DistrictListContants.GET_ALL_SUCCESS, districtList };
  }

  function failure(error) {
    return { type: DistrictListContants.GET_ALL_FAILURE, error };
  }
}

function sectorList(arg) {
  // Sektör Listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.sectorList(arg).then(
      (sectorList) => {
        dispatch(success(sectorList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: SectorListContants.GET_ALL_REQUEST };
  }

  function success(sectorList) {
    return { type: SectorListContants.GET_ALL_SUCCESS, sectorList };
  }

  function failure(error) {
    return { type: SectorListContants.GET_ALL_FAILURE, error };
  }
}
function departmentList(arg) {
  // Departman Listesi
  return (dispatch) => {
    dispatch(request());
    DropListServices.departmentList(arg).then(
      (departmentList) => {
        dispatch(success(departmentList));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: DepartmentsListContants.GET_ALL_REQUEST };
  }

  function success(departmentList) {
    return { type: DepartmentsListContants.GET_ALL_SUCCESS, departmentList };
  }

  function failure(error) {
    return { type: DepartmentsListContants.GET_ALL_FAILURE, error };
  }
}
function blueNewDemand(arg, history, secondsRef) {
  // Mavi Yaka Yeni Talep Post
  return (dispatch) => {
    dispatch(request());
    DropListServices.blueNewDemand(arg).then(
      (blueNewDemand) => {
        if (blueNewDemand.succeeded === true) {
          var sendData = {
            actionTypeId: 4, //örnek olarak eşleşme detay açıldı
            duration: secondsRef.current,
            actionRelatedId: null,
          };

          DemandsServices.currentUserActionPageLog(sendData).then(
            (pageLogRes) => {
              if (pageLogRes && pageLogRes.succeeded === true) {
              }
            },
            (error) => {}
          );
          toast.success("Mavi Yaka Talebiniz Başarıyla Gerçekleşti", {
            autoClose: 2000,
            onClose: () => {
              history.push("/demands/allShow");
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast(blueNewDemand.error.message);
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: JobConstants.GET_ALL_REQUEST };
  }

  function success(blueNewDemand) {
    return { type: JobConstants.GET_ALL_SUCCESS, blueNewDemand };
  }

  function failure(error) {
    return { type: JobConstants.GET_ALL_FAILURE, error };
  }
}
function blueUpdateDemand(arg, history) {
  // Mavi Yaka Talep Güncelleme Post
  return (dispatch) => {
    dispatch(request());
    DropListServices.blueUpdateDemand(arg).then(
      (blueUpdateDemand) => {
        if (blueUpdateDemand.succeeded === true) {
          toast.success(blueUpdateDemand.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(blueUpdateDemand.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: JobConstants.GET_ALL_REQUEST };
  }

  function success(blueUpdateDemand) {
    return { type: JobConstants.GET_ALL_SUCCESS, blueUpdateDemand };
  }

  function failure(error) {
    return { type: JobConstants.GET_ALL_FAILURE, error };
  }
}
function clearCompanyList() {
  return (dispatch) => {
    return dispatch({ type: CompanyListConstants.GET_CLEAR });
  };
}
