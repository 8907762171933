import { Button } from "@mui/material";
import React from "react";

const CustomButton = ({
  title,
  onTap,
  backgroundColor,
  color,
  width,
  border,
  hoverBorder,
  hoverBackgroundColor,
  hoverTextColor,
  height,
  showShadow,
  borderRadius,
}) => {
  return (
    <Button
      onClick={onTap == undefined || onTap == null ? null : onTap}
      type="submit"
      sx={{
        borderRadius: 35,
        color: color ?? "white",
        backgroundColor: backgroundColor ?? "#001542",
        padding: "15px 0px 15px 0px",
        fontSize: "18px",
        borderRadius: borderRadius ?? "8px",
        cursor: "pointer",
        marginTop: "0px",
        height: height ?? "60px",
        border:
          showShadow === undefined ||
          showShadow === null ||
          showShadow === false
            ? border ?? "1px solid #001542"
            : "none",
        width:
          width === null || width === undefined || width === false
            ? {
                xs: "280px",
                sm: "300px",
                md: "350px",
                lg: "380px",
                xl: "380px",
              }
            : width,
        fontSize: {
          xs: "13px",
          sm: "13px",
          md: "15px",
          lg: "15px",
          xl: "15px",
        },
        boxShadow:
          showShadow === undefined ||
          showShadow === null ||
          showShadow === false
            ? ""
            : "0px 0px 8px 0px #22668d", // Flutter'daki BoxShadow'un MUI karşılığı
        "&:hover": {
          backgroundColor: hoverBackgroundColor ?? "#01256e",
          color: hoverTextColor ?? "white",
          border: hoverBorder ?? "none",
        },
        textTransform: "capitalize",
      }}
      variant="contained"
    >
      {title}
    </Button>
  );
};

export default CustomButton;

/*

 <Button
      onClick={onTap == undefined || onTap == null ? null : onTap}
      type="submit"
      sx={{
        borderRadius: 35,
        color: isBorder === true ? "#001542" : "white",
        backgroundColor:
          color === undefined || color === null ? "#001542" : color,
        padding: "15px 0px 15px 0px",
        fontSize: "18px",
        borderRadius: "8px",
        marginTop: "0px",
        border:
          isBorder === undefined || isBorder === null || isBorder === false
            ? "none"
            : "1px solid #001542",

        width:
          width === null || width === undefined || width === false
            ? {
              xs: "280px",
              sm: "300px",
              md: "350px",
              lg:
                inSmallModal === undefined || inSmallModal === null
                  ? "350px"
                  : inSmallModal === false
                    ? "380px"
                    : "300px",
              xl:
                inSmallModal === undefined || inSmallModal === null
                  ? "350px"
                  : inSmallModal === false
                    ? "380px"
                    : "300px",
            }
            : width,
        fontSize: {
          xs: "13px",
          sm: "13px",
          md: "15px",
          lg: "15px",
          xl: "15px",
        },
        "&:hover": {
          backgroundColor: hoverColor === undefined || hoverColor === null || hoverColor === "" ?
            color === undefined || color === null || color === ""
              ? "#01256e"
              : color == "red"
                ? "rgb(231, 32, 32)"
                : "#01256e" : hoverColor, // Hover rengi burdan ayarlanır.
          color: "white",
          border: hoverBorderColor === undefined || hoverBorderColor === null || hoverBorderColor === "" ? "none" : "1px solid " + hoverBorderColor

        },
        textTransform: 'capitalize',
      }}
      variant="contained"
    >
      {title}
    </Button>

    */
