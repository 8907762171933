import React, { useState, useEffect, useMemo, useCallback } from "react";
import "../../../css/bitoniscss/activepacket.css";
import logo from "../../../images/packedNotFound.svg";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import api from "../../../utility/Utils";
import { connect, useDispatch } from "react-redux";
import { DropListAction } from "../../../action";
import { useHistory } from "react-router-dom";
import { DashboardServices } from "../../../services";
import useIsMobile from "../../hooks/useIsMobile";
import LoadingDotsAnimation from "../../widgets/LoadingDotsAnimation";

const ActivePackets = (props) => {
  const [currentPacket, setCurrentPacket] = useState(null);
 const isMobile = useIsMobile();

  const [getData, setGetData] = useState(false);
  const [remainderJobCount, setRemainderJobCount] = useState(null);
  const history = useHistory();
  useEffect(() => {
    planList().then(
      (plan) => {
        getCurrentPackets(plan);
      },
      (error) => {
        dispatch(failure(error));
      }
    );
    DashboardServices.getRemainderJobCount().then((remainderResponse) => {
      if (remainderResponse && remainderResponse.succeeded == true) {
        if (
          remainderResponse.data.creditLeft &&
          remainderResponse.data.creditLeft.toString() === "0"
        ) {
          setRemainderJobCount(remainderResponse.data.creditLeft.toString());
        } else if (parseInt(remainderResponse.data.creditLeft.toString()) > 0) {
          setRemainderJobCount(remainderResponse.data.creditLeft.toString());
        }
      }
    });
  }, []);

  function planList() {
    return api
      .get("/payment/getplans") //burda paketler
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  async function getCurrentPackets(plan) {
    api
      .get("payment/getCompanyPermission")
      .then((response) => {
        var data = response.data;
        var planList = [];
        if (plan == null || plan == []) {
        } else {
          planList = plan;
          if (data.creditLeft == 0) {
            setCurrentPacket(null);
            setGetData(Boolean(data));
          } else {
            planList.map((item) => {
              if (item.planId == data.currentPlanId) {
                setCurrentPacket(item);
                setGetData(Boolean(data));
              }
            });
          }
        }
      })
      .catch((err) => {});
  }
  if (getData == false) {
    return   <div >
    <div
      style={{
        display: "flex",
              flexDirection: "row",
              justifyContent: "center",
      }}
    >
      <LoadingDotsAnimation fontSize={40} />
    </div>
  </div>
  } else if (currentPacket == null) {
    return (
      <div>
        <div class="activenotfound" style={{}}>
          <img
            src={logo}
            style={{
              height: "120px",
              textAlign: "center",
            }}
          />
          <p
            style={{
              color: "#001542",
              margin: "10px",
              paddingTop:"10px",
              fontSize: "17px",
              fontWeight:"bold",
            }}
          >
            {" "}
            Paket Hakkınız Kalmamıştır!
          </p>
          <p
            style={{
              color: "#001542",
              margin: "10px",
              fontSize: "13px",
              paddingLeft: "80px", 
              paddingRight: "80px",
              textAlign:"center",
      
            }}
          >
            {" "}
            Paketler bölümünden ihtiyacınıza uygun bir paket satın alabilirsiniz. Adayların kişisel bilgilerine, video CV'lerine, video konferans ve iş yerinde görüşme gibi özelliklere erişebilmek için bir paket satın almanız gerekecek. Satın aldığınız paketler, esnek kullanım imkanı sunar. Yani, hangi taleplerinizde kullanmak istediğinize siz karar verirsiniz.
          </p>
          <button
            type="submit"
            className="btn btn-primary btn-block"
            id="buttonpacket"
            onClick={(event) => {
              history.push(`/packets`);
            }}
          >
            <p style={{ marginTop: "10px" }}>Paket Satın Al</p>
          </button>
        </div>
      </div>
    );
  }


  return (
    <div>
      <div className="row center-items ">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p
              style={{
                fontSize: isMobile ? "12px" : "18px",
                color: "#001542",
                fontWeight: 400,
              }}
            >
              Talep Kullanım Hakkınız :
            </p>
            <p
              style={{
                fontSize: isMobile ? "12px" : "20px",
                color: "#001542",
                fontWeight: "bold",
                marginLeft: "5px",
              }}
            >
              {" "}
              {remainderJobCount && remainderJobCount}{" "}
            </p>
          </div>
          <Col md={6} className="tab-equal">
  <div
    className="cardPackets"
    style={{
      cursor: "pointer",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      transition: "transform 0.2s, box-shadow 0.2s, background 0.2s, border 0.2s",
      borderRadius: "10px",
      background: "linear-gradient(145deg, #ffffff, #e6e6e6)",
      border: "2px solid #001542", 
    }}
    onClick={() => {
      // Your onClick handler logic here
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.transform = "translateY(-5px)";
      e.currentTarget.style.boxShadow = "0 10px 20px rgba(0, 0, 0, 0.2)";
      e.currentTarget.style.background = "linear-gradient(145deg, #e6e6e6, #ffffff)";
      e.currentTarget.style.border = "2px solid #fdc303"; 
    }} 
    onMouseLeave={(e) => {
      e.currentTarget.style.transform = "translateY(0)";
      e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      e.currentTarget.style.background = "linear-gradient(145deg, #ffffff, #e6e6e6)";
      e.currentTarget.style.border = "2px solid #001542";
    }}
  >
    <div className="cardHeader">
      <h4 className="headerTitle">{currentPacket.planName} </h4>
    </div>
    <div className="cardBody">
      <ul
        className="list-unstyled mt-0 mb-0"
        style={{
          fontSize: "14px",
          color: "#001542",
          fontWeight: 400,
        }}
      >
        {currentPacket == null ? (
          <div></div>
        ) : (
          currentPacket.properties.map((feature, i) => (
            <li key={i}>
              {" "}
              <i
                className="fa-solid fa-tag"
                style={{
                  fontSize: "15px",
                  color: "#FDC303",
                  margin: "8px",
                }}
              ></i>{" "}
              {feature.value}
            </li>
          ))
        )}
      </ul>
    </div>
  </div>
</Col>

        </div>
      </div>
    </div>
  );
};

function mapState(state) {
  let payment = state.payment;
  let companyList = state.companyListMain;
  let planList = state.planList;
  return {
    payment,
    companyList,
    planList,
  };
}
export default connect(mapState)(ActivePackets);
