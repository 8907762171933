import React from 'react';
import { styled, keyframes } from '@mui/system';

const waveAnimation = (maxScale, startColor, endColor) => keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
    background-color: ${startColor};
    border-color: ${startColor};
  }
  50% {
    opacity: 0.5;
    transform: scale(${maxScale * 0.75});
    background-color: ${endColor};
    border-color: ${endColor};
    border:1px dotted red
  }
  100% {
    opacity: 0;
    transform: scale(${maxScale});
    background-color: ${endColor};
    border-color: ${endColor};
  }
`;

const Wave = styled('div')(({ waveDuration, delay, maxScale, startColor, endColor }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: '50%',
  animation: `${waveAnimation(maxScale, startColor, endColor)} ${waveDuration}s infinite`,
  animationDelay: `${delay}s`,
  backgroundColor: 'transparent',
  transformOrigin: 'center',
  
}));

const WaveWrapper = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const AvatarGlow = ({
  children,
  waveCount = 3,
  waveDuration = 2,
  maxRadius = 120,
  initialRadius = 40,
  startColor = 'rgba(255, 255, 255, 1)', // Başlangıç rengi beyaz
  endColor = 'rgba(0, 0, 255, 0.7)',
  animate = true,width,
  height,
  valueMaxScale
}) => {
  const maxScale =valueMaxScale?? maxRadius / initialRadius;

  const waves = Array.from({ length: waveCount }).map((_, i) => (
    <Wave
      key={i}
      waveDuration={waveDuration}
      delay={(i * waveDuration) / waveCount}
      maxScale={maxScale}
      startColor={startColor}
      endColor={endColor}
    />
  ));

  return (
    <WaveWrapper style={{ width: initialRadius * 1.2, height: initialRadius * 1.2 }}>
      {animate && waves}
      <div style={{
        width:width?? '100%', 
        height:height?? '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: '50%',
        position: 'relative',
  
      }}>
        {children}
      </div>
    </WaveWrapper>
  );
};

export default AvatarGlow;
