import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropListServices } from "../../../../../../../services";
import { customStyles } from "../../../../../../customStyleInline";
import { DropListAction } from "../../../../../../../action";
import api from "../../../../../../../utility/Utils";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import SelectSingleRoute from "../../../../../../widgets/components/common/SelectSingleRoute";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import { Box } from "@mui/material";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import IsLoadingToShowDataModal from "../../../../../../widgets/loading/IsLoadingToShowDataModal";
import GoogleMapWidget from "../../../../../../widgets/map/GoogleMapWidget";

const EmployerCompaniesAddedLocationEdit = ({
  show,
  handleClose,
  props,
  companyLocationId,
  companyId,
  locationName,
  companyNameForLocation,
}) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [locationDetail, setLocationsDetail] = useState(null);

  const handleCity = useCallback(
    (selected) => {
      props.dispatch(DropListAction.countyList(selected.value));
    },
    [props]
  );

  const handleDistry = useCallback(
    (selected) => {
      props.dispatch(DropListAction.districtList(selected.value));
    },
    [props]
  );

  async function getLatLngApi(adress) {
    
    DropListServices.handleGetLocation(adress).then((location) => {

      if (location) {
        var latitute = location.lat;
        var longtitute = location.lng;

        setLat(latitute);
        setLng(longtitute);
        setLat(latitute);
        setLng(longtitute);
      } else {
      }
    });
  }
  function getCompanyLocationsDetail(id) {
    //Lokasyonalrı getir
    return api
      .get("company/location/details/" + id.toString())
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  const [phoneNumber, setPhoneNumber] = useState(null);
  useEffect(() => {
    if (show && companyLocationId) {
      setLocationsDetail(null);
      if (companyLocationId) {
        getCompanyLocationsDetail(companyLocationId).then(
          (locationRequest) => {
            if (locationRequest.succeeded === true) {

              setPhoneNumber(locationRequest.data.phoneNumber);
              setLocationsDetail(locationRequest.data);
              setValue("locationName", locationRequest.data.locationName);
              setValue("sector", {
                value: locationRequest.data.sectorId,
                label: locationRequest.data.sectorName,
              });
              setValue("countryId", {
                value: locationRequest.data.countryId,
                label: locationRequest.data.countryName,
              });
              setValue("cityId", {
                value: locationRequest.data.cityId,
                label: locationRequest.data.cityName,
              });
              handleCity({
                value: locationRequest.data.cityId,
                label: locationRequest.data.cityName,
              });

              setValue("countyId", {
                value: locationRequest.data.countyId,
                label: locationRequest.data.countyName,
              });
              handleDistry({
                value: locationRequest.data.countyId,
                label: locationRequest.data.countyName,
              });
              setValue("districts", {
                value: locationRequest.data.districtId,
                label: locationRequest.data.districtName,
              });
              setValue("companyAddress", locationRequest.data.companyAddress);

            } else {
              handleClose();
              setLocationsDetail(null);

              toast.error("Lokasyon detayı açılırken hata oluştu", {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => { }, 100);
                },
              });
            }
          },
          (error) => {
            toast.info("Hata Oluştu", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  handleClose();
                }, 100);
              },
            });
          }
        );
      }
    }
  }, [show, companyLocationId]);
  function locationItemUpdate(arg) {
    // lokasyon  güncelle
    return api
      .post("/company/location/update", arg)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  function editLocation(formData) {
    let sendData = {
      id: companyLocationId,
      companyId: companyId,
      locationName: formData.locationName.toString(),
      countryId: formData.countryId.value,
      cityId: formData.cityId.value,
      countyId: formData.countyId.value,
      districtId: formData.districts.value,
      address: formData.companyAddress,
      latitude: lat.toString() ?? "",
      longitude: lng.toString() ?? "",
      phoneNumber: phoneNumber,
      sectorId: formData.sector.value,
      isActive: true,
      isDeleted: false,
    };

    locationItemUpdate(sendData).then((locationUpdateRequestData) => {
      if (locationUpdateRequestData) {
        if (locationUpdateRequestData.succeeded === true) {
          handleClose();
          toast.success("Lokasyon başarılı şekilde güncellendi", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(locationUpdateRequestData.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      } else {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
      (error) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      };
    });
  }
  function locationItemDelete(arg) {
    // lokasyon  güncelle
    return api
      .post("/company/location/delete", arg)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  function deleteLocationItem() {
    let sendData = {
      companyId: parseInt(companyId.toString()),
      locationId: parseInt(companyLocationId.toString()),
    };
    locationItemDelete(sendData).then((locationDeleteRequestData) => {
      if (locationDeleteRequestData) {
        if (locationDeleteRequestData.succeeded === true) {
          handleClose();
          toast.success("Lokasyon başarılı şekilde silindi", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(locationDeleteRequestData.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      } else {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
      (error) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      };
    });
  }

  var adress = watch("companyAddress");
  var countryId = watch("countryId");
  var cityId = watch("cityId");
  var countyId = watch("countyId");
  var districtsId = watch("districts");
  useEffect(() => {
    if (show) {
      /*
        if (countryId && cityId && countyId && districtsId) {
          var createLatLngLastVersion =
            cityId.label +
            "," +
            "+" +
            countyId.label +
            "," +
            "+" +
            districtsId.label +
            "," +
            "+" +
            companyNameForLocation;
          getLatLngApi(createLatLngLastVersion);
        } else {
          var createLatLngLastVersion = companyNameForLocation;
          getLatLngApi(createLatLngLastVersion);
        }
          */
    }
  }, [show, cityId, countyId, districtsId, companyNameForLocation]);

  useEffect(() => {
    if (show) {
      if (locationDetail && locationDetail.latitude && locationDetail && locationDetail.longitude) {
     
        setLat(parseFloat(locationDetail && locationDetail.latitude.toString()))
        setLng(parseFloat(locationDetail && locationDetail.longitude.toString()))

      }
    }

  }, [show, locationDetail])



  return (
    <div>
      {locationDetail === null && show == true ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingDotsAnimation />
        </Box>
      ) : (
        <div>
          <Modal
            className="fade bd-example-modal-lg"
            size="lg"
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                justifyContent: "center",
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              <Modal.Title
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <h4 style={{ color: "#001542", fontWeight: "600" }}>
                  {" "}
                  {locationDetail && locationName}
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              className="grid-example"
              style={{
                marginTop: "10px",
                paddingTop: "10px",
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              <div className="container">
                <IsLoadingToShowDataModal
                  isFooter={true}
                  data={locationDetail}
                  showBody={
                    <form onSubmit={handleSubmit(editLocation)}>
                      <div>
                        <div className="row">
                          <CustomDesign
                            label={"Lokasyon Adı"}
                            required={true}
                            isColumn={true}
                            columnClassName={"col-xs-6 col-md-6"}
                            child={
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputClass"
                                  rules={{ required: true }}
                                  name="locationName"
                                  {...register("locationName", {
                                    required: true,
                                  })}
                                  placeholder="Lokasyon Adı"
                                ></input>
                                {errors.locationName && (
                                  <div className="text-danger fs-12">
                                    Lokasyon Adı Boş Olamaz
                                  </div>
                                )}
                              </>
                            }
                          />
                          <CustomDesign
                            label={"Sektör"}
                            required={true}
                            isColumn={true}
                            columnClassName={"col-xs-6 col-md-6"}
                            child={
                              <BitonisSingleDropdown
                                items={
                                  props.sectorList.success &&
                                  props.sectorList.sectorList &&
                                  props.sectorList.sectorList.map(
                                    ({ id, name }) => ({
                                      value: id,
                                      label: name,
                                    })
                                  )
                                }
                                register={register}
                                watch={watch}
                                companyClassName={"sector"}
                                control={control}
                                setValue={setValue}
                                errors={errors}
                                placeholder="Sektör Seç"
                                onValueChanged={(e) => {
                                  console.log(e);
                                  if (e) {

                                  }
                                }}
                                required={true}
                                getValues={getValues}
                                errorMessage={"Sektör Boş Olamaz "}
                              />
                            }
                          />
                        </div>

                        <SelectSingleRoute
                          props={props}
                          register={register}
                          watch={watch}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          getValues={getValues}
                          getLatLngApi={getLatLngApi}
                          preview={true}
                          employerCompanyName={companyNameForLocation}
                        />
                     
                        <div className="row">
                          <CustomDesign
                            label={"Şirket Adresi"}
                            required={true}
                            isColumn={true}
                            columnClassName={"col-xs-12 col-md-12"}
                            child={
                              <>
                                {
                                  <textarea
                                    name="companyAddress"
                                    id="noter-text-area-active"
                                    {...register("companyAddress", {
                                      required: true,
                                    })}
                                  ></textarea>
                                }
                                {errors.adress && (
                                  <div className="text-danger fs-12">
                                    {"Şirket Adresi Boş Olamaz "}
                                  </div>
                                )}
                              </>
                            }
                          />
                        </div>
                        <GoogleMapWidget
                          initialLatLng={{
                            lat: lat,
                            lng: lng
                          }}
                          onChangedPositioned={(position) => {
                            setLat(position.lat);
                            setLng(position.lng);
                        
                          }}
                          heightMap={"60vh"}
                        />
                      </div>
                    </form>
                  }
                />
              </div>
            </Modal.Body>{" "}
            {locationDetail && locationDetail.isMain == true ? (
              <Modal.Footer
                style={{
                  display: "flex",
                  justifyContent: "center",
                  borderTop: "none",
                  marginTop: "0px",
                  paddingTop: "0px",
                  alignContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <div
                  className="col-xs-6 col-md-6"
                  style={{
                    marginBottom: "0px",
                    paddingBottom: "0px",
                    display: "flex",
                    justifyContent: "center",
                    borderTop: "none",
                    marginTop: "0px",
                    paddingTop: "0px",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <form
                    onSubmit={handleSubmit(editLocation)}
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <CustomButton
                      title={"Kaydet"}
                      width={{
                        xs: "390px",
                        sm: "390px",
                        md: "350px",
                        lg: "400px",
                        xl: "400px",
                      }}
                    />
                  </form>
                </div>
              </Modal.Footer>
            ) : (
              <Modal.Footer
                style={{
                  border: "none",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <IsLoadingToShowDataModal
                  data={locationDetail}
                  isFooter={true}
                  showBody={
                    <Box
                      sx={{
                        paddingBottom: {
                          xs: "5px",
                          sm: "5px",
                          md: "0px",
                          lg: "0px",
                          xl: "0px",
                        },
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "column",
                          md: "column",
                          lg: "row",
                          xl: "row",
                        },
                      }}
                    >
                      {" "}
                      <Box
                        sx={{
                          margin: "0px",
                          padding: {
                            xs: "5px",
                            sm: "5px",
                            md: "5px",
                            lg: "5px",
                            xl: "5px",
                          },
                        }}
                      >
                        <CustomButton
                          title={"Sil"}
                          onTap={deleteLocationItem}
                          color={"white"}
                          backgroundColor={"red"}
                          hoverBackgroundColor={"rgb(231, 32, 32)"}
                          border={"none"}
                          width={{
                            xs: "400px",
                            sm: "350px",
                            md: "350px",
                            lg: "350px",
                            xl: "350px",
                          }}
                        />{" "}
                      </Box>
                      <form onSubmit={handleSubmit(editLocation)}>
                        <Box
                          sx={{
                            margin: "0px",
                            padding: {
                              xs: "5px",
                              sm: "5px",
                              md: "5px",
                              lg: "5px",
                              xl: "5px",
                            },
                          }}
                        >
                          <CustomButton
                            title={"Kaydet"}
                            width={{
                              xs: "400px",
                              sm: "350px",
                              md: "350px",
                              lg: "350px",
                              xl: "350px",
                            }}
                          />
                        </Box>
                      </form>
                    </Box>
                  }
                />
              </Modal.Footer>
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};

export default EmployerCompaniesAddedLocationEdit;
