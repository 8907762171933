import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
//** Import Profile Img */
import { Alert, Button, Col, Container, Dropdown, Row } from "react-bootstrap";

import "../../../../../../css/bitoniscss/profile.css";
import { useForm, Controller } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import {
  DropListServices,
  ProfileDetailServices,
} from "../../../../../../services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { customStyles } from "../../../../../customStyleInline";
import { API_URL } from "../../../../../../services/ApiUrlServices";
import api from "../../../../../../utility/Utils";
import ProfileCompanyBody from "./ProfileCompanyBody";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { getToken } from "../../../../../../configs/cookie";
import profileImg from "../../../../../../images/avatar/company.png";
import useReRender from "../../../../../hooks/useReRender";
import LoadingDotsAnimation from "../../../../../widgets/LoadingDotsAnimation";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

function getCompanyMainDetail() {
  //Firmanın Ana Şirket Bilgileri
  return api
    .get("/company/main")
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
const Profile = (props) => {
  const history = useHistory();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [companyMainDetail, setCompanyMainDetail] = useState(null);
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const isMobile = useIsMobile();
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    getCompanyMainDetail().then(
      (companyResponse) => {
        if (companyResponse) {
          if (companyResponse.succeeded === true) {
            //alert("companyResponse --  "+JSON.stringify(companyResponse))
            setCompanyMainDetail(companyResponse.data);
          }
        }
      },
      (error) => {}
    );
  }, []);

  const [companyEmail, setCompanyEmail] = useState("");

  function getInformation() {
    ProfileDetailServices.companyInfo().then(
      (infoResponse) => {
        if (infoResponse) {
          setCompanyEmail(infoResponse.email);
        }
      },
      (error) => {}
    );
  }

  useEffect(() => {
    getInformation();
  }, []);

  const [getProfileImage, setGetProfileImage] = useState(null);

  const [loading, setLoading] = useState(true);
  const [notFount, setNotFount] = useState(false);
  const [selectFile, setSelectFile] = useState(null);
  const [file, setFile] = useState(null);
  const handleFileChange = async (e) => {
    setSelectFile(e);
    if (e.target.files) {
      setLoading(true);
      setFile(e.target.files[0]);
      if (e.target.files[0]) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        formData.append("companyId", companyMainDetail.id);

        var token = getToken();

        try {
          const result = await fetch(API_URL + "/employer/profile-image", {
            method: "POST",
            body: formData,
            headers: { Authorization: `Bearer ${token}` },
          });

          const data = await result.json();

          setLoading(false);
          if (data.succeeded === true) {
            toast.success(data.result.message);
            setFile(null);
            var profileImageUrl = data.data;
            var id = companyMainDetail.id;
            const url = `${API_URL}/images/employer/profile/${id}/${profileImageUrl}`;

            setGetProfileImage(url);
            setLoading(false);
            setNotFount(false);
          } else {
            setError(true);

            toast.success(data.error.message);
          }

          console.log(data);
        } catch (error) {
          console.error(error);
        }
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setGetProfileImage(null);
    setLoading(true);
    ProfileDetailServices.companyInfo().then(
      (companyInfo) => {
        setLoading(false);

        var id = companyInfo.id;

        var profileImageUrl = companyInfo.profileImageUrl;
        const url = `${API_URL}/images/employer/profile/${id}/${profileImageUrl}`;
        setLoading(false);

        if (
          companyInfo.profileImageUrl === undefined ||
          companyInfo.profileImageUrl === null ||
          companyInfo.profileImageUrl === ""
        ) {
          setNotFount(true);

          setGetProfileImage(profileImg);
        } else {
          setNotFount(false);
          setGetProfileImage(url);
        }
      },
      (error) => {}
    );
  }, []);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <Fragment>
      {companyMainDetail === null ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <LoadingDotsAnimation />
        </div>
      ) : (
        <div>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="row">
            <div className="col-xl-3 col-xxl-4 col-lg-4">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card  flex-lg-column flex-md-row ">
                    <div className="card-body text-center border-bottom profile-bx">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignContent: "center",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",

                            marginBottom: "0px",
                          }}
                        >
                          <div
                            style={{
                              border: "5px solid #001542",
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              flexDirection: "column",
                              position: "relative",
                              alignItems: "center",
                            }}
                          >
                            {loading && (
                              <i
                                class="fa-solid fa-spinner"
                                style={{
                                  position: "absolute",

                                  left: "30%",
                                  transform: "translate(-50%, -50%)",
                                  fontSize: "24px",
                                  animation: "spin 1s linear infinite",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  flexDirection: "column",
                                }}
                              ></i>
                            )}
                            <img
                              src={notFount ? profileImg : getProfileImage}
                              onLoad={handleLoad}
                              onError={(val) => {
                                setNotFount(true);
                              }}
                              alt="Display"
                              style={{
                                display: loading ? "none" : "block",
                                width: notFount ? "60px" : "100%",
                                height: notFount ? "60px" : "100%",
                                borderRadius: notFount ? "" : "50%",
                                padding: "1px",
                                margin: notFount ? "10px" : "0px",
                              }}
                            />
                          </div>
                          <label
                            htmlFor="file"
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: "50%",
                              right: "-5%",
                            }}
                          >
                            {" "}
                            <div
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: "white",
                                border: "1.5px solid #001542",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <i
                                class="fa-solid fa-pencil"
                                Şirket
                                Hakkında
                                style={{
                                  color: "#001542",
                                }}
                              ></i>
                            </div>
                            <input
                              input
                              id="file"
                              type="file"
                              onChange={handleFileChange}
                              style={{
                                display: "none",
                              }}
                            />
                          </label>
                        </div>
                      </div>
                      <h4 className="fs-22 text-black mb-1 text-capitalize">
                        {companyMainDetail && companyMainDetail.alias}
                      </h4>
                      <a
                        href={`mailto:${companyEmail}`}
                        className="fs-14 text-lowercase"
                        style={{ color: "blue", marginBottom: 0 }}
                      >
                        {companyEmail}
                      </a>

                      <div className="row">
                        <div className="col-12">
                          <div
                            className="border rounded p-2"
                            style={{ backgroundColor: "#7a7a7a" }}
                          >
                            <h4 className="fs-22 vkn-white font-w600">
                              Vergi No
                            </h4>
                            <span className="vkn-white">
                              {companyMainDetail && companyMainDetail.taxNumber}
                            </span>
                          </div>
                          <div className="text-center mt-3">
                            <CustomButton
                              onTap={(val) => {
                                if (companyMainDetail) {
                                  const listValue = companyMainDetail;
                                  const dataMap = new Map();
                                  dataMap.set("companyId", listValue.id);
                                  dataMap.set(
                                    "phoneNumber",
                                    listValue.phoneNumber
                                  );
                                  history.push({
                                    pathname: "/companyLocations",
                                    state: { dataMap },
                                  });
                                }
                              }}
                              height={{
                                xs: "40px",
                                sm: "40px",
                                md: "45px",
                                lg: "55px",
                                xl: "55px",
                              }}
                              borderRadius={"10px"}
                              title={"Lokasyonlarım"}
                              width={{
                                xs: "100%",
                                sm: "100%",
                                md: "100%",
                                lg: "100%",
                                xl: "100%",
                              }}
                            />

                            <div className="text-center mt-3">
                              <CustomButton
                                onTap={(val) => {
                                  history.push("/profile/groupCompanies");
                                }}
                                height={{
                                  xs: "40px",
                                  sm: "40px",
                                  md: "45px",
                                  lg: "55px",
                                  xl: "55px",
                                }}
                                borderRadius={"10px"}
                                title={"Grup Şirketlerim"}
                                width={{
                                  xs: "100%",
                                  sm: "100%",
                                  md: "100%",
                                  lg: "100%",
                                  xl: "100%",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-xxl-8 col-lg-8">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card profile-card">
                    <div className="">
                      <ProfileCompanyBody
                        companyMainDetailData={companyMainDetail}
                        props={props}
                        companyEmail={companyEmail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let companyDetail = state.companyDetail;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyMainDetail = state.companyMainDetail;
  let sectorList = state.sectorList;
  let taxOffices = state.taxOffices;

  return {
    authentication,
    companyDetail,
    countryList,
    cityList,
    countyList,
    districtList,
    companyMainDetail,
    sectorList,
    taxOffices,
  };
}

export default connect(mapState)(Profile);
