import axios from "axios";
import api from "../utility/Utils";

export const DropListServices = {
  positionList,
  yearOfExperience,
  wayOfWorking,
  nationalityList,
  ageRangeList,
  driverLicenceType,
  programList,
  certificatesList,
  competencesList,
  countryList,
  cityList,
  countyList,
  districtList,
  companyList,
  companyLocation,
  sectorList,
  departmentList,
  blueNewDemand,
  blueUpdateDemand,internUpdateDemand,
  companyListM,
  planList,
  handleGetLocation,
  companyItemLocation,
  countyJobList,
  allCityList,
  allCountyList,
  profileFillPcc,
  searchCompanyList,
  getDisabilityOfDegreeList,
  internNewDemand,
};

// Mavi Yaka Bilgiler

function companyList() { //Şirket Bilgileri
    return api.get("/employer/companies")//burda main yazdım
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

;

function searchCompanyList() {
  //Şirket Bilgileri
  return api
    .get("/employer/companies/base") 
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function companyLocation() { //Şirket Lokasyon Bilgileri
    return api.get("/employer/locations")
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};






;



function companyItemLocation(id) {
  //İlgili şirket seçildiğinde talpe yayınlama kısmında ilgili şirketin Lokasyon Bilgilerini getirmek
  return api
    .get("/company/location/baseList/" + id.toString())
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};










function planList() {
  //Şirket plan listesi
  return api
    .get("/payment/getplans") //burda paketler
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}



function companyListM() {
  //Şirket Bilgileri
  return api
    .get("/company/main") //burda main yazdım,,buradan alıyorum ,company apisi boş dönüyor ordan çekmeye çalışıyorlardı
      .then((response) => {
       
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function positionList(arg) { // Pozisyon Listesi
    return api.post("/common/GetPositionList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function yearOfExperience(arg) { //Tecrübe Yılı listesi
    return api.post("/common/GetExperienceRangeTypeList", arg)
      .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function getDisabilityOfDegreeList() {
  //engellilik seviyesini getiren liste
  return api
    .get("/common/degreeOfDisabilityList")
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function wayOfWorking(arg) { // Çalışma Şekli
    return api.post("/common/GetWorkTypeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function nationalityList(arg) { // Uyruk
    return api.get("/common/GetNationalityList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function ageRangeList(arg) { // Yaş Aralığı
    return api.get("/common/GetAgeRangeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function driverLicenceType(arg) { // Sürücü Belgesi Türü
    return api.get("/common/GetDrivingLicenceTypeList", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function programList(positionGroupCode) { // Program Listesi
    return api.post("/common/programs", positionGroupCode)

        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function profileFillPcc(positionGroupCode) {
  // Program Listesi
  return api
    .post("/common/profilefillpcc", positionGroupCode)

    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function certificatesList(positionGroupCode) { // Sertifika Listesi
    return api.post("/common/certificates", positionGroupCode)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function competencesList(positionGroupCode) { // Yetkinlik Listesi
    return api.post("/common/competences", positionGroupCode)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function countryList(arg) { // Ülke Listesi
    return api.get("/common/country", arg)
        .then((response) => {

            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function cityList(arg) { // Şehir Listesi
    return api.post("/common/city", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}function allCityList(arg) {
  // Şehir Listesi
  return api
    .post("/common/allcity", arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function countyList(cityId) {
    const arg = {
        cityId: cityId
    };
    return api.post("/common/county", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}function allCountyList(cityId) {
  const arg = {
    cityId: cityId,
  };
  return api
    .post("/common/allcounty", arg)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function countyJobList(cityId) {
  const arg = {
    cityId: cityId,
  };
  return api
    .post("/common/allcounty", arg)
      .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}


 async function handleGetLocation(address)  {
  return  await axios
       .get(
         `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyB_aGSx1_rKC-QV7lJS-6FbJWueDTfUE_8`
       )
        .then((response) => {
        
         return Promise.resolve(response.data&&response.data.results[0]&&response.data.results[0].geometry.location);
       })
       .catch((err) => {
         return Promise.reject(err);
       });;
    
 
 };



function districtList(countyId) { // Semt Listesi
    const arg = {
        countyId: countyId
    };
    return api.post("/common/district", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}


function sectorList(arg) { // Sektör Listesi
    return api.get("/common/sectors", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}
function departmentList(arg) { // Departman Listesi
    return api.get("/common/departments", arg)
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function blueNewDemand(arg) { // Mavi Yaka Yeni Talep Post
    return api.post("/job/labourer/create", arg)
        .then((response) => {
            return Promise.resolve(response.data === null ? response : response)
        })
        .catch((err) => {
          
            return Promise.reject(err);
        });
}
function internNewDemand(arg) {
  // stajyer  Yeni Talep Post
  return api
    .post("/intern/job/create", arg)
    .then((response) => {
      return Promise.resolve(response.data === null ? response : response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
function blueUpdateDemand(arg) { // Mavi Yaka Talep Güncelleme Post
    return api.post("/job/labourer/update", arg)
        .then((response) => {
            return Promise.resolve(response) 
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}

function internUpdateDemand(arg) {
  // Stajyer Talep Güncelleme Post
  return api
    .post("/intern/job/update", arg)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}