import React, { useState } from "react";
import moment from "moment";
import { Dropdown } from "react-bootstrap";
import { Button, Fab, Menu, MenuItem } from "@mui/material";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Box, Typography } from "@mui/material";
import { Row } from "react-bootstrap";

const BlueDemandItem = ({ demand, onTap, onTapMatch, positionName }) => {
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getBackgroundColor = () => {
    if (isHovered) {
      return "white";
    }

    // Diğer durumlar için renkleri döndürün
    return "white";
  };

  const getBoxShadow = () => {
    return isHovered
      ? "0px 7px 8px #dfdfdf;" // Hovered state shadow (elevation effect)
      : "0px"; // Default state shadow
  };

  return (
    <div>
      <Box
        onClick={onTap}
        className="card-body"
        key={demand.candidateId}
        sx={{
          margin: "8px",
          padding: "0px",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          display: "flex",
          flexDirection: "row",
          border: isHovered == true ? "1px solid #D6E5FA" : "1px solid #F7F7F7",
          height: "60px",
          position: "relative",
          borderRadius: "10px",
          alignItems: "center",
          width: "100%",
          margin: "8px",
          justifyContent: "space-between",
          cursor: "pointer",
          backgroundColor: getBackgroundColor(),
          boxShadow: getBoxShadow(),
          transition: "box-shadow 0.3s ease-in-out",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <>
          <Row
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignContent: "center",
                alignItems: "center",

                marginLeft: {
                  xs: "15px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              <span
                className="fa-stack fa-lg"
                style={{
                  marginLeft: "15px",
                }}
              >
                <i class="fa-solid fa-user"></i>
              </span>

              <Typography
                sx={{
                  width: {
                    xs: "120px",
                    sm: "120px",
                    md: "150px",
                    lg: "200px",
                    xl: "200px",
                  },
                  marginLeft: {
                    xs: "0px",
                    sm: "0px",
                    md: "0px",
                    lg: "0px",
                    xl: "0px",
                  },

                  fontSize: {
                    xs: "9px",
                    sm: "11px",
                    md: "11px",
                    lg: "12px",
                    xl: "12px",
                  },

                  fontWeight: 600,
                  color: {
                    xs: "#001542",
                    sm: "#001542",
                    md: "#001542",
                    lg: "#001542",
                    xl: "#001542",
                  },
                }}
              >
                {positionName}
              </Typography>
            </Box>
          </Row>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
              textAlign: "center",
            }}
          >
            <Typography
              sx={{
                marginTop: "20px",
                textAlign: "center",
                width: {
                  xs: "120px",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                fontSize: {
                  xs: "9px",
                  sm: "11px",
                  md: "11px",
                  lg: "11px",
                  xl: "11px",
                },
                fontWeight: 600,
                color: {
                  xs: "#001542",
                  sm: "#001542",
                  md: "#001542",
                  lg: "#001542",
                  xl: "#001542",
                },
                textAlign: "center",
              }}
            >
              <div>
                {" "}
                Talep Tarihi: {moment(demand.demandDate).format("DD/MM/YYYY")}
                <div
                  style={{
                    fontSize: "9px",
                    fontWeight: 600,
                    color: "#001542",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",

                      fontSize: {
                        xs: "9px",
                        sm: "11px",
                        md: "11px",
                        lg: "11px",
                        xl: "11px",
                      },
                      fontWeight: 600,
                      color: {
                        xs: demand.isActive ? "green" : "red",
                        sm: demand.isActive ? "green" : "red",
                        md: demand.isActive ? "green" : "red",
                        lg: demand.isActive ? "green" : "red",
                        xl: demand.isActive ? "green" : "red",
                      },
                      textAlign: "center",
                    }}
                  >
                    {demand.isActive === true ? (
                      <p> Talep Durumu:Aktif</p>
                    ) : (
                      <p> Talep Durumu : Pasif</p>
                    )}
                  </Typography>
                </div>
              </div>
            </Typography>
          </Box>
          <Box></Box>
          <Box></Box>
        </>
        <Box
          onClick={(event) => {
            event.stopPropagation(); // Olayın yayılmasını durdur
            onTapMatch(); // sadece onTapMatch'i çalıştır
          }}
          sx={{
            height: "62px",
            width: "60px",

            padding: "5px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            justifyItems: "center",
            alignContent: "center",
            alignItems: "center",
            border: "1px solid grey",
            borderRadius: "0px 8px 8px 0px",
            boxShadow: getBoxShadow(),
            transition: "box-shadow 0.3s ease-in-out",
            border: "1px solid #f5f5f5",
            backgroundColor:
              demand && demand.didDemandExpired === true ? "grey" : "#FFB40D",
            color: "white",
            cursor: "pointer",
          }}
        >
          <i
            class="fa-solid fa-users"
            style={{
              marginBottom: "2px",
            }}
          ></i>

          <h6
            style={{ fontSize: "9px", margin: 0, padding: 0, color: "white" }}
          >
            Talebimle Eşleşenler
          </h6>
        </Box>
        {demand.paymentPlanType !== 5 && (
          <Typography
            sx={{
              position: "absolute",
             top: 5,
            left: 5,
              fontSize: {
                xs: "9px",
                sm: "11px",
                md: "11px",
                lg: "11px",
                xl: "11px",
              },
              fontWeight: 600,

              textAlign: "center",
            }}
          >
            🔴
          </Typography>
        )}
       
      </Box>
    </div>
  );
};

export default BlueDemandItem;
