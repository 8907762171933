import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import "../../../../../../css/bitoniscss/Home/cardsettings.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CountdownTimerOtp from "./CountdownTimerOtp";
import ProfileOtpModal from "./Modal/ProfileOtpModal";
import { AccountServices } from "../../../../../../services/AccountServices";
import { setToken } from "../../../../../../configs/cookie";
import EmployerToBitonisContact from "../../../../../pages/EmployerToBitonisContact";
import CustomDesign from "../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

const ChangeEmailPage = () => {
  useEffect(() => { }, []);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const [showContact, setShowContact] = useState(false);
  function handleContactClose() {
    setShowContact(false);
  }
  const [show, setShow] = useState(false);
  function handleClose() {
    setShowContact(false);
    setShow(false);

  }
  const [showTimerParentPage, setShowTimerParentPage] = useState(false);
  const [again, setAgain] = useState(false);
  const timeRef = useRef(10 * 60);
  function onSubmit(form) {
    if (form.emailCtx2 === form.emailCtx1) {
      if (
        timeRef.current < 10 * 60 &&
        timeRef.current !== 0.0 &&
        timeRef.current !== 0
      ) {
        setShow(true);
        setShowTimerParentPage(true);
      } else {
        changeEmail();
      }
    } else {
      setShowTimerParentPage(false);
      toast.error("Email adresleri aynı değil");
    }
  }

  const handleTimeChange = (newTime) => {
    console.log(newTime);
    timeRef.current = newTime;
    console.log("ccc " + timeRef.current);
  };

  function changeEmail() {
    var password = watch("passwordCtx");
    var email = watch("emailCtx1");

    var sendData = {
      password: password.toString(),
      email: email.toString(),
      confirmEmail: email.toString(),
    };
    AccountServices.approvalChangeEmail(sendData).then(
      async (getResponse) => {
        if (getResponse.succeeded === true) {
          setShow(true);
          setShowTimerParentPage(true);
          toast.info(getResponse.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => { }, 100);
            },
          });
        } else {
          if (getResponse.error.code == 114) {
            setShow(true);
            setShowTimerParentPage(true);
          }
          toast.info(getResponse.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => { }, 100);
            },
          });
        }
      },
      (error) => { }
    );
  }

  function approvalChangeEmailOtpCode(otpCode) {
    var email = watch("emailCtx1");
    var sendData = { newEmail: email, verificationCode: otpCode };

    AccountServices.approvalChangeEmailOtp(sendData).then(
      async (getResponse) => {
        if (getResponse.succeeded === true) {
          setShow(false);
          setShowTimerParentPage(true);
          timeRef.current = 10 * 60;
          setAgain(true);
          setToken(getResponse.data.accessToken);
          toast.info(getResponse.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(getResponse.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                setAgain(false);
              }, 100);
            },
          });
        }
      },
      (error) => { }
    );
  }
  function requestAgainEmailOtpCode() {
    if (timeRef.current === 0.0 || timeRef.current === 0) {
      var email = watch("emailCtx1");
      var setShowTimerParentPageData = {
        email: email.toString(),
      };
      AccountServices.againRequestChangeEmailOtp(setShowTimerParentPageData).then(
        async (getResponse) => {
          if (getResponse.succeeded === true) {
            setShow(true);
            setShowTimerParentPage(true);
            timeRef.current = 10 * 60;
            setAgain(true);

            toast.info(getResponse.result.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  setAgain(false);
                }, 100);
              },
            });
          } else {
            toast.info(getResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  setAgain(false);
                }, 100);
              },
            });
          }
        },
        (error) => { }
      );
    } else {
      toast.error("Lütfen belirtilen süre bittiğinde tekrar deneyiniz");
    }
  }
  return (
    <>
      {" "}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        id="settingBody"
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >

        <div
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            textAlign: "center"
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "600",
              fontSize:"20px",
            }}
          >
            E-Posta Adresini Değiştir
          </p>
        </div>
        <div
          className="card"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <div
            className="card-header"
            style={{
              borderBottom: "none",
              marginLeft: "18px",
              paddingLeft: "18px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p></p>
            </div>
          </div>
          <div
            className="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >

            <form onSubmit={handleSubmit(onSubmit)}>

              <CustomDesign
                label={"Mevcut Şifreniz"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        key={"password1"}
                        autoComplete="new-password"
                        style={{
                          border: "1px solid rgb(203, 200, 200)",
                          padding: "10px",
                          marginLeft: "0px",
                          backgroundColor: "#f8f8f9",
                          borderRadius: "6px",
                          width: "100%",
                          color: "#001542",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        name="passwordCtx"
                        {...register("passwordCtx", { required: true })}
                        placeholder="Şifre"
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="far fa-eye"></i>
                        ) : (
                          <i className="far fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                    {errors.password && (
                      <div className="text-danger fs-12">Şifre Boş Bırakılamaz</div>
                    )}
                  </div>
                }
              />


              <CustomDesign
                label={"Yeni E-Posta Adresi"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      name="emailCtx1"
                      key={"emailCtx1"}
                      style={{
                        border: "1px solid rgb(203, 200, 200)",
                        padding: "10px",
                        marginLeft: "0px",
                        backgroundColor: "#f8f8f9",
                        borderRadius: "6px",
                        width: "100%",
                        color: "#001542",
                        fontWeight: "500",
                        fontSize: "15px",
                      }}
                      {...register("emailCtx1", { required: true })}
                      placeholder="E-Posta Adresiniz"
                    />
                    {errors.emailCtx1 && (
                      <div className="text-danger fs-12">Email Boş Bırakılamaz</div>
                    )}
                  </div>
                }
              />
     <CustomDesign
                label={"Yeni E-Posta Adresini Tekrar Giriniz"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                      <input
                  type="email"
                  className="form-control"
                  name="emailCtx2"
                  key={"emailCtx2"}
                  style={{
                    border: "1px solid rgb(203, 200, 200)",
                    padding: "10px",
                    marginLeft: "0px",
                    backgroundColor: "#f8f8f9",
                    borderRadius: "6px",
                    width: "100%",
                    color: "#001542",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                  {...register("emailCtx2", { required: true })}
                  placeholder="E-Posta Adresiniz"
                />
                {errors.emailCtx2 && (
                  <div className="text-danger fs-12">Email Boş Bırakılamaz</div>
                )}
                  </div>
                }
              />


             
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignContent: "end",
                  alignItems: "end",
                  marginRight: "15px",
                  marginTop:"15px"
                }}
              >
                {showTimerParentPage && (
                  <CountdownTimerOtp
                    initialMinute={timeRef.current / 60}
                    onTimeChange={handleTimeChange}
                    again={again}
                  />
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
              
                <CustomButton title={"Kaydet"} width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }} />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ProfileOtpModal
        show={show}
        handleClose={handleClose}
        approval={(code) => {
          if (code) {
            approvalChangeEmailOtpCode(code);
          }
        }}
        time={timeRef.current.toFixed(0)}
        handleTimeChange={handleTimeChange}
        requestAgainEmailOtpCode={requestAgainEmailOtpCode}
        again={again}
        showContact={showContact}
        setShowContact={setShowContact}
        handleContactClose={handleContactClose}
      />{" "}
      <EmployerToBitonisContact
        show={showContact}
        handleClose={handleContactClose}
        contactUsUserUserName={watch("emailCtx1")}
      />
    </>
  );
};

export default ChangeEmailPage;
