import React, { useEffect, useRef, useState } from "react";

import { connect, useDispatch, useSelector } from "react-redux";
import { DemandsServices } from "../../../../../../../../services";
import "../../../../../../../../css/bitoniscss/matchdetail.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import WhiteInformation from "./components/WhiteInformation";
import WhiteUserHeaderInformation from "./components/WhiteUserHeaderInformation";
import WhiteWantToWorkPositions from "./components/WhiteWantToWorkPositions";
import WhiteEducation from "./components/WhiteEducation";
import WorkExperiences from "./components/WorkExperiences";
import WhiteAbilities from "./components/WhiteAbilities";
import WhiteActions from "./components/WhiteActions";
import { ToastContainer, toast } from "react-toastify";
import { ApiUrlServices } from "../../../../../../../../services/ApiUrlServices";
import DividerDotted from "../../../../../../../widgets/DividerDotted";
import { API_URL } from "../../../../../../../../services/ApiUrlServices";
import { useReactToPrint } from "react-to-print";
import WhiteCandidateCv from "../../Cv/WhiteCandidateCv/WhiteCandidateCv";
import PdfWidget from "../../Cv/PdfWidget";
import WhiteHobbies from "./components/WhiteHobbies";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import WhiteWantToLocation from "./components/WhiteWantToLocation";
import WhiteMediumActions from "./components/WhiteMediumActions";
import CustomButton from "../../../../../../../widgets/buttons/CustomButton";
import CustomInfoPage from "../../../../../../../widgets/components/common/CustomInfoPage";
import { Typography } from "@mui/material";
import MatchDetailInfoModal from "../blueMatchDetail/matchDetailInfoModal";

const WhiteMatchMediumDetail = (props) => {
  const location = useLocation();
  const { dataMap } = location.state;
  const candidateId = dataMap.get("candidateId");
  const matchId = dataMap.get("matchId");
  useCurrentUserActionPageLog(13, candidateId);
  const [infoShow, setInfoShow] = useState(false);
  function handleInfoClose() {
    setInfoShow(false);
  }

  const status = dataMap.get("status");
  const cameWithoutMatchingPage = dataMap.get("cameWithoutMatchingPage");

  const history = useHistory();
  const [whiteMatchDetail, setWhiteMatchDetail] = useState([]);
  var genderList = [
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Kadın",
    },
    {
      id: 2,
      name: "Erkek",
    },
  ];
  var maritalStatusList = [
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Bekar",
    },
    {
      id: 2,
      name: "Evli",
    },
  ];
  var askerlikDurumu = [
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Tamamlandı",
    },
    {
      id: 2,
      name: "Tamamlanmadı",
    },
  ];
  var driverLicense = [
    {
      id: 1,
      name: "Var",
    },
    {
      id: 2,
      name: "Yok",
    },
  ];
  var smokingList = [
    {
      id: true,
      name: "Kullanıyorum",
    },
    {
      id: false,
      name: "Kullanmıyorum",
    },
  ];
  var egitimDurumuList = [
    {
      id: 1,
      name: "İlkokul",
    },
    {
      id: 2,
      name: "Ortaokul",
    },
    {
      id: 3,
      name: "Lise",
    },
    {
      id: 4,
      name: "Ön Lisans",
    },
    {
      id: 5,
      name: "Lisans",
    },
    {
      id: 6,
      name: "Yüksek Lisans",
    },
    {
      id: 7,
      name: "Doktora",
    },
  ];
  var haftaSonuCalismaDurumuCandidate = [
    {
      id: 5,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Yok",
    },
    {
      id: 2,
      name: "Tam Gün",
    },
    {
      id: 3,
      name: "Yarım Gün",
    },
    {
      id: 4,
      name: "Dönüşümlü",
    },
  ];
  var disabilityCandidateList = [
    { id: 2, name: "Var" },
    { id: 0, name: "Yok" },
  ];
  function calculateAge(jobSeekerDate) {
    var currentDate = new Date();
    var birthDate = new Date(jobSeekerDate);

    var age = currentDate.getFullYear() - birthDate.getFullYear();

    var currentMonth = currentDate.getMonth() + 1; // Ay indeksi 0'dan başlar, bu yüzden 1 ekliyoruz.
    var birthMonth = birthDate.getMonth() + 1;

    if (
      currentMonth < birthMonth ||
      (currentMonth === birthMonth &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  const [employerId, setEmployerId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  function getEmployerHeaderData() {
    DemandsServices.getEmployerHeaderData().then((employerData) => {
      if (employerData && employerData.succeeded === true) {
        setEmployerId(employerData.data.employerId);
        setCompanyId(employerData.data.id);
      }
    });
  }

  useEffect(() => {
    getEmployerHeaderData();
    if (candidateId) {
      setWhiteMatchDetail([]);
      DemandsServices.candidateMediumMatchDetailProfileCandidateEmployee(
        candidateId,
        matchId
      ).then((getResponse) => {
        console.log("getResponse* * " + JSON.stringify(getResponse));
        var matchLanguageDetailList = [];
        if (getResponse && getResponse.succeeded === true) {
          const getData = getResponse.data;
          var matchCandidateLocations = [];
          if (getData) {
            console.log("getData* * " + JSON.stringify(getData));
            var gender = genderList.find((val) => val.id === getData.gender);
            var age = calculateAge(getData.birthdate.toString());
            var maritalStatus = maritalStatusList.find(
              (val) => val.id === getData.maritalStatus
            );
            var smoking = smokingList.find(
              (val) => val.id === getData.isSmoking
            );
            var military = askerlikDurumu.find(
              (val) => val.id === getData.militaryStatus
            );
            var driving = driverLicense.find(
              (val) => val.id === getData.drivingLicence
            );
            var yearofexperienceWhiteMatch = 0.0;
            var addedExperienceList = [];
            if (
              getData.employeeWorkExperiencesCandidates == null ||
              getData.employeeWorkExperiencesCandidates == [] ||
              getData.employeeWorkExperiencesCandidates.toString() == "[]"
            ) {
            } else {
              getData.employeeWorkExperiencesCandidates.map((v) => {
                if (
                  v["workExperienceDuration"].toString() == "null" ||
                  v["workExperienceDuration"] === undefined
                ) {
                  yearofexperienceWhiteMatch = 0.0;
                } else {
                  if (
                    v["workExperienceDuration"].toString() == "0.0" ||
                    v["workExperienceDuration"].toString() == "0"
                  ) {
                  } else {
                    yearofexperienceWhiteMatch = v["workExperienceDuration"];
                    var addedExperience = {
                      companyName: v.companyName,
                      positionName: v.positionName,
                      startDate: v.startDate,
                      finishDate: v.finishDate,
                      present: v.present,
                      sectorName: v.sectorName,
                      departmentName: v.departmentName,
                      workTypeName: v.workTypeName,
                    };
                    addedExperienceList.push(addedExperience);
                  }
                }
              });
            }
            var pozitionMatchDetailCandidate =
              getData.jobPosition && getData.jobPosition;
            var weekendWork;
            haftaSonuCalismaDurumuCandidate.map((value) => {
              weekendWork = getData.employeeWeekendWorkTypes.find(
                (val) => val.weekendWorkId === value.id
              );
            });
            weekendWork = haftaSonuCalismaDurumuCandidate.find((weekend) =>
              getData.employeeWeekendWorkTypes.find(
                (val) => val.weekendWorkId === weekend.id
              )
            );
            var eduList = [];

            getData.employeeCandidateEducations.map((edu) => {
              var schoolName = egitimDurumuList.find(
                (val) => val.id === edu.educationTypeId
              );
              var eduMap = {
                educationTypeId: edu.educationTypeId,
                educationName: schoolName.name,
                universityName: edu.universityName,
                sectionName: edu.departmentName,
                educationStartDate: edu.educationStartDate,
                educationFinishDate: edu.educationFinishDate,
                present: edu.present,
                leavingSchool: edu.leavingSchool,
              };
              eduList.push(eduMap);
            });
            if (
              getData.employeeForeignLanguagesCandidates == null ||
              getData.employeeForeignLanguagesCandidates.toString() == "null" ||
              getData.employeeForeignLanguagesCandidates.toString() == "[]" ||
              getData.employeeForeignLanguagesCandidates == []
            ) {
              //yabancı dil boş
            } else {
              getData.employeeForeignLanguagesCandidates.map((language) => {
                var foreignLanguages = {
                  languageName: language.languageName,
                  languageLevelName: language.languageLevelName,
                };
                matchLanguageDetailList.push(foreignLanguages);
              });
            }
            var disabilityName = "";
            var disabilityId = parseInt(
              getData && getData.disability.toString()
            );
            disabilityCandidateList.forEach((element) => {
              if (element.id.toString() == getData.disability.toString()) {
                disabilityName = element.name;
              }
            });
            var disabilityDegreeName = "";
            if (
              getData.candidateDegreeOfDisabilities.toString() == "[]" ||
              getData.candidateDegreeOfDisabilities.toString() == "null" ||
              getData.candidateDegreeOfDisabilities.toString() == "" ||
              getData.candidateDegreeOfDisabilities == undefined
            ) {
            } else {
              getData.candidateDegreeOfDisabilities.forEach((val) => {
                disabilityDegreeName =
                  disabilityDegreeName + " " + val.degreeOfDisabilityName;
              });
            }

            var matchHobbies = "";
            if (
              getData.candidateHobbies.toString() == "[]" ||
              getData.candidateHobbies == [] ||
              getData.candidateHobbies == null ||
              getData.candidateHobbies.toString() == "null"
            ) {
            } else {
              getData.candidateHobbies.forEach((val) => {
                matchHobbies += ", " + val.hobbyName;
              });
              if (
                matchHobbies == null ||
                matchHobbies == "" ||
                matchHobbies == undefined
              ) {
              } else {
                matchHobbies = matchHobbies.replace(", ", "");
              }
            }
            var matchRestrictionStatus = getData.matchRestrictionStatus;
            if (
              getData.candidatePreferredLocations == "[]" ||
              getData.candidatePreferredLocations == [] ||
              getData.candidatePreferredLocations == "null" ||
              getData.candidatePreferredLocations ||
              getData.candidatePreferredLocations == null
            ) {
            } else {
              matchCandidateLocations = getData.candidatePreferredLocations;
            }
            var data = {
              matchId: getData.matchId,
              candidateId: getData.candidateId,
              paymentPlanType: getData.paymentPlanType,
              aboutNote: getData.aboutNote ?? "",
              matchRestrictionStatus: matchRestrictionStatus,
              matchHobbies: matchHobbies,
              matchCandidateLocations: matchCandidateLocations,
              disabilityName: disabilityName,
              disabilityId: disabilityId,
              disabilityDegreeName: disabilityDegreeName,
              videoUrl: getData.extraVideoUrl,
              candidateId: getData.candidateId,
              mobilephone: getData.mobilephone,
              profileImageUrl: getData.profileImageUrl,
              candidateName: getData.name + " " + getData.surname,
              birthDate: age,
              genderId: gender.id,
              email: getData.email.toString() == "null" ? null : getData.email,
              nationalityName: getData.nationalityName.toString(),
              gender: gender.id,
              genderName: gender.name.toString(),
              maritalStatusName: maritalStatus.name.toString(),
              isSmokingName: smoking.name,
              militaryStatusName: military.name,
              driverLicenseName: driving.name,
              driverLicensesId: getData.drivingLicence,
              driverLicenseTypeName:
                getData.candidateDrivingLicenceTypes == null ||
                  getData.candidateDrivingLicenceTypes == [] ||
                  getData.candidateDrivingLicenceTypes.toString() == "[]"
                  ? null
                  : getData.candidateDrivingLicenceTypes,
              yearofexperienceWhiteMatch:
                yearofexperienceWhiteMatch % 1 == 0
                  ? parseInt(yearofexperienceWhiteMatch.toString())
                  : yearofexperienceWhiteMatch,
              pozitionMatchDetailCandidate: pozitionMatchDetailCandidate,
              workTypes: getData.workTypes,
              salaryRange: getData.employeeSalaryRangeName,
              programs: getData.candidatePrograms,
              certificates: getData.candidateCertificates,
              competencies: getData.candidateCompetences,
              weekendWork: weekendWork,
              sector: getData.employeeCandidateSectorPreferences,
              businessTravel:
                getData.employeeCandidateBusinessTravelRestrictions,
              address:
                getData.countryName +
                "/" +
                getData.cityName +
                "/" +
                getData.countyName +
                "/" +
                getData.districtName,
              educationList: eduList,
              experiencesList: addedExperienceList,
              matchLanguageDetailList: matchLanguageDetailList,
              linkedinProfile: getData.linkedinProfile,
            };

            setWhiteMatchDetail(data);
          }
        }
      });
    }
  }, [candidateId]);
  const [matchPoint, setMatchPoint] = useState(null);
  useEffect(() => {
    setMatchPoint(null);
    if (cameWithoutMatchingPage == true) {
      //taleplerim ile eşleşenlerden geldiğimde bu kod bloğu çalışır ve eşleşme oranı gösterilir.
      if (status === 0) {
        //sadece yeni eşleşmede çalışır
        DemandsServices.getMatchPoint(matchId)
          .then((getResponse) => {
            if (getResponse && getResponse.succeeded === true) {
              var getData = getResponse.data;

              var point = {
                isPositionMatched: getData.isPositionMatched,
                isPositionGroupMatched: getData.isPositionGroupMatched,
                isLocationMatched: getData.isLocationMatched,
                isNationMatched: getData.isNationMatched,
                isEducationMatched: getData.isEducationMatched,
                isTravelRestrictionMatched: getData.isTravelRestrictionMatched,
                isLanguageMatched: getData.isLanguageMatched,
                isDrivingLicenseTypeMatched:
                  getData.isDrivingLicenseTypeMatched,
                isWorkTypeMatched: getData.isWorkTypeMatched,
                isExperienceMatched: getData.isExperienceMatched,
                isSalaryMatched: getData.isSalaryMatched,
                isWeekendWorkMatched: getData.isWeekendWorkMatched,
                isAgeRangeMatched: getData.isAgeRangeMatched,
                isCompetencesMatched: getData.isCompetencesMatched,
                isProgramsMatched: getData.isProgramsMatched,
                isCertificatesMatched: getData.isCertificatesMatched,
                isMilitaryStatusMatched: getData.isMilitaryStatusMatched,
                isGenderMatched: getData.isGenderMatched,
                isSectorMatched: getData.isSectorMatched,
                isSmokeMatched: getData.isSmokeMatched,
                isMaritalStatusMatched: getData.isMaritalStatusMatched,
              };
              setMatchPoint(point);
            }
          })
          .catch((err) => {
            console.log("hata oluştu ");
          });
      } else {
        setMatchPoint(null);
      }
    }
  }, [cameWithoutMatchingPage]);

  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    if (whiteMatchDetail) {
      var candidateId = whiteMatchDetail && whiteMatchDetail.candidateId;
      var image = whiteMatchDetail && whiteMatchDetail.profileImageUrl;
      const imageUrl = `${API_URL}/images/candidate/profile/${candidateId}/${image}`;
      setUserImage(imageUrl);
    }
  }, [whiteMatchDetail]);
  const pdfRef2 = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfRef2.current,
    documentTitle: "beyaz-yaka-cv",
    onAfterPrint: () => { },
  });

  return (
    <div>
      {
        whiteMatchDetail && whiteMatchDetail.length <= 0 ? <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <LoadingDotsAnimation />
        </div>

          : <div>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <div
              id="matchDetailBody"
              style={{
                position: "relative",
              }}
              className="card"
            >
              <WhiteInformation
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                img={userImage}
                whiteMatchDetail={whiteMatchDetail}
                matchPoint={matchPoint}
              />
              <WhiteWantToLocation
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                whiteMatchDetail={whiteMatchDetail}
                matchPoint={matchPoint}
              />
              <WhiteWantToWorkPositions
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                whiteMatchDetail={whiteMatchDetail}
                matchPoint={matchPoint}
              />
              <WhiteEducation
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                whiteMatchDetail={whiteMatchDetail}
              />{" "}
              <WorkExperiences
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                whiteMatchDetail={whiteMatchDetail}
              />
              <WhiteAbilities
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                whiteMatchDetail={whiteMatchDetail}
                matchPoint={matchPoint}
              />
              {(whiteMatchDetail && whiteMatchDetail.matchHobbies == null) ||
                (whiteMatchDetail && whiteMatchDetail.matchHobbies == undefined) ||
                (whiteMatchDetail && whiteMatchDetail.matchHobbies == "" ? (
                  <div></div>
                ) : (
                  <WhiteHobbies whiteMatchDetail={whiteMatchDetail} />
                ))}
              {whiteMatchDetail && whiteMatchDetail.paymentPlanType === 5 ? (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "10px 0px 20px 0px",
                    }}
                  >
                    <div
                      style={{
                        margin: "10px",
                      }}
                    >
                      <CustomInfoPage
                        color={"white"}
                        title={
                          "Adayın Detaylı Bilgilerini Görmek İçin Paket Seçiminizi Yapın"
                        }
                        subTitle={
                          "Adayın tüm detaylı bilgilerine erişim sağlamak için paket satın almanız gerekmektedir."
                        }
                      />
                    </div>
                    <Box sx={{
                      marginBottom: "10px"

                    }}>
                      <CustomButton
                        onTap={() => {
                          history.push("/Packets");
                        }}
                        title={"Paket Satın Al"}
                        width={{
                          xs: "380px",
                          sm: "380px",
                          md: "450px",
                          lg: "450px",
                          xl: "600px",
                        }}
                      />
                    </Box>
                  </div>
                </div>
              ) : (<WhiteMediumActions
                title={props.authentication.user.title}
                cardStyle={cardStyle}
                labelStyle={labelStyle}
                inputStyle={inputStyle}
                matchId={matchId}
                status={status}
                employerId={employerId}
                companyId={companyId}
                whiteMatchDetail={whiteMatchDetail}
              />)}
              {whiteMatchDetail && whiteMatchDetail.paymentPlanType === 5 ? (
                <div
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    width: "50px",
                    cursor: "pointer",
                  }}
                >
                  <div
                    onClick={() => {
                      setInfoShow(true);
                    }}
                    style={{
                      margin: "10px",
                      width: "50px",
                      height: "50px",
                      backgroundColor: "#F5F5F5",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      marginBottom: "0px",
                      cursor: "pointer",
                      marginTop: "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "20px", // 0px ve üzeri ekran genişlikleri için
                          sm: "20px", // 600px ve üzeri ekran genişlikleri için
                          md: "20px", // 900px ve üzeri ekran genişlikleri için
                          lg: "20px", // 1200px ve üzeri ekran genişlikleri için
                          xl: "20px", // 1536px ve üzeri ekran genişlikleri için,
                        },
                        fontWeight: 600,
                      }}
                    >
                      🔐
                    </Typography>
                  </div>
                </div>
              ) : (<div>
                <div
                  style={{
                    position: "absolute",
                    top: "20px",
                    right: "20px",
                    width: "100px",
                  }}
                >
                  {whiteMatchDetail && <PdfWidget handlePrint={handlePrint} />}
                </div>
                <div
                  style={{
                    display: "none",
                  }}
                >
                  <WhiteCandidateCv
                    whiteMatchDetail={whiteMatchDetail}
                    pdfRef2={pdfRef2}
                  />
                </div>
              </div>)}
            </div>
          </div>

      }
      <MatchDetailInfoModal show={infoShow} handleClose={handleInfoClose} />
    </div>);
};

function mapState(state) {
  let authentication = state.authentication;
  return {
    authentication,
  };
}

export default connect(mapState)(WhiteMatchMediumDetail);
var inputStyle = {
  borderRadius: "8px",
  height: "55px",
  backgroundColor: "#F5F5F5",
  margin: "0px",
};
var cardStyle = {
  // border: "1.1px dotted orange",
  padding: "10px",
  margin: "5%",
};
var cardParentStyle = {
  border: "1.1px dotted orange",
  padding: "10px",
};
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
const customStylesMulti = {
  multiValue: (provided) => ({
    ...provided,
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: "6px",
    padding: "10px 10px 10px 0px",
    border: "1px solid rgb(226, 226, 226)",
    fontSize: "15px",
    color: "#001542",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue etiket yazı boyutu
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    fontSize: "15px", // MultiValue kaldırma simgesi yazı boyutu
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px", // Font boyutunu 9px olarak ayarlayın
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "13px", // Placeholder yazı boyutu
    marginLeft: "2px",
  }),
};
const labelStyle = {
  fontSize: "15px",
};
