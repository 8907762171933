import { Box, Typography } from "@mui/material";
import React from "react";
import AvatarGlow from "../AvatarGlow";

const CustomInfoPage = ({ title, subTitle, color,avatarGlowColor }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: color ?? "#f5f5f5",
        padding: "20px 20px 30px 30px",
        borderRadius: "8px",
        marginBottom: "0px",
        marginTop: "0px",
      }}
    >
      <Box sx={{}}>
        <Box
          sx={{
            marginLeft: "20px",
            width: {
              xs: "60px",
              sm: "80px",
              md: "80px",
              lg: "80px",
              xl: "90px",
            },
          }}
        >
          <AvatarGlow
            initialRadius={25}
            valueMaxScale={4}
            glowColor="red"
            waveDuration={3}
            startColor={avatarGlowColor??"red"}
            endColor={avatarGlowColor??"red"}
          >
            <i
              class="fa-solid fa-exclamation"
              style={{
                color: "red",
              }}
            ></i>
          </AvatarGlow>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignContent: "flex-start",
          alignItems: "flex-start",
          marginLeft: "0px",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "10px",
              sm: "12px",
              md: "12px",
              lg: "14px",
              xl: "14px",
            },
            fontWeight: "bold",
            color: "red",
          }}
        >
          {title}
        </Typography>{" "}
        <Typography
          sx={{
            fontSize: {
              xs: "10px",
              sm: "12px",
              md: "12px",
              lg: "14px",
              xl: "14px",
            },

            fontWeight: 400,
            color: "rgb(88, 88, 88)",
          }}
        >
          {subTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomInfoPage;
