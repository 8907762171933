import { UserConstants } from "../constants";
import {
  DemandsServices,
  ProfileDetailServices,
  UserService,
} from "../services";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import api from "../utility/Utils";
import { setCompanyData } from "../redux/redux-toolkit/companyInfo";
import { EmployerSmsType } from "../jsx/widgets/EmployerSmsType";
import {
  removeCookie,
  setCookie,
  setToken,
  setUser,
  setVerifyCaptcha,
} from "../configs/cookie";

export const UserAction = {
  login,
  own,
  activation,
  lostPassword,
  resetPassword,
  logout,
  register,
  registerDetails,
  updateProfile,
  updateEmail,
  updatePassword,
  updatePermissions,
  getAll,
  get,
  delete: _delete,
  loginLastVersion,
  registerLastVersion,
};
function login(user_mail, password, history, dispatch2) {
  return (dispatch) => {
    dispatch(request({ user_mail }));

    UserService.login(user_mail, password).then(
      async (gelenData) => {
        dispatch(success(gelenData.data.accessToken));

        const r = await ProfileDetailServices.companyMainDetail();

        history.push(r ? "/" : "/register-details");

        dispatch2(setCompanyData(gelenData));

        if (r == null) {
        } else {
          window.location.reload();
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.LOGIN_REQUEST, user };
  }

  function success(token) {
    return { type: UserConstants.LOGIN_SUCCESS, token };
  }

  function failure(error) {
    return { type: UserConstants.LOGIN_FAILURE, error };
  }
}
function saveLUser(response) {
  setUser(response.data);
  return Promise.resolve(response.data);
}
function loginLastVersion(user_mail, password, history, dispatch2) {
  //nolur nolmaz diye eski yazılmış login fonksiyonlarına karışmadım,bu yüzden yeniden yazdım
  //burda login yaaprken email confirmed hatası verirse;
  //sms confirmed doğrulama sayfasına gönderiyorum,doğrulanmış ise ana sayfaya yönlendiriyorum yönlendiirlirken şirket kontrolü isteği rota yönlendirmede atıyor.
  return (dispatch) => {
    dispatch(request({ user_mail }));

    UserService.loginLastVersion(user_mail, password).then(
      async (resData) => {
        if (resData) {
          if (resData.succeeded == true) {
            removeCookie("expirationEmployerLoginTime");
            dispatch(success(resData.data.accessToken));
            const r = await ProfileDetailServices.companyMainDetail();
            history.push(r ? "/" : "/register-details");
            window.location.reload();
          } else {
            if (resData.error.code === 114) {
              removeCookie("expirationEmployerLoginTime");
              //mail onaylanmamış.
              toast.success(resData.error.message);
              const dataMap = new Map();
              dataMap.set("EmployerSmsType", EmployerSmsType.REGISTER);
              dataMap.set("getEmail", user_mail);
              history.push({
                pathname: "/page-email-otp",
                state: { dataMap },
              });
              window.location.reload();
            } else if (resData.error.code === 156) {
              toast.error(resData.error.message);
              setVerifyCaptcha(
                "expirationEmployerLoginTime",
                resData.data.expiration
              );
            } else {
              toast.error(resData.error.message);
            }
          }
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.LOGIN_REQUEST, user };
  }

  function success(token) {
    return { type: UserConstants.LOGIN_SUCCESS, token };
  }

  function failure(error) {
    return { type: UserConstants.LOGIN_FAILURE, error };
  }
}
function own() {
  return (dispatch) => {
    UserService.own().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(logout());
      }
    );
  };

  function success(user) {
    return { type: UserConstants.OWN_SUCCESS, user };
  }
}

function activation(code) {
  return (dispatch) => {
    dispatch(request({ code }));
    UserService.activation(code).then(
      (user) => {
        //console.log('Hesabınız başarıyla etkinleştirildi.');
        dispatch(success(code));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(code) {
    return { type: UserConstants.LOGIN_REQUEST, code };
  }

  function success(code) {
    return { type: UserConstants.LOGIN_SUCCESS, code };
  }

  function failure(error) {
    if (error.response.status === 401 || error.response.status === 400) {
      if (error.response.data && error.response.data.detail) {
        //console.log(error.response.data.detail);
      }
    }

    return { type: UserConstants.LOGIN_FAILURE, error };
  }
}
function lostPassword(email, history) {
  UserService.lostPassword(email).then(
    (user) => {
      if (user) {
        if (user.succeeded === true) {
          const dataMap = new Map();
          dataMap.set("EmployerSmsType", EmployerSmsType.FORGOT);
          dataMap.set("getEmail", email);
          history.push({
            pathname: "/page-email-otp",
            state: { dataMap },
          });
          window.location.reload();
        } else {
          toast(user.error.message);
        }
      } else {
        toast("Hata Oluştu");
      }
    },
    (error) => {
      toast("Hata Oluştu");
      //console.log(error.toString());
    }
  );
}
function resetPassword(password, re_password, code) {
  UserService.resetPassword(password, re_password, code).then(
    (user) => {
      // Swal.fire({
      //     position: 'top-end',
      //     icon: 'success',
      //     title: 'Şifreniz başarıyla değiştirilmiştir.',
      //     showConfirmButton: false,
      //     timer: 3000
      //   })
    },
    (error) => {}
  );
}

function logout(message = false) {
  return (dispatch) => {
    if (UserService.logout()) {
      if (message) {
        //console.log('Çıkış Yaptınız.');
      }
      dispatch({ type: UserConstants.LOGOUT });
    }
  };
}

function register(user, history) {
  return (dispatch) => {
    dispatch(request(user));

    UserService.register(user, history).then(
      (user) => {
        dispatch(success());
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.REGISTER_REQUEST, user };
  }

  function success(user) {
    return { type: UserConstants.REGISTER_SUCCESS, user };
  }

  function failure(error) {
    return { type: UserConstants.REGISTER_FAILURE, error };
  }
}
function registerLastVersion(user, history) {
  return (dispatch) => {
    dispatch(request(user.email));
    UserService.registerLastVersion(user, history).then(
      (res) => {
        if (res) {
          if (res.succeeded == true) {
            if (res.result.code === 201) {
              toast.success(res.result.message);
              const dataMap = new Map();
              dataMap.set("EmployerSmsType", EmployerSmsType.REGISTER);
              dataMap.set("getEmail", user.email);
              history.push({
                pathname: "/page-email-otp",
                state: { dataMap },
              });
              window.location.reload();
            } else {
              UserService.loginLastVersion(user.email, user.password).then(
                async (resData) => {
                  if (resData) {
                    if (resData.succeeded == true) {
                      dispatch(success(resData.data.accessToken));
                      setToken(resData.data.accessToken);
                      saveLUser(resData);
                      history.push("/register-details");
                      window.location.reload();
                    } else {
                      if (resData.error.code === 114) {
                        //mail onaylanmamış.
                        toast.success(resData.error.message);
                        const dataMap = new Map();
                        dataMap.set(
                          "EmployerSmsType",
                          EmployerSmsType.REGISTER
                        );
                        dataMap.set("getEmail", user.email);
                        history.push({
                          pathname: "/page-email-otp",
                          state: { dataMap },
                        });
                        window.location.reload();
                      } else {
                        toast.error(resData.error.message);
                      }
                    }
                  }
                }
              );
            }
          } else {
            if (res.error.code == 114) {
              toast.success(res.error.message);
              const dataMap = new Map();
              dataMap.set("EmployerSmsType", EmployerSmsType.REGISTER);
              dataMap.set("getEmail", user.email);
              history.push({
                pathname: "/page-email-otp",
                state: { dataMap },
              });
            } else {
              toast.error(res.error.message);
              history.push("/");
            }
          }
        }
      },
      (error) => {}
    );
  };
  function request(user) {
    return { type: UserConstants.LOGIN_REQUEST, user };
  }

  function success(token) {
    return { type: UserConstants.LOGIN_SUCCESS, token };
  }

  function failure(error) {
    return { type: UserConstants.LOGIN_FAILURE, error };
  }
}
export const request = (user) => {
  return {
    type: "REQUEST",
    payload: user,
  };
};

function registerDetails(companyDetails, history, secondsRef) {
  return (dispatch) => {
    const userFromCookie = Cookies.get("user");
    const user = userFromCookie ? JSON.parse(userFromCookie) : {};
    const {
      isReadServiceContract,
      isAllowContactInformation,
      companyName,
      ...otherUserValues
    } = user;
    const mergedUser = {
      ...otherUserValues,
      name: companyName,
      ...companyDetails,
    };
    dispatch(request(mergedUser));
    UserService.registerDetails(mergedUser, history).then(
      (companyDetailsData) => {
        if (companyDetailsData && companyDetailsData.succeeded == true) {
       
          var sendData = {
            actionTypeId: 3,
            duration: secondsRef.current,
            actionRelatedId: null,
          };
          DemandsServices.currentUserActionPageLog(sendData).then(
            (pageLogRes) => {
              if (pageLogRes && pageLogRes.succeeded === true) {
              }
            },
            (error) => {}
          );

          history.push("/");
          window.location.reload();
        } else if (
          companyDetailsData &&
          companyDetailsData.succeeded == false
        ) {
          toast(companyDetailsData.error.message);
        }
      },
      (error) => {
        // dispatch(failure(error.toString()));
      }
    );
  };
}
function getAll() {
  return (dispatch) => {
    dispatch(request());

    UserService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: UserConstants.GET_ALL_REQUEST };
  }

  function success(users) {
    return { type: UserConstants.GET_ALL_SUCCESS, users };
  }

  function failure(error) {
    return { type: UserConstants.GET_ALL_FAILURE, error };
  }
}

function get(user, type = "get") {
  return (dispatch) => {
    dispatch(request());
    return UserService.get(user, type).then(
      (user) => dispatch(success(user)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return { type: UserConstants.GET_REQUEST };
  }

  function success(user) {
    return { type: UserConstants.USER_SUCCESS, user };
  }

  function failure(error) {
    return { type: UserConstants.GET_FAILURE, error };
  }
}

function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    UserService.delete(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.toString()))
    );
  };

  function request(id) {
    return { type: UserConstants.DELETE_REQUEST, id };
  }

  function success(id) {
    return { type: UserConstants.DELETE_SUCCESS, id };
  }

  function failure(id, error) {
    return { type: UserConstants.DELETE_FAILURE, id, error };
  }
}

function updateProfile(arg = {}) {
  return (dispatch) => {
    dispatch(request(arg));
    UserService.updateProfile(arg).then(
      (user) => {
        //console.log('Bilgileriniz Güncellendi.');
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.GET_REQUEST, user };
  }

  function success(user) {
    return { type: UserConstants.OWN_SUCCESS, user };
  }

  function failure(error) {
    return { type: UserConstants.GET_FAILURE, error };
  }
}

function updateEmail(arg = {}) {
  return (dispatch) => {
    dispatch(request(arg));
    UserService.updateEmail(arg).then(
      (user) => {
        //console.log('Telefon Numaranız Güncellendi. Lütfen E-Posta kutunuza gelen kod ile E-Posta hesabınızı aktif ediniz');
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.GET_REQUEST, user };
  }

  function success(user) {
    return { type: UserConstants.GET_SUCCESS, user };
  }

  function failure(error) {
    return { type: UserConstants.GET_FAILURE, error };
  }

  return UserService.updateEmail(arg);
}

function updateGsm(arg = {}) {
  return (dispatch) => {
    dispatch(request(arg));
    UserService.updateGsm(arg).then(
      (user) => {
        //console.log('Telefon Numaranız Güncellendi. Lütfen telefon numaranıza gelen kod ile telefonunuzu aktif ediniz');
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.GET_REQUEST, user };
  }

  function success(user) {
    return { type: UserConstants.GET_SUCCESS, user };
  }

  function failure(error) {
    return { type: UserConstants.GET_FAILURE, error };
  }
}

function updatePassword(arg = {}) {
  return (dispatch) => {
    dispatch(request(arg));
    UserService.updatePassword(arg).then(
      (user) => {
        //console.log('Şifreniz Güncellendi.');
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.GET_REQUEST, user };
  }

  function success(user) {
    return { type: UserConstants.GET_SUCCESS, user };
  }

  function failure(error) {
    return { type: UserConstants.GET_FAILURE, error };
  }
}

function updatePermissions(arg = {}) {
  return (dispatch) => {
    dispatch(request(arg));
    UserService.updatePermissions(arg).then(
      (user) => {
        //console.log('İzinleriniz Güncellendi.');
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request(user) {
    return { type: UserConstants.GET_REQUEST, user };
  }

  function success(user) {
    return { type: UserConstants.GET_SUCCESS, user };
  }

  function failure(error) {
    return { type: UserConstants.GET_FAILURE, error };
  }
}
