import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { StepConnector } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import EasySteps from "../EasySteps";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { useRef } from "react";

import FindJobSeekerRoutes from "../Common/FindJobSeekerRoutes";
import "../../../../../../css/bitoniscss/stepper.css";
import FindJobSeekerIsPreviewModal from "../Common/FindJobSeekerIsPreviewModal";

import { connect, useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RouteIsEmptyModal from "../Common/RouteIsEmptyModal";
import BitonisStepper from "../BitonisStepper";
import InternCompanyLocation from "./partOne/InternCompanyLocation";
import FindInternFeatures from "./partTwo/FindInternFeatures";
import sendDemandIntern from "../services/sendDemandIntern";
import StepperNextButton from "../../../../../widgets/buttons/StepperNextButton";
import PageInfoTitle from "../../../../../widgets/PageInfoTitle";

var stepHeader = [
  {
    icon: <i class="fa-solid fa-route"></i>,
    title: "Görüşme Lokasyonu",
    description: "",
  },

  {
    icon: <i class="fa-regular fa-id-card"></i>,
    title: "Aradığınız Özellikler",
    description: "Açıklama 3",
  },

  {
    icon: <i class="fa-solid fa-location-dot"></i>,
    title: "Konum",
    description: "",
  },
];

const FindWhiteSeeker = (props) => {
  //----------
  const [seconds, setSeconds] = useState(0);
  const secondsRef = useRef(0);

  // Zamanlayıcıyı başlat
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => {
        secondsRef.current = prev + 1;
        return prev + 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const [companyId, setCompanyId] = useState(null);
  const [showEmptyRoutes, setShowEmptyRoutes] = useState(null);
  function handleChangeRoutesShow() {
    setShowEmptyRoutes(false);
  }
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const formRef = useRef({});

  function handleChangeRoutesShow() {
    setShowEmptyRoutes(false);
  }
  const [showPreview, setShowPreview] = useState(null);
  function handleChangeStepPreviewShow() {
    setShowPreview(false);
  }
  const [eduStatus, setEduStatus] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRouteList, setSelectedRouteList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyLocationName, setCompanyLocationName] = useState("");

  const stepBody = [
    <InternCompanyLocation
      register={register}
      submitNewVersion={submitForm}
      handleSubmit={handleSubmit}
      control={control}
      watch={watch}
      reset={reset}
      setValue={setValue}
      errors={errors}
      getValues={getValues}
    />,
    <FindInternFeatures
      register={register}
      handleSubmit={handleSubmit}
      submitNewVersion={submitForm}
      control={control}
      watch={watch}
      reset={reset}
      setValue={setValue}
      errors={errors}
      companyName={companyName}
      companyLocationName={companyLocationName}
      getValues={getValues}
      sectionList={sectionList}
      setSectionList={setSectionList}
    />,
    <FindJobSeekerRoutes
      register={register}
      handleSubmit={handleSubmit}
      submitNewVersion={submitForm}
      control={control}
      watch={watch}
      reset={reset}
      setValue={setValue}
      errors={errors}
      companyId={companyId}
      selectedRouteList={selectedRouteList}
      setSelectedRouteList={setSelectedRouteList}
      companyName={companyName}
      companyLocationName={companyLocationName}
      getValues={getValues}
      candidateType={"intern"}
    />,
  ];

  function changeStepPrev() {
    if (currentStep > 0) {
      setCurrentStep((prevActiveStep) => prevActiveStep - 1);
    }
  }

  function changeStep() {
    setCurrentStep((prevActiveStep) => prevActiveStep + 1);
  }

  function submitForm(form) {
    //verileri okumanın en sağlıklı yöntemi
    formRef.current = { ...formRef.current, form };
    if (currentStep == 0) {
      setCompanyName(formRef.current.form.companyId.label);
      setCompanyLocationName(formRef.current.form.companyLocationId.label);
      if (
        formRef.current.form.companyLocationId == undefined ||
        formRef.current.form.companyLocationId === "" ||
        formRef.current.form.companyLocationId === null
      ) {
        toast.info("Görüşme Lokasyonu Boş Geçilemez", {
          autoClose: 3000,

          onClose: () => {
            setTimeout(() => { }, 100);
          },
        });
      } else {
        changeStep();
      }
    } else if (currentStep == 1) {
      setCompanyId(formRef.current.form.companyId.value);
      changeStep();
    } else if (currentStep == 2) {
      if (selectedRouteList.length <= 0) {
        toast.info("Lütfen Güzergah Seçin", {
          autoClose: 3000,
          onClose: () => {
            setTimeout(() => { }, 100);
          },
        });
      } else {
        if (showPreview == true) {
          setShowPreview(false);
          sendDemandIntern(form, props, selectedRouteList, history, secondsRef);
        } else {
          setShowPreview(true);
        }
      }
    } else {
    }
  }

  return (
    <>
      <div id="stepBody">
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            backgroundColor: "white",
            width: "200px",
            marginBottom: "0px",
            borderRadius: "8px 8px 0px 0px",
            padding: "5px 5px 0px 5px",
            borderBottom: "1px dotted #F5F7F8",
          }}
        >
          <PageInfoTitle
            title={"Stajyer Talep Oluştur"}
            style={{
              fontSize: {
                xs: "16px",
                sm: "16px",
                md: "16px",
                lg: "16px",
                xl: "16px",
              },
            }}
          />
        </Box>
        <BitonisStepper
          stepsHeader={stepHeader}
          currentStep={currentStep}
          changePrev={changeStepPrev}
          companyName={companyName}
          companyLocationName={companyLocationName}
        >
          <form onSubmit={handleSubmit(submitForm)}>
            {stepBody[currentStep]}
            <div
              className="card"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <StepperNextButton
                title={currentStep === 2 ? "Talebimi Yayınla" : "İlerle"}
                currentStep={currentStep}
              />
            </div>
          </form>
        </BitonisStepper>
      </div>
      <RouteIsEmptyModal
        show={showEmptyRoutes}
        handleClose={handleChangeRoutesShow}
      />
      <FindJobSeekerIsPreviewModal
        show={showPreview}
        handleClose={handleChangeStepPreviewShow}
        handleSubmit={handleSubmit}
        submitNewVersion={submitForm}
        isIntern={true}
      />
    </>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let companyLocation = state.companyLocation;

  let companyItemLocation = state.companyItemLocation;

  //alert("companyItemLocation * * * * * "+JSON.stringify(companyItemLocation))
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;

  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    companyLocation,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    companyItemLocation,
  };
}

export default connect(mapState)(FindWhiteSeeker);
