import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import {
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import AddPositionNotFoundModal from "../../Common/AddPositionNotFoundModal";

const FindBluePosition = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    getValues,
    interviewContactValue,
    setInterviewContactValue,
  } = props;
  const [showPosition, setShowPosition] = useState(null)
  function positionHandleClose() {
    setShowPosition(false)
  }


  useEffect(() => {
    if (!props.positionList.success) {
      props.dispatch(
        DropListAction.positionList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.positionListWhite.success) {
      props.dispatch(
        DropListWhiteAction.positionListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.yearOfExperience.success) {
      props.dispatch(
        DropListAction.yearOfExperience({
          positionType: 1,
          isActive: true,
        })
      );
    }

    if (!props.wayOfWorking.success) {
      props.dispatch(
        DropListAction.wayOfWorking({
          positionType: 1,
          isActive: true,
        })
      );
    }

    if (!props.nationalityList.success) {
      props.dispatch(
        DropListAction.nationalityList({
          positionType: 1,
          isActive: true,
        })
      );
    }
    if (!props.ageRangeList.success) {
      props.dispatch(
        DropListAction.ageRangeList({
          positionType: 1,
          isActive: true,
        })
      );
    }

    if (!props.programList.success) {
      props.dispatch(DropListAction.programList({}));
    }
    if (!props.certificatesList.success) {
      props.dispatch(DropListAction.certificatesList({}));
    }
    if (!props.certificatesListWhite.success) {
      props.dispatch(
        DropListWhiteAction.certificatesListWhite({
          isActive: true,
        })
      );
    }
    if (!props.competencesList.success) {
      props.dispatch(DropListAction.competencesList({}));
    }
    if (!props.competencesListWhite.success) {
      props.dispatch(
        DropListWhiteAction.competencesListWhite({
          positionGroupCode: "P20",
          isActive: true,
        })
      );
    }

    if (!props.companyList.success) {
      props.dispatch(DropListAction.companyList());
    }
    if (!props.companyLocation.success) {
      props.dispatch(DropListAction.companyLocation());
    }
    if (!props.disabilityOfDegreeList.success) {
      props.dispatch(DropListAction.getDisabilityOfDegreeList());
    }
  }, []);

  // console.log(props)
  const gorusmeGunleriList = [
    //kontrol edildi
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Pazartesi",
    },
    {
      id: 2,
      name: "Salı",
    },
    {
      id: 3,
      name: "Çarşamba",
    },
    {
      id: 4,
      name: "Perşembe",
    },
    {
      id: 5,
      name: "Cuma",
    },
    {
      id: 6,
      name: "Cumartesi",
    },
    {
      id: 7,
      name: "Pazar",
    },
  ];
  const preferredeEmployerContactCandidateList = [
    {
      id: 1,
      name: "Belirleyeceğim günlerde benimle görüşmeye gelsinler",
    },
    {
      id: 2,
      name: "Talebim ile eşleşen adaylarla iletişime geçtikten sonra, benimle görüşmeye gelsinler.",
    },
  ];
  const educationStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "İlkokul" },
    { id: 2, name: "Ortaokul" },
    { id: 3, name: "Lise" },
    { id: 4, name: "Ön Lisans" },
    { id: 5, name: "Lisans" },
    { id: 6, name: "Yüksek Lisans" },
    { id: 7, name: "Doktora" },
  ];
  var disabilityEmployerList = [
    { id: 1, name: "Farketmez" },
    { id: 2, name: "Evet" },
    { id: 0, name: "Hayır" },
  ];
  const handlePositionSelect = (selected) => {
    props.dispatch(DropListAction.getProfileFillPcc([selected.groupCode]));
  };
  const jobInterviewType = watch("jobInterviewType");
  const disabilityStatus = watch("disabilityStatus");

  return (
    <div>
      <div
        className="card"
        style={{
          boxShadow: "none",
          margin: "0px",
          padding: "0px",
        }}
      >
        <form onSubmit={handleSubmit(submitNewVersion)}>
          <CustomDesign
            label={"Pozisyon"}
            required={true}
            child={
              <BitonisSingleDropdown
                items={
                  props.positionList.positionList &&
                  props.positionList.positionList.map(
                    ({ id, name, groupCode }) => ({
                      value: id,
                      label: name,
                      groupCode,
                    })
                  )
                }
                register={register}
                watch={watch}
                companyClassName={"positionId"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={"Pozisyon Boş Geçilemez"}
                placeholder="Pozisyon Seçin"
                infoText={"Mavi yakada bulamadığınız pozisyonlar beyaz yakada mevcut olabilir."}

                onValueChanged={(e) => {
                  console.log(e);
                  if (e) {
                    setValue("jobPrograms", "");
                    setValue("jobCertificates", "");
                    setValue("jobCompetences", "");
                    handlePositionSelect(e); // Pozisyon seçimini işle
                  }
                }}
                required={true}
                getValues={getValues}
                showRightBox={true}
                onRightBoxTap={
                  (val) => {
                    setShowPosition(true)
                  }
                }
              />
            }
          />

          <CustomDesign
            label={"Tecrübe Yılı"}
            required={true}
            child={
              <BitonisMultiDropdown
                items={
                  props.yearOfExperience.yearOfExperience &&
                  props.yearOfExperience.yearOfExperience.map(
                    ({ id, name }) => ({
                      value: id,
                      label: name,
                    })
                  )
                }
                register={register}
                watch={watch}
                companyClassName={"jobExperienceRanges"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={"Tecrübe Yılı Boş geçilemez"}
                placeholder="Tecrübe Yılını Seçin"
                onValueChanged={(value) => {
                  console.log(value);
                }}
                selectedId={1}
                required={true}
                getValues={getValues}
              />
            }
          />

          <CustomDesign
            label={"Çalışma Şekli"}
            required={true}
            child={
              <BitonisMultiDropdown
                items={
                  props.wayOfWorking.wayOfWorking &&
                  props.wayOfWorking.wayOfWorking.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"workTypes"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={"Çalışma Şekli Boş geçilemez"}
                placeholder="Çalışma Şeklini Seçin"
                onValueChanged={(value) => {
                  console.log(value);
                }}
                required={true}
                getValues={getValues}
              />
            }
          />
          <CustomDesign
            label={"Tercih Edilen Görüşme Günleri"}
            required={true}
            child={
              <BitonisMultiDropdown
                items={gorusmeGunleriList.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                register={register}
                watch={watch}
                companyClassName={"jobInterviewDays"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={"Terci Edilen Görüşme Günleri Boş geçilemez"}
                placeholder="Tercih Edilen Görüşme Gününü Seçin "
                onValueChanged={(value) => {
                  console.log(value);
                }}
                required={true}
                selectedId={0}
                getValues={getValues}
                interviewContactBoolean={true}
                interviewContactValue={interviewContactValue}
                setInterviewContactValue={setInterviewContactValue}
                infoText={
                  "Tercih Ettiğiniz Günlere Göre Eşleşme Yapılarak,eşleşen adaylar Yönlendirilecektir"
                }
              />
            }
          />
          <CustomDesign
            label={"Eğitim Durumu"}
            required={true}
            child={
              <BitonisSingleDropdown
                items={educationStatus.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                register={register}
                watch={watch}
                companyClassName={"educationTypes"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={"Eğitim Durumu Boş Geçilemez"}          
                placeholder="Eğitim Durumunu Seçin"
                infoText={"Minimum Eğitim Durumunu seçiniz."}
                onValueChanged={(e) => {
                  console.log(e);
                  if (e) {
                  }
                }}
                required={true}
                getValues={getValues}
              />
            }
          />
          <CustomDesign
            label={"Engellilik Durumu"}
            required={true}
            child={
              <BitonisSingleDropdown
                items={
                  disabilityEmployerList &&
                  disabilityEmployerList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"disabilityStatus"}
                control={control}
                setValue={setValue}
                errors={errors}
                errorMessage={"Engellilik Durumu Boş geçilemez"}
                placeholder="Engellilik Durumu Seçin"
                onValueChanged={(e) => {
                  console.log(e);
                  if (e) {
                    if (
                      e.value.toString() == "0" ||
                      e.value.toString() == "1"
                    ) {
                      setValue("degreeOfDisability", "");
                    }
                  }
                }}
                required={true}
                getValues={getValues}
              />
            }
          />

          {(disabilityStatus && disabilityStatus.value === 0) ||
            (disabilityStatus && disabilityStatus.value === "") ||
            (disabilityStatus && disabilityStatus.value === null) ||
            disabilityStatus === "" ||
            disabilityStatus === undefined ||
            (disabilityStatus && disabilityStatus.value === 1) ? (
            <div></div>
          ) : (
            <CustomDesign
              label={"Engellilik Seviyesi"}
              required={true}
              child={
                <BitonisMultiDropdown
                  items={
                    props.disabilityOfDegreeList.disabilityOfDegreeList &&
                    props.disabilityOfDegreeList.disabilityOfDegreeList.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"degreeOfDisability"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Engellilik Seviyesi Boş geçilemez"}
                  placeholder="Engellilik Seviyesi Seçin"
                  onValueChanged={(value) => {
                    console.log(value);
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />
          )}
        </form>
      </div>
      <AddPositionNotFoundModal
        show={showPosition}
        handleClose={positionHandleClose}
        candidateType="blue"


      />
    </div>
  );
};
function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let companyLocation = state.companyLocation;

  let companyItemLocation = state.companyItemLocation;

  //alert("companyItemLocation * * * * * "+JSON.stringify(companyItemLocation))
  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;
  let disabilityOfDegreeList = state.disabilityOfDegreeList;

  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    companyLocation,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    companyItemLocation,
    disabilityOfDegreeList,
  };
}

export default connect(mapState)(FindBluePosition);
