import React, { useEffect } from "react";
import moment from "moment";
import CustomPreview from "../../../../../../../widgets/CustomPreview";
import PrevIconButton from "../../../../../../../widgets/buttons/PrevIconButton";

const InternInformation = ({
  cardStyle,
  labelStyle,
  inputStyle,
  internMatchDetail,
  componentRef,
}) => {

  return (
    <div>
      <>
        <div
          class=""
          style={{
            padding: "10px",
          }}
        >
          <div
            class="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <h5 class="card-title">Kişisel Bilgileri</h5>
          </div>
          <div class="card-body">
            <CustomPreview
              name={"Yaş"}
              value={
                internMatchDetail &&
                internMatchDetail.birthDate &&
                internMatchDetail.birthDate.toString()
              }
            />
            <CustomPreview
              name={"Uyruk"}
              value={internMatchDetail && internMatchDetail.nationalityName}
            />{" "}
            <CustomPreview
              name={"Cinsiyet"}
              value={internMatchDetail && internMatchDetail.genderName}
            />{" "}
            <CustomPreview
              name={"Eğitim"}
              value={internMatchDetail && internMatchDetail.educationTypeName}
            />{" "}
            <CustomPreview
              name={"Bölüm"}
              value={internMatchDetail && internMatchDetail.sectionName}
            />{" "}
            <CustomPreview
              name={"Lise"}
              value={internMatchDetail && internMatchDetail.eduClassName}
            />
          </div>
        </div>{" "}
        <div
          style={{
            display: "none",
          }}
        ></div>
           <PrevIconButton top={15} left={10} />
      </>
    </div>
  );
};

export default InternInformation;
