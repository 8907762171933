import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import api from "../../../../../../../utility/Utils";
import { ToastContainer, toast } from "react-toastify";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import { DemandsServices } from "../../../../../../../services";
import BlueDemandPackageUseItem from "./widgets/BlueDemandPackageUseItem";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import WhiteDemandPackageUseItem from "./widgets/WhiteDemandPackageUseItem";
import demandNotFound from "../../../../../../../images/svg/demandNotFound.svg";

const PackageCandidateDemandUsageScreen = ({
  show,
  handleClose,
  candidateType,
}) => {
  const history = useHistory();
  const [demands, setDemands] = useState(null);
  const [packageInformation, setPackageInformation] = useState(null);
  const [selectDemandUseList, setSelectDemandUseList] = useState([]);

  useEffect(() => {
    getCurrentPackets();
  }, []);

  function _getAllShowBlue() {
    var sendData = { isActive: true, positionType: 1 };
    DemandsServices.demandsBlue(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setDemands([]);
          const filteredDemands = demand.data.filter(
            (value) => value.paymentPlanType == 5
          );
          setDemands(filteredDemands);
        } else {
          handleClose();
        }
      },
      (error) => {
        handleClose();
      }
    );
  }
  function _getAllShowWhite() {
    var sendData = { isActive: true, positionType: 2 };
    DemandsServices.demandsBlue(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setDemands([]);
          const filteredDemands = demand.data.filter(
            (value) => value.paymentPlanType == 5
          );
          setDemands(filteredDemands);
        } else {
          handleClose();
        }
      },
      (error) => {}
    );
  }
  function _getAllShowIntern() {
    var sendData = { isActive: true, positionType: 3 };
    DemandsServices.demandsIntern(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setDemands([]);
          const filteredDemands = demand.data.filter(
            (value) => value.paymentPlanType == 5
          );
          setDemands(filteredDemands);
        } else {
          handleClose();
        }
      },
      (error) => {}
    );
  }
  async function getCurrentPackets() {
    setPackageInformation(null);
    api
      .get("payment/getCompanyPermission")
      .then((response) => {
        if (response.succeeded === true) {
          var data = response.data;
          setPackageInformation(data);
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    if (show) {
      setDemands(null);
      if (candidateType == "blue") {
        _getAllShowBlue();
      } else if (candidateType == "white") {
        _getAllShowWhite();
      } else if (candidateType == "intern") {
        _getAllShowIntern();
      }
    }
  }, [show]);

  async function jobChangePaymentPlan() {
    if (packageInformation !== null && packageInformation.currentPlanId === 5) {
      history.push("/activepackets");
    } else {
      if (selectDemandUseList.length <= 0) {
        toast.error("Lütfen Seçim Yapın", {
          autoClose: 2000,

          onClose: () => {
            setTimeout(() => {}, 100);
          },
        });
      } else {
        var sendIds = selectDemandUseList.map((value) => value.jobId);
        var sendData = {
          jobIds: sendIds,
        };
        api
          .post("job/changepaymentplan", sendData)
          .then((response) => {
            if (response.succeeded === true) {
              handleClose();
              toast.success("Paket Kullanımınız Onaylandı!", {
                autoClose: 2000,

                onClose: () => {
                  setTimeout(() => {
                    if (candidateType == "intern") {
                      history.push("/demands/internAllSho");
                    } else if (candidateType == "blue") {
                      history.push("/matchShow");
                    } else {
                      history.push("/matchShow");
                    }
                  }, 100);
                },
              });
            } else {
              toast.success(response.data.error.message, {
                autoClose: 2000,

                onClose: () => {
                  setTimeout(() => {}, 100);
                },
              });
            }
          })
          .catch((err) => {});
      }
    }
  }

  return (
    <div>
      {demands === null && show == true ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingDotsAnimation />
        </Box>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
        >
          <>
            <div>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Modal
                show={show}
                onHide={handleClose}
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                }}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="full-screen-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  style={{
                    borderBottom: "0px",
                    marginTop: "20px",
                    marginBottom: "0px",
                  }}
                >
                  {demands === null || (demands && demands.length > 0) ? (
                    <ShowTopInfo packageInformation={packageInformation} />
                  ) : (
                    <div></div>
                  )}
                </Modal.Header>

                <Modal.Body
                  style={{}}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {demands === null || (demands && demands.length > 0) ? (
                    <div
                      style={{
                        marginTop: "0px",
                        paddingTop: "0px",

                        marginTop: "15px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignContent: "flex-start",
                        alignItems: "flex-start",
                        overflowY: "auto",
                        maxHeight: "60vh",
                      }}
                    >
                      {candidateType == "blue" ? (
                        <ShowBlueBody
                          packageInformation={packageInformation}
                          demands={demands}
                          selectDemandUseList={selectDemandUseList}
                          setSelectDemandUseList={setSelectDemandUseList}
                        />
                      ) : candidateType == "white" ? (
                        <ShowWhiteBody
                          packageInformation={packageInformation}
                          demands={demands}
                          selectDemandUseList={selectDemandUseList}
                          setSelectDemandUseList={setSelectDemandUseList}
                        />
                      ) : candidateType == "intern" ? (
                        <ShowInternBody
                          packageInformation={packageInformation}
                          demands={demands}
                          selectDemandUseList={selectDemandUseList}
                          setSelectDemandUseList={setSelectDemandUseList}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={demandNotFound}
                          style={{
                            height: "80px",
                            textAlign: "center",
                          }}
                        />
                        <Typography
                          sx={{
                            marginTop: "10px",

                            marginLeft: {
                              xs: "0px",
                              sm: "0px",
                              md: "0px",
                              lg: "0px",
                              xl: "0px",
                            },

                            fontSize: {
                              xs: "15px",
                              sm: "15px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },

                            fontWeight: "bold",
                            color: {
                              xs: "#001542",
                              sm: "#001542",
                              md: "#001542",
                              lg: "#001542",
                              xl: "#001542",
                            },
                          }}
                        >
                          Onaylanacak Talep Bulunamadı!
                        </Typography>
                        <Typography
                          sx={{
                            marginTop: "5px",

                            marginLeft: {
                              xs: "0px",
                              sm: "0px",
                              md: "0px",
                              lg: "0px",
                              xl: "0px",
                            },

                            fontSize: {
                              xs: "13px",
                              sm: "15px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            textAlign: "center",

                            fontWeight: "600px",
                            color: {
                              xs: "#001542",
                              sm: "#001542",
                              md: "#001542",
                              lg: "#001542",
                              xl: "#001542",
                            },
                          }}
                        >
                          Onay verip eşleşme detaylarını görüntüleyebileceğiniz
                          bir talep bulunmamaktadır.
                        </Typography>
                        <Box
                          sx={{
                            marginTop: "30px",
                            marginBottom: "0px",
                          }}
                        >
                          <CustomButton
                            onTap={(val) => {
                              if (candidateType == "intern") {
                                history.push("/findJobSeeker/internJob");
                              } else if (candidateType == "blue") {
                                history.push("/findJobSeeker/blueJobSeeker");
                              } else {
                                history.push("/findJobSeeker/whiteJobSeeker");
                              }
                            }}
                            height={50}
                            title={"Talep Oluştur"}
                            width={{
                              xs: "300px",
                              sm: "300px",
                              md: "300px",
                              lg: "300px",
                              xl: "300px",
                            }}
                          />
                        </Box>
                      </div>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer
                  style={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <div
                    style={{
                      position: "fixed",

                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      marginBottom: "50px",
                    }}
                  >
                    {demands === null ||
                    (demands && demands.length <= 0 && show === true) ? (
                      <div></div>
                    ) : (
                      <CustomButton
                        onTap={(val) => {
                          jobChangePaymentPlan();
                        }}
                        title={
                          packageInformation !== null &&
                          packageInformation.currentPlanId === 5
                            ? "Paket Satın Al"
                            : "Onayla"
                        }
                        width={{
                          xs: "400px",
                          sm: "400px",
                          md: "400px",
                          lg: "400px",
                          xl: "400px",
                        }}
                      />
                    )}{" "}
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

function ShowBlueBody({
  packageInformation,
  demands,
  selectDemandUseList,
  setSelectDemandUseList,
}) {
  const toggleSelection = (demand, index) => {
    console.log("packetInformation* *", packageInformation);
    if (
      packageInformation.currentPlanId === 5 &&
      packageInformation.creditLeft === 0
    ) {
      toast.error(
        "Talep seçimi yapabilmek için paket satın almanız gerekmektedir.",
        {
          autoClose: 2000,

          onClose: () => {
            setTimeout(() => {}, 100);
          },
        }
      );
    } else {
      if (anyMethod({ index })) {
        setSelectDemandUseList((prev) =>
          prev.filter((item) => item.jobId !== demand.jobId)
        );
      } else {
        if (selectDemandUseList.length < packageInformation.creditLeft) {
          setSelectDemandUseList((prev) => [...prev, demands[index]]);
        } else {
          toast.error(
            "Seçtiğiniz talep sayısı, mevcut paket haklarınızdan fazla. Daha fazla talep almak için lütfen yeni bir paket satın alınız.",
            {
              autoClose: 6000,

              onClose: () => {
                setTimeout(() => {}, 100);
              },
            }
          );
        }
      }
    }
  };

  const anyMethod = ({ index }) => {
    return (
      selectDemandUseList &&
      selectDemandUseList.some(
        (element) => element.jobId === demands[index].jobId
      )
    );
  };
  const findIndexByJobId = (jobId, demands) => {
    return demands.findIndex((item) => item.jobId === jobId);
  };
  return (
    <div
      style={{
        marginBottom: "70px",
        marginTop: "0px",
      }}
    >
      {demands &&
        demands.map((demand, index) => {
          return (
            <BlueDemandPackageUseItem
              positionName={demand.positionName}
              demand={demand}
              selectedItems={selectDemandUseList}
              onTap={() => {
                toggleSelection(demand, index);
              }}
            />
          );
        })}
    </div>
  );
}
function ShowInternBody({
  packageInformation,
  demands,
  selectDemandUseList,
  setSelectDemandUseList,
}) {
  const toggleSelection = (demand, index) => {
    console.log("packetInformation* *", packageInformation);
    if (
      packageInformation.currentPlanId === 5 &&
      packageInformation.creditLeft === 0
    ) {
      toast.error(
        "Talep seçimi yapabilmek için paket satın almanız gerekmektedir.",
        {
          autoClose: 2000,

          onClose: () => {
            setTimeout(() => {}, 100);
          },
        }
      );
    } else {
      if (anyMethod({ index })) {
        setSelectDemandUseList((prev) =>
          prev.filter((item) => item.jobId !== demand.jobId)
        );
      } else {
        if (selectDemandUseList.length < packageInformation.creditLeft) {
          setSelectDemandUseList((prev) => [...prev, demands[index]]);
        } else {
          toast.error(
            "Seçtiğiniz talep sayısı, mevcut paket haklarınızdan fazla. Daha fazla talep almak için lütfen yeni bir paket satın alınız.",
            {
              autoClose: 6000,

              onClose: () => {
                setTimeout(() => {}, 100);
              },
            }
          );
        }
      }
    }
  };

  const anyMethod = ({ index }) => {
    return (
      selectDemandUseList &&
      selectDemandUseList.some(
        (element) => element.jobId === demands[index].jobId
      )
    );
  };
  const findIndexByJobId = (jobId, demands) => {
    return demands.findIndex((item) => item.jobId === jobId);
  };
  return (
    <div
      style={{
        marginBottom: "70px",
        marginTop: "0px",
        paddingTop: "0px",
      }}
    >
      {demands &&
        demands.map((demand, index) => {
          return (
            <BlueDemandPackageUseItem
              positionName={demand.departmentName}
              demand={demand}
              selectedItems={selectDemandUseList}
              onTap={() => {
                toggleSelection(demand, index);
              }}
            />
          );
        })}
    </div>
  );
}
function ShowWhiteBody({
  packageInformation,
  demands,
  selectDemandUseList,
  setSelectDemandUseList,
}) {
  const toggleSelection = (demand, index) => {
    console.log("packetInformation* *", packageInformation);
    if (
      packageInformation.currentPlanId === 5 &&
      packageInformation.creditLeft === 0
    ) {
      toast.error(
        "Talep seçimi yapabilmek için paket satın almanız gerekmektedir.",
        {
          autoClose: 2000,

          onClose: () => {
            setTimeout(() => {}, 100);
          },
        }
      );
    } else {
      if (anyMethod({ index })) {
        setSelectDemandUseList((prev) =>
          prev.filter((item) => item.jobId !== demand.jobId)
        );
      } else {
        if (selectDemandUseList.length < packageInformation.creditLeft) {
          setSelectDemandUseList((prev) => [...prev, demands[index]]);
        } else {
          toast.error(
            "Seçtiğiniz talep sayısı, mevcut paket haklarınızdan fazla. Daha fazla talep almak için lütfen yeni bir paket satın alınız.",
            {
              autoClose: 6000,

              onClose: () => {
                setTimeout(() => {}, 100);
              },
            }
          );
        }
      }
    }
  };

  const anyMethod = ({ index }) => {
    return (
      selectDemandUseList &&
      selectDemandUseList.some(
        (element) => element.jobId === demands[index].jobId
      )
    );
  };
  const findIndexByJobId = (jobId, demands) => {
    return demands.findIndex((item) => item.jobId === jobId);
  };
  return (
    <div
      style={{
        marginBottom: "70px",
        marginTop: "0px",
      }}
    >
      {demands &&
        demands.map((demand, index) => {
          return (
            <WhiteDemandPackageUseItem
              positionName={demand.positionName}
              demand={demand}
              selectedItems={selectDemandUseList}
              onTap={() => {
                toggleSelection(demand, index);
              }}
            />
          );
        })}
    </div>
  );
}

function ShowTopInfo({ packageInformation }) {
  return (
    <div>
      {packageInformation && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "flex-start",
            alignItems: "flex-start",
            backgroundColor: "#f5f5f5",
            padding: "5px",
            borderRadius: "8px",
            marginBottom: "0px",
            marginTop: "0px",
            width: "100%",
            position: "relative",
          }}
        >
          {packageInformation && (
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                  backgroundColor: "#f5f5f5",
                  padding: "5px 10px 5px 10px",
                  borderRadius: "8px",
                  marginBottom: "0px",
                  marginTop: "0px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "0px",
                    width: "100%",
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "12px",
                        lg: "14px",
                        xl: "14px",
                      },
                      width: "98%",
                      fontWeight: "600px",
                      color: "#001542",

                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    {packageInformation !== null &&
                    packageInformation.currentPlanId === 5
                      ? "Talebinizle eşleşen adayların detaylı bilgilerini görmek için paket satın almanız gerekmektedir. Böylece, eşleşen adayların tüm detaylı bilgilerine erişebilir ve görüşme oluşturabilirsiniz"
                      : "Kullanmak istediğiniz talepleri işaretleyip onaylayın. Onay sonrası, eşleşen adayların detaylı bilgilerini görüntüleyebilir, Video CV talep edebilir, Video Konferans düzenleyebilir ve İşyerinde Görüşmeye çağırabilirsiniz."}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              position: "absolute",
              right: {
                xs: "10px",
                sm: "10px",
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
              top: "5px",
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                fontWeight: "600px",
              }}
            >
              📌
            </Typography>{" "}
          </Box>
        </Box>
      )}
    </div>
  );
}

export default PackageCandidateDemandUsageScreen;
