//*** Date:07-05-2024/ Code Writer Developer HÜSEYİN TELLİ ***
import React, { useEffect, useRef, useState } from "react";
import {
  TextField,
  Autocomplete,
  Checkbox,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import "./BitonisDropdownStyle.css";
import { customStylesMulti } from "../customStyleInline";
import "./CustomDesign.css";
import useIsMobile from "../hooks/useIsMobile";
const BitonisMultiDropdown = ({
  items,
  onValueChanged,
  errors,
  errorMessage,
  register,
  companyClassName,
  control,
  watch,
  selectedId,
  setValue,
  required,
  placeholder,
  getValues,
  interviewContactValue,
  interviewContactBoolean,
  setInterviewContactValue,
  infoText,
  requiredSelectedItemsBoolean,
  requiredSelectedItemsValues,
  setRequiredSelectedItemsValues,
  requiredSelectedItemsText,
  showRightBox,
  onRightBoxTap,
}) => {
  const isMobile = useIsMobile();
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  // const filterOptions = (options: any[], { inputValue }: any) => {
  //     return options.filter((option) =>
  //         option.name.toLowerCase().includes(inputValue.toLowerCase())
  //     );
  // };

  function onSelectChanged(value) {
    onValueChanged(value);
  }
  var initialValue = watch(companyClassName);

  useEffect(() => {
    // if (watch(companyClassName)) {
    //     const selectedItemsArray = (watch(companyClassName) as any[]).map((item) => items.find((i) => i.id === item.id));
    //     const list = selectedItemsArray.filter(item => Boolean(item));
    //     setSelected(list as any[]);
    // }
  }, [companyClassName, items, watch]);

  var initialValueGet = getValues(companyClassName);

  useEffect(() => {
    if (selectedId !== undefined || selectedId !== null) {
      /*   const { unsubscribe } = watch((value, { name }) => {
        const selectedValues = value[companyClassName];
        if (name == companyClassName && (selectedValues ?? []).length) {
          setDisabled(selectedValues.some((x) => x.value === selectedId));
        } else {
          setDisabled(false);
        }
      });
      return () => {
        unsubscribe();
      };
      */
      const selectedValues = watch(companyClassName);
      if ((selectedValues ?? []).length) {
        setDisabled(selectedValues.some((x) => x.value === selectedId));
      } else {
        setDisabled(false);
      }
    }
  }, [
    selectedId,
    initialValueGet,
    companyClassName,
    interviewContactBoolean,
    interviewContactValue,
  ]);

  useEffect(() => {
    if (
      selectedId ||
      selectedId === 0 ||
      selectedId !== 0 ||
      selectedId !== undefined ||
      selectedId !== null
    ) {
      var values = getValues(companyClassName);
      const selectedValues = values;
      if (selectedValues && selectedValues.length) {
        setDisabled(selectedValues.some((x) => x.value === selectedId));
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(false);
    }
  }, []);

  //-----------------------------------------------
  function changeSelected(_, e) {
    let newValue = [];
    if (selectedId === undefined || selectedId === null || selectedId === "") {
      return (newValue = e);
    } else {
      if (e.length) {
        const selectedValues = e.map((option) => option.value);
        if (selectedValues.includes(selectedId)) {
          const selectedOption = e.find(
            (option) => option.value === selectedId
          );
          if (selectedOption) {
            setDisabled(true);
            newValue = [selectedOption];
            return newValue;
          } else {
            newValue = [];
          }
        } else {
          newValue = e;
        }
      } else {
        newValue = e;
      }

      setDisabled(false);
      return newValue;
    }
  }

  useEffect(() => {
    if (
      interviewContactBoolean &&
      interviewContactBoolean === true &&
      interviewContactValue &&
      interviewContactValue === true
    ) {
      setValue(companyClassName, "");
      setDisabled(true);
    } else {
      if (interviewContactValue && interviewContactValue === false) {
        setDisabled(false);
      } else {
      }
    }
  }, [interviewContactBoolean, interviewContactValue, initialValue]);

  return (
    <div>
      <div
        style={{
          borderRadius: "5px",
          marginBottom: "5px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {items && (
          <Controller
            name={companyClassName}
            control={control}
            defaultValue={[]}
            rules={
              interviewContactBoolean &&
                interviewContactBoolean === true &&
                interviewContactValue &&
                interviewContactValue === true
                ? {
                  required: Boolean(false),
                }
                : {
                  required: Boolean(required),
                }
            }
            render={({ field }) => {
              return (
                <Autocomplete
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                  multiple
                  isOptionEqualToValue={(a, b) => a.value === b.value}
                  options={items}
                  onChange={(event, value) => {
                    const newValue = changeSelected(event, value);
                    field.onChange(newValue);
                    onValueChanged(newValue);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },

                    "& .MuiInputBase-root": {
                      overflowX: "hidden",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      flexWrap: "nowrap",
                      cursor: "pointer",
                    },
                    "&& .MuiAutocomplete-input": {
                      minWidth: 0,
                      width: 0,
                      overflowX: "scroll",
                    },
                    display: "flex",
                    alignItems: "center",
                    width:
                      showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "100%"
                        : "90%",
                    backgroundColor: "#fcfcfc",
                    borderRadius:
                      showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "6px"
                        : "6px 0px 0px 6px",

                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    border: "1px solid #f3f3f6",
                    marginRight:
                      showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "0px"
                        : "0px",
                    border: "1px solid #dcdcdd",
                  }}
                  value={field.value || []}
                  getOptionLabel={(option) => option && option.label}
                  disableCloseOnSelect={true}
                  filterSelectedOptions={false}
                  noOptionsText="Bulunamadı"
                  renderTags={(value) => {
                    return (
                      <Typography
                        className="displayValue"
                        variant="body2"
                        classes={customStylesMulti}
                        onClick={() => {
                          if (open === true) {
                            setOpen(false);
                          } else {
                            setOpen(true);
                          }
                        }}
                        sx={{
                          whiteSpace: "pre",
                          maxWidth: "100%",
                          textOverflow: "",
                          overflowX: "hidden",
                          color: "#001542",
                          overflowX: "scroll",
                          "&::-webkit-scrollbar": {
                            display: "none",
                          },
                          width: "100%",
                          fontWeight: 410,
                          fontSize: isMobile ? "12px" : "14px",
                        }}
                      >
                        {field.value
                          .reduce((acc, val) => {
                            return `${acc}, ${val.label}`;
                          }, "")
                          .slice(2)}
                      </Typography>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      onClick={() => {
                        if (open === true) {
                          setOpen(false);
                        } else {
                          setOpen(true);
                        }
                      }}
                      {...params}
                      variant="outlined"
                      placeholder={
                        field.value.length === 0 &&
                          interviewContactValue &&
                          interviewContactBoolean
                          ? "İletişime geçilecektir"
                          : field.value.length === 0
                            ? placeholder
                            : ""
                      }
                      className="custom-placeholder-text"
                      sx={
                        ({
                          "& .MuiAutocomplete-tag": {
                            color: "#001542",
                            borderRadius: "5px",
                            fontWeight: 400,
                            whiteSpace: "nowrap",
                            display: "inline-flex",
                          },
                        },
                        {
                          input: {
                            cursor: "pointer",
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#001542",
                            "::placeholder": {
                              fontSize: isMobile ? "11px" : "13px",
                              fontWeight: 500,
                              color:
                                interviewContactBoolean &&
                                  interviewContactBoolean === true &&
                                  interviewContactValue &&
                                  interviewContactValue === true
                                  ? "#001542"
                                  : "#001542",
                            },
                          },
                        })
                      }
                    />
                  )}
                  renderOption={(props, option, e) => {
                    var disable = disabled;
                    if (selectedId === undefined || selectedId === null) {
                      disable = false;
                    } else {
                      if (
                        interviewContactValue &&
                        interviewContactValue === true &&
                        interviewContactBoolean &&
                        interviewContactBoolean === true
                      ) {
                        disable = true;
                      } else {
                        disable =
                          disable && option && option.value !== selectedId;
                      }
                    }

                    return (
                      <>
                        {" "}
                        {e && e.index === 0 && requiredSelectedItemsBoolean && (
                          /* showInfo Bunu sen tanımla veya dışarıdan al, lazım değilse kaldır */
                          <li
                            style={{
                              border: "1.2px dotted orange",
                              padding: "0px",
                              borderRadius: "8px",
                              margin: "0px 15px 0px 15px",
                              cursor: "pointer",
                              backgroundColor: "#EEF7FF",
                            }}
                            onClick={() => {
                              setRequiredSelectedItemsValues(
                                !requiredSelectedItemsValues
                              );
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignContent: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                padding: "8px",
                              }}
                            >
                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Checkbox
                                  icon={icon}
                                  style={{
                                    marginRight: 0,
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                    color: "orange",
                                  }}
                                  onChange={(value) => {
                                    setRequiredSelectedItemsValues(
                                      value.target.checked
                                    );
                                  }}
                                  checked={requiredSelectedItemsValues}
                                />
                              </div>
                              <p
                                style={{
                                  textAlign: "start",
                                  color: "#001542",
                                  fontWeight: "600px",
                                  fontSize: isMobile ? "10px" : "12px",
                                  padding: "0px",
                                  margin: "0px",
                                }}
                              >
                                {requiredSelectedItemsText}
                              </p>
                            </div>
                          </li>
                        )}
                        {e && e.index === 0 && interviewContactBoolean && (
                          /* showInfo Bunu sen tanımla veya dışarıdan al, lazım değilse kaldır */
                          <li
                            style={{
                              border: "1.2px dotted orange",
                              padding: "0px",
                              borderRadius: "8px",
                              margin: "0px 15px 0px 15px",
                              cursor: "pointer",
                              backgroundColor: "#EEF7FF",
                            }}
                            onClick={() => {
                              setInterviewContactValue(!interviewContactValue);
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignContent: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                padding: "8px",
                              }}
                            >
                              <div
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Checkbox
                                  icon={icon}
                                  style={{
                                    marginRight: 0,
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                    color: "orange",
                                  }}
                                  onChange={(value) => {
                                    setInterviewContactValue(
                                      value.target.checked
                                    );
                                  }}
                                  checked={interviewContactValue}
                                />
                              </div>
                              <p
                                style={{
                                  textAlign: "start",
                                  color: "#001542",
                                  fontWeight: "600px",
                                  fontSize: isMobile ? "10px" : "12px",
                                  padding: "0px",
                                  margin: "0px",
                                }}
                              >
                                Talebim ile eşleşen adaylarla itetişime
                                geçtikten sonra, benimle görüşmeye gelsinler
                              </p>
                            </div>
                          </li>
                        )}
                        {e &&
                          e.index === 0 &&
                          infoText &&
                          interviewContactValue === false && (
                            <li
                              style={{
                                pointerEvents: "none",
                                padding: "0px",
                                borderRadius: "8px",
                                margin: "0px 0px 0px 20px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignContent: "center",
                                  alignItems: "center",
                                  justifyItems: "center",
                                  margin: "5px 0px 0px 5px",
                                }}
                              >
                                <div
                                  style={{
                                    width: "17px",
                                    height: "17px",
                                    backgroundColor: "orange",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                    borderRadius: "2px",
                                  }}
                                >
                                  <i
                                    class="fa-solid fa-exclamation"
                                    style={{
                                      color: "white",
                                      fontSize: "12px",
                                    }}
                                  ></i>
                                </div>
                                <p
                                  style={{
                                    color: "001542",
                                    fontWeight: "600px",
                                    fontSize: isMobile ? "10px" : "12px",
                                    padding: "10px",
                                    margin: "0px",
                                  }}
                                >
                                  {infoText}
                                </p>
                              </div>
                            </li>
                          )}
                        <li
                          {...props}
                          style={{
                            opacity: disable ? 0.4 : 1,
                            pointerEvents: disable ? "none" : "all",
                            fontSize: isMobile ? "11px" : "13px",
                            fontWeight: 405,
                            padding: "0px 15px 5px 15px",
                            color: "#001542",
                          }}
                        >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={e.selected}
                          />
                          {option.label}
                        </li>
                      </>
                    );
                  }}
                  loading
                />
              );
            }}
          />
        )}

        {showRightBox &&items &&  (
          <Box
            onClick={
              onRightBoxTap === undefined || onRightBoxTap === null
                ? () => { }
                : onRightBoxTap
            }
            sx={{
              width: {
                xs: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "10%",
                sm: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "10%",
                md: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "8%",
                lg: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "8%",
                xl: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "8%",

              },

              backgroundColor: "#fdc303",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginLeft: "0px",
              borderRadius: "0px 4px 4px 0px",
              cursor: "pointer",
            }}
          >
            <i
              class="fa-solid fa-exclamation"
              style={{
                fontSize: "20px",
                color: "white",
              }}
            ></i>
          </Box>
        )}
      </div>
      {interviewContactBoolean &&
        interviewContactBoolean === true &&
        interviewContactValue &&
        interviewContactValue === true ? (
        <div></div>
      ) : (
        required &&
        errors[companyClassName] && (
          <div className="text-danger fs-12 mb-2">{errorMessage}</div>
        )
      )}
    </div>
  );
};

export default BitonisMultiDropdown;
