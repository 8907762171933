import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { AccountServices } from "../../../../../../services/AccountServices";
import CustomDesign from "../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

const ChangePasswordPage = () => {
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword2, setShowPassword2] = useState(true);
  const [showPassword3, setShowPassword3] = useState(true);
  const [passwordValidations, setPasswordValidations] = useState([
    { rule: "En az 9 En fazla 20 karakter", isValid: false },
    { rule: "1 rakam", isValid: false },
    { rule: "1 büyük harf", isValid: false },
    { rule: "1 küçük harf", isValid: false },
  ]);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isStrongPasswordVisible, setIsStrongPasswordVisible] = useState(true);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  const checkPasswordStrength = () => {
    return passwordValidations.every((item) => item.isValid);
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm();

  const validatePassword = (password) => {
    const validations = [
      {
        rule: "En az 9 En fazla 20 karakter",
        isValid: password.length > 8 && password.length <= 20,
      },
      { rule: "1 rakam", isValid: /\d/.test(password) },
      { rule: "1 büyük harf", isValid: /[A-Z]/.test(password) },
      { rule: "1 küçük harf", isValid: /[a-z]/.test(password) },
    ];

    setPasswordValidations(validations);

    // Bütün kurallar geçerliyse güçlü şifre kontrolünü kapat
    const allValid = validations.every((validation) => validation.isValid);
    setIsStrongPasswordVisible(!allValid);

    // Bütün kurallar sağlanıyorsa ve şifre odaklı değilse güçlü şifre kontrolünü gösterme
    if (allValid && !isPasswordFocused) {
      setIsStrongPasswordVisible(false);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e; //e.target.value;
    const validations = validatePassword(newPassword);
    // Bu validasyonları state'e veya context'e atarak kullanabilirsiniz.
    console.log(validations);
  };

  const handlePasswordFocus = (isFocused) => {
    setIsPasswordFocused(isFocused);
  };
  var pass = watch("passwordCtx2");

  useEffect(() => {
    if (pass) {
      handlePasswordChange(pass);
    }
  }, [pass]);

  const onSubmit = async (form) => {
    if (form.passwordCtx2 === form.passwordCtx3) {
      if (checkPasswordStrength() == true) {
        var sendData = {
          currentPassword: form.passwordCtx1.toString(),
          password: form.passwordCtx2.toString(),
          confirmPassword: form.passwordCtx3.toString(),
        };

        try {
          const getResponse = await AccountServices.changePasswordProfile(
            sendData
          );

          if (getResponse && getResponse.succeeded === true) {
            toast.info(getResponse.result.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  window.location.href = window.location.href;
                }, 100);
              },
            });
          } else {
            toast.info(getResponse && getResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => { }, 100);
              },
            });
          }
        } catch (error) {
          toast.info("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => { }, 100);
            },
          });
        }
      } else {
        const allValid = passwordValidations.every(
          (validation) => validation.isValid
        );
        if (allValid == false) {
          setIsStrongPasswordVisible(true);
          setIsPasswordFocused(true);
        }
        // Bütün kurallar sağlanıyorsa ve şifre odaklı değilse güçlü şifre kontrolünü gösterme
        if (allValid && !isPasswordFocused) {
          setIsStrongPasswordVisible(false);
          setIsPasswordFocused(false);
        }
      }
    } else {
      toast.info("Şifreler Aynı Değil", {
        autoClose: 2000,
        onClose: () => {
          setTimeout(() => { }, 100);
        },
      });
    }
  };

  // useEffect ile bütün kurallar sağlandığında güçlü şifre kontrolünü kapat
  useEffect(() => {
    const allValid = passwordValidations.every(
      (validation) => validation.isValid
    );
    if (allValid) {
      setIsStrongPasswordVisible(false);
    }
  }, [passwordValidations]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div id="settingBody">
        <div
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            textAlign: "center"
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "600",
              fontSize:"20px",
            }}
          >
            Şifre Değiştir
          </p>
        </div>
        <div className="card">
        
          <div
            className="card-header"
            style={{
              borderBottom: "0px",
            }}
          ></div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>


              <CustomDesign
                label={"Mevcut Şifreniz"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        key={"password1"}
                        style={{
                          border: "1px solid rgb(203, 200, 200)",
                          padding: "10px",
                          marginLeft: "0px",
                          backgroundColor: "#f8f8f9",
                          borderRadius: "6px",
                          width: "100%",
                          color: "#001542",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        name="passwordCtx1"
                        {...register("passwordCtx1", { required: true })}
                        placeholder="Şifre"
                        onFocus={() => setIsStrongPasswordVisible(true)}
                        onBlur={() => setIsStrongPasswordVisible(false)}
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="far fa-eye"></i>
                        ) : (
                          <i className="far fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                    {errors.passwordCtx1 && (
                      <div className="text-danger fs-12">Şifre Boş Bırakılamaz</div>
                    )}
                  </div>
                }
              />
              <CustomDesign
                label={"Yeni Şifre"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword2 ? "text" : "password"}
                        className="form-control"
                        key={"passwordCtx2"}
                        style={{
                          border: "1px solid rgb(203, 200, 200)",
                          padding: "10px",
                          marginLeft: "0px",
                          backgroundColor: "#f8f8f9",
                          borderRadius: "6px",
                          width: "100%",
                          color: "#001542",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        name="passwordCtx2"
                        {...register("passwordCtx2", {
                          required: "Yeni Şifre Boş Bırakılamaz..",
                        })}
                        placeholder="Yeni Şifre"
                        onFocus={() => handlePasswordFocus(true)}
                        onBlur={() => handlePasswordFocus(false)}
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility2}
                      >
                        {showPassword3 ? (
                          <i className="far fa-eye"></i>
                        ) : (
                          <i className="far fa-eye-slash"></i>
                        )}
                      </span>
                    </div>

                    {errors.passwordCtx2 && (
                      <div className="text-danger fs-12">
                        {errors.passwordCtx2.message}
                      </div>
                    )}

                    {isPasswordFocused && isStrongPasswordVisible && (
                      <div
                        style={{
                          border: "0.5px solid #F7F7F7",
                          margin: "5px",
                          borderRadius: "8px",
                        }}
                      >
                        {passwordValidations.map((validation, index) => (
                          <div
                            key={index}
                            style={{
                              fontSize: "11px",
                              padding: "2px",
                              margin: "5px",
                              fontWeight: "500",
                            }}
                            className={`text-${validation.isValid ? "success" : "danger"
                              } fs-12`}
                          >
                            {validation.isValid ? (
                              <i
                                class="fa-solid fa-check"
                                style={{
                                  margin: "5px",
                                }}
                              ></i>
                            ) : (
                              <i
                                class="fa-solid fa-x"
                                style={{
                                  margin: "5px",
                                }}
                              ></i>
                            )}
                            {validation.rule}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                }
              />
              <CustomDesign
                label={"Yeni Şifre Tekrar"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <div>
                    <div style={{ position: "relative" }}>
                      <input
                        type={showPassword3 ? "text" : "password"}
                        className="form-control"
                        key={"password3"}
                        style={{
                          border: "1px solid rgb(203, 200, 200)",
                          padding: "10px",
                          marginLeft: "0px",
                          backgroundColor: "#f8f8f9",
                          borderRadius: "6px",
                          width: "100%",
                          color: "#001542",
                          fontWeight: "500",
                          fontSize: "15px",
                        }}
                        name="passwordCtx3"
                        {...register("passwordCtx3", { required: true })}
                        placeholder="Yeni Şifre Tekrar"
                        onFocus={() => handlePasswordFocus(true)}
                        onBlur={() => handlePasswordFocus(false)}
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={togglePasswordVisibility3}
                      >
                        {showPassword3 ? (
                          <i className="far fa-eye"></i>
                        ) : (
                          <i className="far fa-eye-slash"></i>
                        )}
                      </span>
                    </div>
                    {errors.passwordCtx3 && (
                      <div className="text-danger fs-12">Şifre Boş Bırakılamaz</div>
                    )}
                  </div>
                }
              />


              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop:"15px"
                }}
              >
                {" "}
                <CustomButton title={"Kaydet"} width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordPage;
