import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../../../../../utility/Utils";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import { Checkbox, Menu, MenuItem } from "@mui/material";

function DetailEditDocumentEmployer({
  show,
  handleClose,
  type,
  addedDocument,
}) {
  const [getEmployerProfileDocument, setGetEmployerProfileDocument] =
    useState(null);
  function getEmployerDocument() {
    setGetEmployerProfileDocument(null);
    api
      .get("common/hiringDocuments")
      .then((response) => {
        if (response.succeeded == true) {
          setGetEmployerProfileDocument([]);
          response.data.map((value) => {
            setGetEmployerProfileDocument((prev) => [...prev, value]);
          });
        } else {
          handleClose();
        }
      })
      .catch((err) => {
        handleClose();
      });
  }

  useEffect(() => {
    if (show) {
      getEmployerDocument();
      setSelectedItems([]);
      if (addedDocument == null || addedDocument == false) {
      } else {
        if (addedDocument.length > 0) {
          addedDocument.map((value) => {
            var val = {
              code: value.hiringDocument.code,
              id: value.hiringDocument.id,
              name: value.hiringDocument.name,
            };
            console.log("val " + JSON.stringify(val));
            setSelectedItems((prev) => [...prev, val]);
          });
        }
      }
    }
  }, [show]);

  const [selectedItems, setSelectedItems] = useState([]);

  const toggleSelection = (item) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      setSelectedItems((prev) =>
        prev.filter((selectedItem) => selectedItem.id !== item.id)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };
  function saveDocument() {
    var _selectedIds = [];
    selectedItems.map((value) =>
      _selectedIds.push(parseInt(value.id.toString()))
    );
    var sendData = {
      hiringDocumentId: _selectedIds,
      positionType: type,
      candidateId: null,
    };
    api
      .put("employer/hirechecklist", sendData)
      .then((response) => {
        handleClose(true);

        if (response.succeeded == true) {
          toast.success(response.result.message, {
            autoClose: 2000,

            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(response.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      })
      .catch((err) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      });
  }
  return (
    <div>
      {getEmployerProfileDocument === null && show == true ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingDotsAnimation />
        </Box>
      ) : (
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            }}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="full-screen-modal"
          >
            <Modal.Header
              closeButton
              style={{
                borderBottom: "none",
                marginBottom: "0px",
                paddingBottom: "0px",
              }}
            >
              <Modal.Title
                aria-labelledby="contained-modal-title-vcenter"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                <PageInfoTitle
                  title={
                    addedDocument == null || addedDocument.length <= 0
                      ? "Evrak Ekle"
                      : "Evrak Güncelle"
                  }
                />
              </Modal.Title>
            </Modal.Header>

            <Modal.Body
              className="grid-example"
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                marginTop: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                overflowY: "auto",
                maxHeight: "65vh",
              }}
            >
              <div>
                {getEmployerProfileDocument &&
                  getEmployerProfileDocument.map((item, index) => (
                    <div key={index} style={{

                      padding: "10px",
                      color: "#001542",
                      border: "none",
                      margin: "0px 0px 5px 0px",
                      borderRadius: "8px",
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                      
                      backgroundColor: selectedItems &&
                        selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        ) ? "#F5F7F8" : "rgb(242, 243, 244)",
                     
                      border: selectedItems && selectedItems.some(
                        (selectedItem) => selectedItem.id === item.id
                      ) ? "1px solid #D6E5FA" : "1px solid white",
                      cursor: "pointer"
                    }} onClick={() => toggleSelection(item)}>


                      <Checkbox
                        sx={{
                          ml: '0px',
                          '&.Mui-checked': {
                            color: '#001542',
                          },
                          '& .MuiSvgIcon-root': {
                            borderRadius: '4px',
                          },
                        }}
                        checked={selectedItems.some(
                          (selectedItem) => selectedItem.id === item.id
                        )}
                        onChange={() => toggleSelection(item)}
                      />

                      <span
                        style={{
                          fontSize: 13,
                          fontWeight: 600,
                          color: "#001542;",
                          paddingLeft: "20px",
                        }}
                      >
                        {item.name}
                      </span>

                    </div>
                  ))}
              </div>
            </Modal.Body>
            <Modal.Footer
              style={{
                border: "none",

                display: "flex",
                flexDirection: "Row",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
              }}
            >
              <CustomButton
                onTap={(val) => {
                  saveDocument();
                }}
                title={"Kaydet"}
                width={{
                  xs: "400px",
                  sm: "350px",
                  md: "350px",
                  lg: "350px",
                  xl: "350px",
                }}
              />
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default DetailEditDocumentEmployer;
