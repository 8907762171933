import React from 'react'
import demandNotFound from "../../../../../../../../images/svg/demandNotFound.svg";
import { Box } from '@mui/material';
import CustomButton from '../../../../../../../widgets/buttons/CustomButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const DemandNotFound = ({ candidateType }) => {
  const history = useHistory();
  return (
    <div>
      <div>
        <div
          style={{
            marginTop: "12%",
          }}
        >
          <div
            class=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <img
              src={demandNotFound}
              style={{
                height: "80px",
                textAlign: "center",
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p
                style={{
                  color: "#001542",
                  marginTop: "10px",
                  marginBottom: "0px",
                  fontSize: "16px",
                  fontWeight: 600,
                  marginBottom: "0px",
                }}
              >
                Talep Bulunamadı
              </p>
            </div>
            {

              <Box sx={{
                marginTop: "10px",
                marginBottom: "0px"
              }}>
                <CustomButton
                  onTap={(val) => {
                    if (candidateType == "blue") {
                      history.push("/findJobSeeker/blueJobSeeker")
                    } else if (candidateType == "white") {
                      history.push("/findJobSeeker/whiteJobSeeker")
                    } else {
                      history.push("/findJobSeeker/internJob")
                    }


                  }}
                  height={50}
                  title={
                    "Talep Oluştur"
                  }
                  width={{
                    xs: "300px",
                    sm: "300px",
                    md: "300px",
                    lg: "300px",
                    xl: "300px",
                  }}
                />
              </Box>

            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemandNotFound;