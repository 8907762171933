import React, { useEffect, useState } from "react";
import api from "../../../../../../../utility/Utils";
import { connect, useDispatch } from "react-redux";
import CompanyServisRoutePageEdit from "./CompanyServisRoutePageEdit";
import "../../../../../../../css/bitoniscss/companyWidthAddedRoutes.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import CustomPreview from "../../../../../../widgets/CustomPreview";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import EditIconWidget from "../../../../../../widgets/EditIconWidget";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton";
import { Box } from "@mui/material";
function CompanyServiceRoutePage(props) {
  const isMobile = useIsMobile();
  let history = useHistory();
  //Eklenmiş Güzergahlar
  const [addedRoutes, setAddedRoutes] = useState([]);
  const dataMap = props.location.state.dataMap;
  var companyId;
  var companyName;
  if (dataMap) {
    const companyInfo = dataMap.get("companyInfo");
    if (companyInfo) {
      companyId = companyInfo.companyId;
      companyName = companyInfo.companyName;
    }
  }
  useEffect(() => {
    if (companyId) {
      getCompanyRoutes(companyId);
    }
  }, [companyId]);

  async function getCompanyRoutes(id) {
    setAddedRoutes([]);
    api
      .get("company/preferredlocationlist/" + id)
      .then((response) => {
        if (response.succeeded == true) {
          response.data.map((value) => {
            setAddedRoutes((prev) => [...prev, value]);
          });
        } else {
          setAddedRoutes([]);
        }
      })
      .catch((err) => {
        setAddedRoutes([]);
      });
  }
  const [showAdd, setShowAdd] = useState(false);
  const handleCloseAdd = () => {
    setShowAdd(false);
  };
  const [companyPreferredLocationId, setCompanyPreferredLocationId] =
    useState(null);
  const [preferredLocationLineId, setPreferredLocationLineId] = useState(null);
  const [routesName, setRoutesName] = useState("");
  if (addedRoutes.length <= 0) {
    return <div >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <LoadingDotsAnimation fontSize={40} />
      </div>
    </div>
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="card"
        style={{
          transition: "none",
        }}
      >   <PrevIconButton top={10} left={0} />
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center"
        }}>
          <Box
            className=""
            sx={{
              borderBottom: "0px",
              color: "#001542",
              fontWeight: 600,
              textAlign: "center",
              margin: "20px",

              borderRadius: "8px",
              marginTop: {
                xs: "20px",
                sm: "20px",
                md: "20px",
                lg: "20px",
                xl: "20px",
              },
              marginLeft: {
                xs: "30px",
                sm: "50px",
                md: "20px",
                lg: "0px",
                xl: "0px",
              }

            }}
          >
            {companyName && companyName}
          </Box>
        </div>
        <form>
          <div
            className="row"
            style={{
              margin: isMobile ? "20px" : "40px",
              marginTop: "0px",
            }}
          >
            {addedRoutes &&
              addedRoutes.map((routes) => {
                return (
                  <CustomPreview
                    name={""}
                    value={
                      <div
                        style={{
                          padding: "5px",
                        }}
                      >
                        {routes.name}
                      </div>
                    }
                    isOnColumn={true}
                    isOnColumnClassName={
                      addedRoutes && addedRoutes.length == 1
                        ? "col-xl-12 col-sm-12"
                        : "col-xl-6 col-sm-6"
                    }
                    trailing={
                      <EditIconWidget
                        size={17}
                        onTap={() => {
                          if (routes.companyPreferredLocationId) {
                            setCompanyPreferredLocationId(
                              routes.companyPreferredLocationId
                            );
                            setPreferredLocationLineId(
                              routes.preferredLocationLineId
                            );
                            setRoutesName(routes.name);
                            setShowAdd(true);
                          }
                        }}
                        showContainerEdit={false}
                        showEditText={false}
                      />

                    }
                  />
                );
              })}
          </div>
        </form>
        <CompanyServisRoutePageEdit
          show={showAdd}
          addedRoutes={addedRoutes}
          handleClose={handleCloseAdd}
          props={props}
          companyPreferredLocationId={companyPreferredLocationId}
          preferredLocationLineId={preferredLocationLineId}
          name={routesName}
          companyId={companyId}
        />
      </div>
    </div>
  );
}

function mapState(state) {
  let authentication = state.authentication;
  let companyRoutes = state.companyAddedGetRoutes.companyRoutes;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  return {
    authentication,
    companyRoutes,
    countryList,
    cityList,
    countyList,
    districtList,
  };
}

export default connect(mapState)(CompanyServiceRoutePage);
