import React from "react";
import { CircularProgress, Box, Tooltip } from "@mui/material";

const ProfileMatchProgress = ({ img, progress }) => {
  // Progress değeri %0 ile %100 arasında olacak şekilde ayarlanıyor
  const normalizedProgress = Math.min(Math.max(progress, 0), 100);

  return (
    <Box
      position="relative"
      display="inline-flex"
      justifyContent="center"
      alignItems="center"
      width="115px"
      height="115px"
      sx={{
        cursor: "pointer",
      }}
    >
      {/* CircularProgress bileşeni, arka planda gri renkte ilerleme gösterir */}
      <CircularProgress
        variant="determinate"
        value={100}
        size={110}
        thickness={6}
        sx={{
          color: "#EAE9E9",
          position: "absolute",
        }}
      />
      {/* CircularProgress bileşeni, progress değeri kadar siyah renkte ilerleme gösterir */}
      <CircularProgress
        variant="determinate"
        value={normalizedProgress}
        size={110}
        thickness={2}
        sx={{
          color: "#001542",
          position: "absolute",
        }}
      />
      <img
        src={img}
        alt="Profile"
        style={{
          objectFit: "cover",
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          position: "relative",
          zIndex: 1,
          padding: "2px",
        }}
      />
      {progress !== null && (
        <Tooltip
          title="Eşleşme Oranı"
          arrow
          sx={{
            "&:hover": {
              color: "white",
            },
          }}
        >
          <Box
            position="absolute"
            bottom={0}
            padding="3px 15px"
            bgcolor="#001542" //"rgba(0, 0, 0, 0.7)"
            borderRadius="15px"
            color="white"
            fontWeight="bold"
            fontSize="14px"
            zIndex={20}
            border={"1px solid white"}
          >
            {normalizedProgress}%
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};

export default ProfileMatchProgress;
