import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import {
  DashboardAction,
  DropListAction,
  DropListWhiteAction,
} from "../../../../../../../action";
import {
  customStyles,
  customStylesMulti,
  labelStyle,
} from "../../../../../../customStyleInline";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import AddPositionNotFoundModal from "../../Common/AddPositionNotFoundModal";
const FindWhitePosition = (props) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    errors,
    submitNewVersion,
    companyName,
    companyLocationName,
    getValues,
  } = props;

  const [showPosition, setShowPosition
  ] = useState(null)
  function positionHandleClose() {
    setShowPosition(false)
  }
  const educationStatus = [
    //kontrol edildi
    { id: 0, name: "Farketmez" },
    { id: 1, name: "İlkokul" },
    { id: 2, name: "Ortaokul" },
    { id: 3, name: "Lise" },
    { id: 4, name: "Ön Lisans" },
    { id: 5, name: "Lisans" },
    { id: 6, name: "Yüksek Lisans" },
    { id: 7, name: "Doktora" },
  ];

  const weekendWork = [
    { id: 1, name: "Yok" },
    { id: 2, name: "Tam Gün" },
    { id: 3, name: "Yarım Gün" },
    { id: 4, name: "Dönüşümlü" },
  ];
  const numberOfSearches = [
    //kontrol edildi
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
    { id: 5, name: "5" },
    { id: 6, name: "6" },
    { id: 7, name: "7" },
    { id: 8, name: "8" },
    { id: 9, name: "9" },
    { id: 10, name: "10" },
  ];
  useEffect(() => {
    if (!props.positionListWhite.success) {
      props.dispatch(
        DropListWhiteAction.positionListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }

    if (!props.yearOfExperience.success) {
      props.dispatch(
        DropListAction.yearOfExperience({
          positionType: 1,
          isActive: true,
        })
      );
    }

    if (!props.wayOfWorkingWhite.success) {
      props.dispatch(
        DropListWhiteAction.wayOfWorkingWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.nationalityList.success) {
      props.dispatch(
        DropListAction.nationalityList({
          positionType: 1,
          isActive: true,
        })
      );
    }

    if (!props.ageRangeListWhite.success) {
      props.dispatch(
        DropListWhiteAction.ageRangeListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }

    if (!props.salaryListWhite.success) {
      props.dispatch(
        DropListWhiteAction.salaryListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.businessTravelListWhite.success) {
      props.dispatch(
        DropListWhiteAction.businessTravelListWhite({
          positionType: 2,
          isActive: true,
        })
      );
    }
    if (!props.disabilityOfDegreeList.success) {
      props.dispatch(DropListAction.getDisabilityOfDegreeList());
    }
  }, []);

  const handlePositionSelect = (selected) => {
    props.dispatch(DropListAction.getProfileFillPcc([selected.groupCode]));
  };
  var travelpreferencesNewList = [
    //iş arayan beyaz yaka üyelikte iş seyahati tercihinde kullanıldı
    {
      id: 1,
      name: "Farketmez",
    },
    {
      id: 2,
      name: "Yok",
    },
    {
      id: 3,
      name: "Şehir İçi",
    },
    {
      id: 4,
      name: "Şehir Dışı",
    },
    {
      id: 5,
      name: "Yurt Dışı",
    },
  ];
  var disabilityEmployerList = [
    { id: 1, name: "Farketmez" },
    { id: 2, name: "Evet" },
    { id: 0, name: "Hayır" },
  ];
  const disabilityStatus = watch("disabilityStatus");
  return (
    <div style={{}}>
      <div
        className="card"
        style={{
          boxShadow: "none",
        }}
      >
        <div className="basic-form">
          <form onSubmit={handleSubmit(submitNewVersion)}>
            <CustomDesign
              label={"Pozisyon"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={
                    props.positionListWhite.success &&
                    props.positionListWhite.positionListWhite &&
                    props.positionListWhite.positionListWhite.map(
                      ({ id, name, groupCode }) => ({
                        value: id,
                        label: name,
                        groupCode,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"positionId"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Pozisyon Seçiniz"
                  infoText={"Beyaz yakada bulamadığınız pozisyonlar mavi yakada mevcut olabilir."}
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      setValue("jobPrograms", "");
                      setValue("jobCertificates", "");
                      setValue("jobCompetences", "");
                      handlePositionSelect(e);
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Pozisyon Boş Geçilemez"}
                  showRightBox={true}
                  onRightBoxTap={
                    (val) => {
                      setShowPosition(true)
                    }
                  }


                />
              }
            />
            <CustomDesign
              label={"Tecrübe Yılı"}
              required={true}
              child={
                <BitonisMultiDropdown
                  items={
                    props.yearOfExperience.yearOfExperience &&
                    props.yearOfExperience.yearOfExperience.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"jobExperienceRanges"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Tecrübe Yılı Boş geçilemez"}
                  placeholder="Tecrübe Yılı Seçiniz"
                  onValueChanged={(value) => {
                    console.log(value);
                  }}
                  required={true}
                  selectedId={1}
                  getValues={getValues}
                />
              }
            />
            <CustomDesign
              label={"Çalışma Şekli"}
              required={true}
              child={
                <BitonisMultiDropdown
                  items={
                    props.wayOfWorkingWhite.wayOfWorkingWhite &&
                    props.wayOfWorkingWhite.wayOfWorkingWhite.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"workTypes"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Çalışma Şekli Boş geçilemez"}
                  placeholder="Çalışma Şekli Seçiniz"
                  onValueChanged={(value) => {
                    console.log(value);
                  }}
                  required={true}
                  getValues={getValues}
                />
              }
            />
            <CustomDesign
              label={"Eğitim Durumu"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={educationStatus.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"educationStatus"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Eğitim Durumu Seçin"
                  infoText={"Minimum Eğitim Durumunu seçiniz."}
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Eğitim Durumu Boş geçilemez"}
                />
              }
            />
            <CustomDesign
              label={"Tercih Edilen Maaş Aralığı"}
              required={true}
              child={
                <BitonisMultiDropdown
                  items={
                    props.salaryListWhite.salaryListWhite &&
                    props.salaryListWhite.salaryListWhite.map(
                      ({ id, name }) => ({
                        value: id,
                        label: name,
                      })
                    )
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"salaryRanges"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"Tercih Edilen Maaş Aralığı Boş geçilemez"}
                  placeholder="Maaş Aralığı Seçiniz"
                  onValueChanged={(value) => {
                    console.log(value);
                  }}
                  required={true}
                  selectedId={0}
                  getValues={getValues}
                />
              }
            />
            <CustomDesign
              label={"Hafta Sonu Çalışma"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={weekendWork.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"weekendWork"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Hafta Sonu Çalışma Durumunu Seçiniz"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Hafta Sonu Çalışma Boş geçilemez"}
                />
              }
            />
            <CustomDesign
              label={"İş Seyahati Tercihi"}
              required={true}
              child={
                <BitonisMultiDropdown
                  items={
                    travelpreferencesNewList &&
                    travelpreferencesNewList.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))
                  }
                  register={register}
                  watch={watch}
                  companyClassName={"businessTravelRestrictions"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  errorMessage={"İş Seyehati Tercihiniz Boş geçilemez"}
                  placeholder="İş Seyahati Tercihini Seçiniz"
                  onValueChanged={(value) => {
                    console.log(value);
                  }}
                  required={true}
                  selectedId={1}
                  getValues={getValues}
                />
              }
            />
            <CustomDesign
              label={"Aradığınız Pozisyon İçin Eleman Sayısı"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={numberOfSearches.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"employeeCount"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Eleman Sayısını Seçiniz"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={
                    "Aradığınız Pozisyon İçin Eleman Sayısı Boş geçilemez"
                  }
                />
              }
            />
            <CustomDesign
              label={"Engellilik Durumu"}
              required={true}
              child={
                <BitonisSingleDropdown
                  items={disabilityEmployerList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                  register={register}
                  watch={watch}
                  companyClassName={"disabilityStatus"}
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  placeholder="Engellilik Durumu Seçin"
                  onValueChanged={(e) => {
                    console.log(e);
                    if (e) {
                      if (
                        e.value.toString() == "0" ||
                        e.value.toString() == "1"
                      ) {
                        setValue("degreeOfDisability", "");
                      }
                    }
                  }}
                  required={true}
                  getValues={getValues}
                  errorMessage={"Engellilik Durumu Boş geçilemez"}
                />
              }
            />

            {(disabilityStatus && disabilityStatus.value === 0) ||
              (disabilityStatus && disabilityStatus.value === "") ||
              (disabilityStatus && disabilityStatus.value === null) ||
              disabilityStatus === "" ||
              disabilityStatus === undefined ||
              (disabilityStatus && disabilityStatus.value === 1) ? (
              <div></div>
            ) : (
              <CustomDesign
                label={"Engellilik Seviyesi"}
                required={true}
                child={
                  <BitonisMultiDropdown
                    items={
                      props.disabilityOfDegreeList.disabilityOfDegreeList &&
                      props.disabilityOfDegreeList.disabilityOfDegreeList.map(
                        ({ id, name }) => ({
                          value: id,
                          label: name,
                        })
                      )
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"degreeOfDisability"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={"Engellilik Seviyesi Boş geçilemez"}
                    placeholder="Engellilik Seviyesi Seçin"
                    onValueChanged={(value) => {
                      console.log(value);
                    }}
                    required={true}
                    getValues={getValues}
                  />
                }
              />
            )}
          </form>
        </div>
      </div>
      <AddPositionNotFoundModal
        show={showPosition}
        handleClose={positionHandleClose}
        candidateType="white"
      />

    </div>
  );
};

function mapState(state) {
  let authentication = state.authentication;
  let labourer = state.labourer;
  let labourerMatch = state.labourerMatch;
  let demands = state.demands;
  let job = state.job;
  let yearOfExperience = state.yearOfExperience;
  let positionList = state.positionList;
  let wayOfWorking = state.wayOfWorking;
  let nationalityList = state.nationalityList;
  let ageRangeList = state.ageRangeList;
  let driverLicenceType = state.driverLicenceType;
  let programList = state.programList;
  let certificatesList = state.certificatesList;
  let competencesList = state.competencesList;
  let countryList = state.countryList;
  let cityList = state.cityList;
  let countyList = state.countyList;
  let districtList = state.districtList;
  let companyList = state.companyList;
  let companyLocation = state.companyLocation;

  // Beyaz Yaka
  let yearOfExperienceWhite = state.yearOfExperienceWhite;
  let positionListWhite = state.positionListWhite;
  let wayOfWorkingWhite = state.wayOfWorkingWhite;
  let nationalityListWhite = state.nationalityListWhite;
  let ageRangeListWhite = state.ageRangeListWhite;
  let driverLicenceTypeWhite = state.driverLicenceTypeWhite;
  let programListWhite = state.programListWhite;
  let certificatesListWhite = state.certificatesListWhite;
  let competencesListWhite = state.competencesListWhite;
  let countryListWhite = state.countryListWhite;
  let cityListWhite = state.cityListWhite;
  let countyListWhite = state.countyListWhite;
  let districtListWhite = state.districtListWhite;
  let companyListWhite = state.companyListWhite;
  let salaryListWhite = state.salaryListWhite;
  let businessTravelListWhite = state.businessTravelListWhite;
  let languageListWhite = state.languageListWhite;
  let languageLevelListWhite = state.languageLevelListWhite;
  let universityListWhite = state.universityListWhite;
  let facultyListWhite = state.facultyListWhite;
  let facultyDepartmentListWhite = state.facultyDepartmentListWhite;
  let companyItemLocation = state.companyItemLocation;
  let disabilityOfDegreeList = state.disabilityOfDegreeList;
  return {
    authentication,
    labourer,
    labourerMatch,
    demands,
    job,
    yearOfExperience,
    positionList,
    wayOfWorking,
    nationalityList,
    ageRangeList,
    driverLicenceType,
    programList,
    certificatesList,
    competencesList,
    countryList,
    cityList,
    countyList,
    districtList,
    companyList,
    yearOfExperienceWhite,
    positionListWhite,
    wayOfWorkingWhite,
    nationalityListWhite,
    ageRangeListWhite,
    driverLicenceTypeWhite,
    programListWhite,
    certificatesListWhite,
    competencesListWhite,
    countryListWhite,
    cityListWhite,
    countyListWhite,
    districtListWhite,
    companyListWhite,
    salaryListWhite,
    businessTravelListWhite,
    languageListWhite,
    languageLevelListWhite,
    universityListWhite,
    facultyListWhite,
    facultyDepartmentListWhite,
    companyLocation,
    companyItemLocation,
    disabilityOfDegreeList,
  };
}

export default connect(mapState)(FindWhitePosition);
