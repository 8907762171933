import { DropListAction } from "../../../../../../../../action";
import { DropListServices } from "../../../../../../../../services";
import { toast } from "react-toastify";

export default function updateInternRequestDetailsToAPI(
  form,
  jobParentId,
  preferredLocationList,
  history,
  dispatch,
  job
) {
  const jobId = jobParentId;
  const companyId = form.companyId.value;
  const companyLocationId = form.companyLocationId.value;

  const gender = parseInt(form.gender.value.toString());



  var jobPreferredLocations = [];

  preferredLocationList.map((data) => {
    let element = {};
    element["countryId"] = data.countryId.value;
    element["cityId"] = data.cityId.value;
    element["counties"] =
      data.countyId == [] || data.countyId == undefined || data.countyId == null
        ? []
        : data.countyId.map(({ value }) => value);
    element["districts"] =
      data.districts == [] ||
      data.districts == undefined ||
      data.districts == null
        ? []
        : data.districts.map(({ value }) => value);
    if (data && data.lat !== undefined && data.lat !== null) {
      element["latitude"] = data.lat.toString();
    } else {
      element["latitude"] = "";
    }
    if (data && data.lng !== undefined && data.lng !== null) {
      element["longitude"] = data.lng.toString();
    } else {
      element["longitude"] = "";
    }

    jobPreferredLocations.push(element);
  });
 
  const nationalityId = form.nationalityId.value;

  var sendDemandData = {
    jobId: jobId,
    companyId: companyId,
    companyLocationId: companyLocationId,
    gender: gender,
    nationalityId: nationalityId,
    isActive: true,
    internEducationType: form.internEducationType.value,
    internHighSchoolDepartmentId:
      form.internEducationType.value === 3
        ? form.internEducationSection.value
        : null,
    facultyDepartmentGroupId:
      form.internEducationType.value === 3
        ? null
        : form.internEducationSection.value,
    internClassLevel:
      form.internEducationClass &&
      form.internEducationClass.map((e) => e.value),
    gender: gender,
    internJobPreferredLocations: jobPreferredLocations,
    isActive: true,
    companyPreferredLocationIds: null,
  };

  DropListServices.internUpdateDemand(sendDemandData).then(
    (internUpdateDemand) => {
      if (internUpdateDemand.succeeded === true) {
        toast.success(internUpdateDemand.result.message, {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      } else {
        toast.error(internUpdateDemand.error.message, {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    },
    (error) => {}
  );
}
