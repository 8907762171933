import React from "react";
import { Box, Typography } from "@mui/material";

const PageInfoTitle = ({ title, bottomPadding, style }) => {
  const defaultStyle = {
    fontSize: {
      xs: "18px",
      sm: "18px",
      md: "18px",
      lg: "20px",
      xl: "20px",
    },
    margin: "0px",
    padding: "0px",
    fontWeight: 500,
    color: "#001542",
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",

        marginBottom:
          bottomPadding == undefined || bottomPadding == null
            ? "10px"
            : bottomPadding,
      }}
    >
      <Typography sx={{ ...defaultStyle, ...style }}>{title}</Typography>
    </Box>
  );
};

export default PageInfoTitle;
