import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import EditProfileCompanyMainDetail from "./EditProfileCompanyMainDetail";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { DropListAction, TaxInfoAction } from "../../../../../../action";
import { inputSmallStyle } from "../../../../../widgets/styleInput";
import CustomPreview from "../../../../../widgets/CustomPreview";
import EditIconWidget from "../../../../../widgets/EditIconWidget";

const ProfileCompanyBody = ({ companyMainDetailData, props, companyEmail }) => {
  const [edit, setEdit] = useState(false);
  function handleClose() {
    setEdit(false);
  }
  useEffect(() => {
    if (!props.sectorList.success) {
      props.dispatch(DropListAction.sectorList({}));
    }
  }, [props.sectorList.success]);
  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(DropListAction.countryList());
    }
    if (!props.cityList.success) {
      props.dispatch(
        DropListAction.cityList({
          countryId: 1,
        })
      );
    }
  }, []);

  return (
    <div>
      {" "}
      <div className="card-header flex-wrap border-0 pb-0">
        <h3 className="fs-24 text-black font-w600 mr-auto mb-2 pr-3">
          Şirket Bilgilerim
        </h3>

        <EditIconWidget
          size={18}
          onTap={() => {
            if (companyMainDetailData) {
              setEdit(true);
            }
          }}
          showEditText={"false"}
        />
      </div>
      <div className="card-body">
        <div className="profile-image mb-0">
          <div className="row">
            <CustomPreview
              name={"Firma Adı"}
              value={companyMainDetailData && companyMainDetailData.name}
              isOnColumn={true}
              isOnColumnClassName={"col-xl-6 col-sm-6"}
            />
            <CustomPreview
              name={"Şirket Marka Adı"}
              value={companyMainDetailData && companyMainDetailData.alias}
              isOnColumn={true}
              isOnColumnClassName={"col-xl-6 col-sm-6"}
            />
             <CustomPreview
              name={"Telefon No"}
              value={companyMainDetailData && companyMainDetailData.phoneNumber}
              isOnColumn={true}
              isOnColumnClassName={"col-xl-6 col-sm-6"}
            />
             <CustomPreview
              name={"Sektör"}
              value={
                companyMainDetailData && companyMainDetailData.sectors[0].name
              }
              isOnColumn={true}
              isOnColumnClassName={"col-xl-6 col-sm-6"}
            />
            <CustomPreview
              name={"Vergi Dairesi İli"}
              value={
                companyMainDetailData &&
                companyMainDetailData.taxOffice.cityName
              }
              isOnColumn={true}
              isOnColumnClassName={"col-xl-6 col-sm-6"}
            />
            <CustomPreview
              name={"Vergi Dairesi"}
              value={
                companyMainDetailData && companyMainDetailData.taxOffice.name
              }
              isOnColumn={true}
              isOnColumnClassName={"col-xl-6 col-sm-6"}
            />
            <CustomPreview
              name={"Şirket Hakkında"}
              value={
                companyMainDetailData && companyMainDetailData.about
              }
              isOnColumn={true}
              isOnColumnClassName={"col-xl-12 col-sm-12"}
            />
          </div>
        </div>
      </div>
      <EditProfileCompanyMainDetail
        show={edit}
        handleClose={handleClose}
        props={props}
        companyMainDetailData={companyMainDetailData}
      />
    </div>
  );
};

export default ProfileCompanyBody;
