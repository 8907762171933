import React, { useEffect, useState } from "react";
import { Card, Tab, Nav } from "react-bootstrap";
import ShowWhiteMatchShow from "./WhiteMatchShow/ShowWhiteMatchShow";
import ShowBlueMatchShow from "./BlueMatchShow/ShowBlueMatchShow";
import "../../../../../../../css/bitoniscss/match.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTab,
  setFilterWhite,
} from "../../../../../../../redux/redux-toolkit/filterMatch";
import { exportExcel } from "../Excel/exportExcel.js";
import { DemandsServices } from "../../../../../../../services";
import FilterExcelModal from "./BlueMatchShow/Modal/FilterExcelModal";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { getMatchAllBlueCreateExcelData } from "../Excel/getMatchAllBlueCreateExcelData";
import excelImage from "../../../../../../../images/svg/excelImage.svg";
import BitonisTabBar from "../../../../../../widgets/BitonisTabBar/BitonisTabBar.jsx";
import { Box, Typography } from "@mui/material";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle.jsx";
import PrevIconButton from "../../../../../../widgets/buttons/PrevIconButton.jsx";

const DemandMatchShow = () => {
  var matchSelectedListExcel = [
    { title: "İşe Alındı", status: 2 },
    { title: "İşe Alınmadı", status: 3 },
    { title: "Yeni Eşleşme", status: 1 },
    { title: "Görüşmeye Bekleniyor", status: 9 },
  ];

  const dispatch = useDispatch();
  const { activeCurrentTab } = useSelector((state) => state.selectedTab);
  const selectedChangeTab = (select) => {
    if (select == 1) {
      dispatch(
        setFilterWhite([
          { status: 0 },
          { status: 1 },
          { status: 3 },
          { status: 9 },
          { status: 5 },
        ])
      );
    }
    dispatch(setCurrentTab(select));
  };
  useState(null);
 

  return (
    <div
      id="matchShow"
      style={{
        position: "relative",
      }}
    >

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <PageInfoTitle title={"Taleplerim İle Eşleşenler"} />


      <div className="row">
        <div className="col-md-12">
          <Card>
            <Card.Header
              style={{
                borderBottom: "none",
                marginBottom: "0px",
                paddingBottom: "0px",


              }}
            >



            </Card.Header>
            <Card.Body style={{
              marginTop: "0px",
              paddingTop: "0px",
              position: "relative"

            }}>
   
              <div style={{
                marginTop: "10px"
              }}>  
                <BitonisTabBar
                  tabLeftTitle={"Mavi Yaka"}
                  tabRightTitle={"Beyaz Yaka"}
                  tabLeftBody={<ShowBlueMatchShow />}
                  tabRightBody={<ShowWhiteMatchShow />}
                  showBorder={false}
                  activeTab={activeCurrentTab}
                  tabStyle={{
                    fontSize: "0.8rem",


                  }}
                  onChangeTab={(tab) => {
                    dispatch(setCurrentTab(tab));
                  }}
                />
              </div>
              <Box sx={{
                position: "absolute",
                right: {
                  xs: 0,
                  sm: 30,
                  md: 30,
                  lg: 20,
                  xl: 20,
                },
                top: {
                  xs: -20,
                  sm: -20,
                  md: -20,
                  lg: -10,
                  xl: -10,
                }
              }}>
                {activeCurrentTab === 1 ? (
                  <div
                    style={{
                      borderRadius: "8px",

                      marginLeft: "5px",
                      backgroundColor: "white",

                      height: "35px",

                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></div>
                ) : (
                 <div></div>
                )}
              </Box>
            </Card.Body>
          </Card>
        </div>
      </div>
     
    </div>
  );
};

export default DemandMatchShow;
