import React from "react";
import "../../../../../../../css/bitoniscss/demand/pdfwidget.css";
import pdfIcon from "../../../../../../../images/cv.png"; 

const PdfWidget = ({ handlePrint }) => {
  return (
    <div className="pdf-widget" onClick={handlePrint}>
      <div className="pdfI-container">
        <img
          src={pdfIcon}
          alt="PDF Icon"
          className="pdfI-icon"
          style={{
            width: '50px', 
            height: '50px', 
            marginRight: '8px', 
          }}
        />

      </div>
    </div>
  );
};

export default PdfWidget;
