import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropListAction } from "../../../../../../../../../action";
import "../../../../../../../../../css/bitoniscss/modalcss.css";
import { labelStyle } from "../../../../../../../../customStyleInline";
import AbilitiesRequiredWidget from "../../../../../FindJobSeeker/Common/AbilitiesRequiredWidget";
import LicenseTypeRequired from "../../../../../FindJobSeeker/Common/LicenseTypeRequired";
import LicenseTypeRequiredUpdate from "../../../../../FindJobSeeker/Common/LicenseTypeRequiredUpdate";
import BitonisMultiDropdown from "../../../../../../../../widgets/BitonisMultiDropdown";
import ProgramsCertificatesCompetences from "../../../../../ProgramsCertificatesCompetences/ProgramsCertificatesCompetences";
import BitonisSingleDropdown from "../../../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../../../../widgets/buttons/CustomButton";
function EditPositionModal({
  show,
  handleClose,
  job,
  onSubmit,
  props,
  customStyles,
  customStylesMulti,
  inputStyle,
  militaryIsShow,
  setMilitaryIsShow,
  licenseIsShow,
  setLicenseIsShow,
  register,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  checkProgram,
  checkCertificate,
  checkCompetence,
  setCheckProgram,
  setCheckCertificate,
  setCheckCompetence,
  checkCLicenseType,
  setCheckLicenseType,
  checkLicenseType,
  getValues,
  interviewContactValue,
  setInterviewContactValue,
}) {
  const preferredeEmployerContactCandidateList = [
    {
      id: 1,
      name: "Belirleyeceğim günlerde benimle görüşmeye gelsinler",
    },
    {
      id: 2,
      name: "Talebim ile eşleşen adaylarla iletişime geçtikten sonra, benimle görüşmeye gelsinler.",
    },
  ];
  const gorusmeGunleriList = [
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Pazartesi",
    },
    {
      id: 2,
      name: "Salı",
    },
    {
      id: 3,
      name: "Çarşamba",
    },
    {
      id: 4,
      name: "Perşembe",
    },
    {
      id: 5,
      name: "Cuma",
    },
    {
      id: 6,
      name: "Cumartesi",
    },
    {
      id: 7,
      name: "Pazar",
    },
  ];

  const educationStatus = [
    { id: 0, name: "Farketmez" },
    { id: 1, name: "İlkokul" },
    { id: 2, name: "Ortaokul" },
    { id: 3, name: "Lise" },
    { id: 4, name: "Ön Lisans" },
    { id: 5, name: "Lisans" },
    { id: 6, name: "Yüksek Lisans" },
    { id: 7, name: "Doktora" },
  ];
  const genderList = [
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Kadın" },
    { id: 2, name: "Erkek" },
  ];

  const maritalStatus = [
    {
      id: 0,
      name: "Farketmez",
    },
    {
      id: 1,
      name: "Bekar",
    },
    {
      id: 2,
      name: "Evli",
    },
  ];

  const driverLicense = [
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Var" },
  ];

  const militaryStatus = [
    { id: 0, name: "Farketmez" },
    { id: 1, name: "Tamamlandı" },
    { id: 2, name: "Tamamlanmadı" },
  ];
  const handleMilitaryStatus = (selectedGender) => {
    if (
      selectedGender &&
      (selectedGender.value === 1 || selectedGender.value == 0)
    ) {
      setMilitaryIsShow(false);
      setValue("militaryStatus", "");
    } else {
      setMilitaryIsShow(true);
    }
  };
  const smoking = [
    { id: 0, name: "Farketmez" },
    { id: 2, name: "Hayır" },
  ];
  const handleLicenseChange = (selectedLicense) => {
    if (
      (selectedLicense && selectedLicense.value === 0) ||
      selectedLicense.value == 2
    ) {
      setValue("drivingLicenceTypes", []);
      setLicenseIsShow(false);
    } else {
      setLicenseIsShow(true);
    }
  };
  useEffect(() => {
    if (show) {
      if (!props.disabilityOfDegreeList.success) {
        props.dispatch(DropListAction.getDisabilityOfDegreeList());
      }
    }
  }, [show]);
  const jobInterviewType = watch("jobInterviewType");
  var disabilityEmployerList = [
    { id: 1, name: "Farketmez" },
    { id: 2, name: "Evet" },
    { id: 0, name: "Hayır" },
  ];

  const disabilityStatus = watch("disabilityStatus");
  useEffect(() => {
    if (show) {
      props.dispatch(
        DropListAction.getProfileFillPcc([props.job.job.positionGroupCode])
      );
      if (job.visibilityStatus === 2) {
        setInterviewContactValue(true);
      } else {
        setInterviewContactValue(false);
      }
      setValue(
        "jobInterviewDays",
        job.jobInterviewDays.map(({ interviewDay, description }) => ({
          value: interviewDay,
          label: description,
        }))
      );
    }
  }, [show]);
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            left: "auto",
          }}
          backdrop="static"
          size="lg"
        >
          <div>
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title
                aria-labelledby="contained-modal-title-vcenter"
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "0 auto",
                }}
              >
                Pozisyon
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    handleClose(); // Close the modal after form submission
                  }
                }}
              >
                <CustomDesign
                  label={"Pozisyon"}
                  required={true}
                  child={
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={props.job.job.positionName}
                      style={{
                        backgroundColor: "#F5F5F5",

                        borderRadius: "8px",
                        height: "55px",

                        border: "1px solid #dcdcdd",
                        margin: "0px",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                      disabled={true}
                      onChange={(e) => {}}
                    />
                  }
                />
                <CustomDesign
                  label={"Tecrübe Yılı"}
                  required={true}
                  child={
                    <BitonisMultiDropdown
                      items={
                        props.yearOfExperience.yearOfExperience &&
                        props.yearOfExperience.yearOfExperience.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"jobExperienceRanges"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      errorMessage={"Tecrübe Yılı Boş geçilemez"}
                      placeholder="Tecrübe Yılı"
                      getValues={getValues}
                      onValueChanged={(value) => {
                        console.log(value);
                      }}
                      required={true}
                      selectedId={1}
                    />
                  }
                />
                <CustomDesign
                  label={"Çalışma Şekli"}
                  required={true}
                  child={
                    <BitonisMultiDropdown
                      items={
                        props.wayOfWorking.wayOfWorking &&
                        props.wayOfWorking.wayOfWorking.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"workTypes"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      errorMessage={"Çalışma Şekli Boş geçilemez"}
                      placeholder="Çalışma Şekli"
                      getValues={getValues}
                      onValueChanged={(value) => {
                        console.log(value);
                      }}
                      required={true}
                    />
                  }
                />
                <CustomDesign
                  label={"Terci Edilen Görüşme Günleri"}
                  required={true}
                  child={
                    <BitonisMultiDropdown
                      items={gorusmeGunleriList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      register={register}
                      watch={watch}
                      companyClassName={"jobInterviewDays"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      errorMessage={
                        "Terci Edilen Görüşme Günleri Boş geçilemez"
                      }
                      placeholder="Tercih Edilen Görüşme Gününü Seçin "
                      onValueChanged={(value) => {
                        console.log(value);
                      }}
                      required={true}
                      selectedId={0}
                      getValues={getValues}
                      interviewContactBoolean={true}
                      interviewContactValue={interviewContactValue}
                      setInterviewContactValue={setInterviewContactValue}
                      infoText={
                        "Tercih Ettiğiniz Günlere Göre Eşleşme Yapılarak,eşleşen adaylar Yönlendirilecektir"
                      }
                    />
                  }
                />
                <CustomDesign
                  label={"Eğitim Durumu"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={educationStatus.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      register={register}
                      watch={watch}
                      companyClassName={"educationTypes"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Eğitim Durumunu Seçin"
                      infoText={"Minimum Eğitim Durumunu seçiniz."}
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Eğitim Durumu Boş geçilemez"}
                    />
                  }
                />

                <CustomDesign
                  label={"Uyruk"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.nationalityList.nationalityList &&
                        props.nationalityList.nationalityList.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"nationalityId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Uyruk Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Uyruk Boş geçilemez"}
                    />
                  }
                />
                <CustomDesign
                  label={"Cinsiyet"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={genderList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      register={register}
                      watch={watch}
                      companyClassName={"gender"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Cinsiyet Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                          handleMilitaryStatus(e);
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Cinsiyet Boş geçilemez"}
                    />
                  }
                />

                {militaryIsShow && (
                  <CustomDesign
                    label={"Askerlik Durumu"}
                    required={true}
                    child={
                      <BitonisSingleDropdown
                        items={militaryStatus.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))}
                        register={register}
                        watch={watch}
                        companyClassName={"militaryStatus"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Askerlik Durumu Seçin"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Askerlik Durumu Boş geçilemez"}
                      />
                    }
                  />
                )}
                <CustomDesign
                  label={"Medeni Hal"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={maritalStatus.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      register={register}
                      watch={watch}
                      companyClassName={"maritalStatus"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Medeni Hal Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Medeni Hal Boş geçilemez"}
                    />
                  }
                />
                <CustomDesign
                  label={"Yaş Aralığı"}
                  required={true}
                  child={
                    <BitonisMultiDropdown
                      items={
                        props.ageRangeList.ageRangeList &&
                        props.ageRangeList.ageRangeList.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"ageRanges"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      errorMessage={"Yaş Aralığı Boş geçilemez"}
                      placeholder="Yaş Aralığını Seçin"
                      onValueChanged={(value) => {
                        console.log(value);
                      }}
                      required={true}
                      selectedId={1}
                      getValues={getValues}
                    />
                  }
                />
                <CustomDesign
                  label={"Sigara Kullanımı"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={smoking.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))}
                      register={register}
                      watch={watch}
                      companyClassName={"smokerApply"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Sigara Kullanımını Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Sigara Kullanımı Boş geçilemez"}
                    />
                  }
                />
                <CustomDesign
                  label={"Engellilik Durumu"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        disabilityEmployerList &&
                        disabilityEmployerList.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"disabilityStatus"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Engellilik Durumu Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                          if (
                            e.value.toString() == "0" ||
                            e.value.toString() == "1"
                          ) {
                            setValue("degreeOfDisability", "");
                          }
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Engellilik Durumu Boş geçilemez"}
                    />
                  }
                />

                {(disabilityStatus && disabilityStatus.value === 0) ||
                (disabilityStatus && disabilityStatus.value === "") ||
                (disabilityStatus && disabilityStatus.value === null) ||
                disabilityStatus === "" ||
                disabilityStatus === undefined ||
                (disabilityStatus && disabilityStatus.value === 1) ? (
                  <div></div>
                ) : (
                  <CustomDesign
                    label={"Engellilik Durum Seviyesi"}
                    required={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          props.disabilityOfDegreeList.disabilityOfDegreeList &&
                          props.disabilityOfDegreeList.disabilityOfDegreeList.map(
                            ({ id, name }) => ({
                              value: id,
                              label: name,
                            })
                          )
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"degreeOfDisability"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"Engellilik Seviyesi Boş geçilemez"}
                        placeholder="Engellilik Seviyesi Seçin"
                        onValueChanged={(value) => {
                          console.log(value);
                        }}
                        required={true}
                        getValues={getValues}
                      />
                    }
                  />
                )}
                <CustomDesign
                  label={"Sürücü Belgesi"}
                  required={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        driverLicense &&
                        driverLicense.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"drivingLicence"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Sürücü Belgesi Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                          handleLicenseChange(e);
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Sürücü Belgesi Boş geçilemez"}
                    />
                  }
                />

                <LicenseTypeRequiredUpdate
                  licenseIsShow={licenseIsShow}
                  props={props}
                  control={control}
                  register={register}
                  errors={errors}
                  checkCLicenseType={checkLicenseType}
                  setCheckLicenseType={setCheckLicenseType}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                />

                <ProgramsCertificatesCompetences
                  programList={
                    props.programList.success && props.programList.programList
                  }
                  certificateList={
                    props.certificatesList.success &&
                    props.certificatesList.certificatesList
                  }
                  CompetenceList={
                    props.competencesList.success &&
                    props.competencesList.competencesList
                  }
                  control={control}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  errors={errors}
                  getValues={getValues}
                  checkCertificate={checkCertificate}
                  checkCompetence={checkCompetence}
                  checkProgram={checkProgram}
                  setCheckCertificate={setCheckCertificate}
                  setCheckCompetence={setCheckCompetence}
                  setCheckProgram={setCheckProgram}
                  preview={true}
                />
              </form>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
                paddingTop: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    var jobInterviewDaysGetValues = watch("jobInterviewDays");
                    if (
                      (interviewContactValue &&
                        interviewContactValue === false &&
                        jobInterviewDaysGetValues === undefined) ||
                      jobInterviewDaysGetValues === "" ||
                      jobInterviewDaysGetValues == []
                    ) {
                      if (
                        interviewContactValue &&
                        interviewContactValue === true
                      ) {
                        handleClose();
                      }
                    } else {
                      handleClose();
                    }
                  }
                }}
              >
                <CustomButton title={"Kaydet"}inSmallModal={false} />
              </form>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default EditPositionModal;
