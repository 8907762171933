import React, { useEffect } from "react";
import CustomDesign from "../../CustomDesign";
import BitonisSingleDropdown from "../../BitonisSingleDropdown";
import { DropListAction } from "../../../../action";
import BitonisMultiDropdown from "../../BitonisMultiDropdown";
import { DropListServices } from "../../../../services";

const SelectMultiRoute = ({
  props,
  register,
  watch,
  control,
  setValue,
  errors,
  getValues,
  getLatLngApi,
  preview,
  setSemtNewList,
  semtNewList,
  handleCity,
  handleCounty,
  showCountiesAndDistricts,
  setShowCountiesAndDistricts,
  cityList,
  setCityList,
}) => {
  useEffect(() => {
    if (!props.countryList.success) {
      props.dispatch(DropListAction.countryList());
    }
    DropListServices.allCityList({
      countryId: 1,
    }).then(
      (cityData) => {
        setCityList([]);
        if (cityData && cityData.length > 0) {
          cityData.map((value) => setCityList((prev) => [...prev, value]));
        }
      },
      (error) => { }
    );
  }, []);
  useEffect(() => {
    if (preview) {
    } else {
      setValue("countryId", "");
      setValue("cityId", "");
      setValue("countyId", "");
      setValue("districts", "");
    }
  }, [preview]);

  return (
    <div>

      <div className="row">
        {props.countryList.countryList && (
          <CustomDesign
            label={"Ülke"}
            required={true}
            isColumn={true}
            columnClassName={"col-xs-6 col-md-6"}
            child={
              <BitonisSingleDropdown
                items={
                  props.countryList.countryList &&
                  props.countryList.countryList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"countryId"}
                control={control}
                setValue={setValue}
                errors={errors}
                placeholder="Ülke Seç"
                onValueChanged={(e) => {
                  console.log(e);
                  setValue("cityId", "");
                  setValue("countyId", "");
                  setValue("districts", "");
                }}
                required={true}
                getValues={getValues}
                errorMessage={"Ülke Boş Olamaz"}
              />
            }
          />
        )}

        {cityList && (
          <CustomDesign
            label={"İl/Şehir"}
            required={false}
            isColumn={true}
            columnClassName={"col-xs-6 col-md-6"}
            child={
              <BitonisSingleDropdown
                items={
                  cityList &&
                  cityList.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))
                }
                register={register}
                watch={watch}
                companyClassName={"cityId"}
                control={control}
                setValue={setValue}
                errors={errors}
                placeholder="İl/Şehir Seç"
                infoText={"İl Farketmez, seçtiğiniz an Türkiye genelindeki tüm iş arayanlarla eşleşmeniz sağlanır."}
                onValueChanged={(e) => {
                  console.log(e);
                  setValue("countyId", "");
                  setValue("districts", "");
                  setSemtNewList([]);
                  if (e) {
                    handleCity(e);
                    setSemtNewList([]);
                    var country = watch("countryId");
                    if (country && e) {
                      var adres = country.label + "/" + e.label;
                      getLatLngApi(adres);
                    }
                  }
                }}
                required={true}
                getValues={getValues}
                errorMessage={"İl/Şehir Boş Olamaz"}
              />
            }
          />
        )}
      </div>

      {showCountiesAndDistricts && (
        <div className="row">
          {props.countyList.countyList &&
            props.countyList.countyList.length > 0 && (
              <CustomDesign
                label={"İlçe"}
                required={true}
                isColumn={true}
                columnClassName={semtNewList == undefined ||
                  semtNewList == null || JSON.stringify(semtNewList) == "[]" ? "col-xs-12 col-md-12" : "col-xs-6 col-md-6"}
                child={
                  <BitonisMultiDropdown
                    items={
                      props.countyList.countyList &&
                      props.countyList.countyList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"countyId"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={"İlçe Boş Olamaz "}
                    placeholder="İlçe Seç"
                    onValueChanged={(value) => {
                      console.log(value);
                      if (value) {
                        const selectedValues = value.map(
                          (option) => option.value
                        );
                        if (selectedValues.includes(990)) {
                          //  alert("value* * * " + JSON.stringify(value));
                          setValue("districts", "");
                          setSemtNewList([]);
                        } else {
                          handleCounty(value);
                          setValue("districts", "");
                        }
                      }
                    }}
                    required={true}
                    selectedId={990}
                    getValues={getValues}
                  />
                }
              />
            )}
          {semtNewList == undefined ||
            (semtNewList == null || JSON.stringify(semtNewList) == "[]" ? (
              <div></div>
            ) : (
              <CustomDesign
                label={"Semt/Mahalle"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <BitonisMultiDropdown
                    items={
                      semtNewList &&
                      semtNewList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"districts"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    errorMessage={""}
                    placeholder="Semt/Mahalle Seç"
                    onValueChanged={(value) => {
                      console.log(value);
                      if (value) {
                        var country = watch("countryId");
                        var city = watch("cityId");
                        var county = watch("countyId");
                        if (country && city && county && value) {
                          var countyLabel = "";
                          if (county && county.length) {
                            countyLabel = county[0].label;
                          }
                          var distLabel = "";
                          if (value && value.length) {
                            distLabel = value[0].label;
                          }
                          var adres =
                            country.label +
                            "/" +
                            city.label +
                            "/" +
                            countyLabel +
                            "/" +
                            distLabel;
                          getLatLngApi(adres);
                        }
                      }
                    }}
                    required={false}
                    getValues={getValues}
                  />
                }
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default SelectMultiRoute;
