import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import { Container } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import PrevIconButton from "../buttons/PrevIconButton";

const BitonisTabBar = ({
  tabRightTitle,
  tabLeftTitle,
  tabLeftBody,
  tabRightBody,
  showBorder,
  activeTab,
  tabStyle,
  onChangeTab,
  inModal
}) => {
  const handleChange = (event, newValue) => {
    onChangeTab(newValue);
  };
  return (
    <div className="card">
      <Container
        style={{
          marginTop: "10px",
        }}
      >{inModal === false || inModal === undefined || inModal === null?(<PrevIconButton top={-10} left={-20} />):<div></div>}
        <Tabs defaultValue={0} onChange={handleChange}>
          <div
            className="card"
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <TabsList>
              <Tab sx={{
                fontSize: {
                  xs: "11px",
                  sm: "12px",
                  md: "12px",
                  lg: "13px",
                  xl: "13px",
                },
              }}
                value={activeTab == 0 ? 0 : null}
                style={{

                }}
                onChange={(val) => {
               //   alert("val ** " + JSON.stringify(val));
                }}
              >
                {tabLeftTitle}{" "}
              </Tab>
              <Tab
                value={activeTab == 1 ? 1 : null}
                sx={{
                  fontSize: {
                    xs: "11px",
                    sm: "12px",
                    md: "12px",
                    lg: "13px",
                    xl: "13px",
                  },
                }}
              >
                {tabRightTitle}
              </Tab>
            </TabsList>
          </div>
          <TabPanel
            value={activeTab == 0 ? 0 : null}
            style={
              showBorder && showBorder === true
                ? {
                  border: "1px solid #EEF7FF",
                  borderWidth: "1px",
                }
                : {}
            }
          >
            {tabLeftBody}
          </TabPanel>
          <TabPanel
            value={activeTab == 1 ? 1 : null}
            style={
              showBorder && showBorder === true
                ? {
                  border: "1px solid #EEF7FF",
                  borderWidth: "1px",

                }
                : {}
            }
          >
            {tabRightBody}
          </TabPanel>
        </Tabs>
      </Container>
    </div>
  );
};

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: white;
    color: #001542;
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${blue[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: #001542;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border-radius: 12px;
  opacity: 1;

  `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  min-width: 400px;
  background-color: #001542;
  border-radius: 12px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
   /* Media queries for responsive design */
  @media (max-width: 576px) { /* xs */
    min-width: 80%;
  
  }

  @media (min-width: 576px) and (max-width: 768px) { /* sm */
    min-width: 80%;
  }

  @media (min-width: 768px) and (max-width: 992px) { /* md */
    min-width: 80%;
  }

  @media (min-width: 992px) and (max-width: 1200px) { /* lg */
    min-width: 60%;
  }

  @media (min-width: 1200px) { /* xl */
    min-width: 55%;
  }
  `
);

export default BitonisTabBar;
