import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DropListAction,
  ProfileDetailAction,
  TaxInfoAction,
} from "../../../../../../../action";
import api from "../../../../../../../utility/Utils";
import RadioButtonWidget from "../../../../../../widgets/RadioButtonWidget";
import { customStyles } from "../../../../../../customStyleInline";
import { DropListServices } from "../../../../../../../services";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import SelectSingleRoute from "../../../../../../widgets/components/common/SelectSingleRoute";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import GoogleMapWidget from "../../../../../../widgets/map/GoogleMapWidget";

function EmployerAddGroupCompanies({ show, handleClose, props }) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [companyMainInfoLocation, setCompanyMainInfoLocation] = useState(null);
  useMemo(() => {
    if (!props.companyMainDetail.success) {
      props.dispatch(ProfileDetailAction.companyMainDetail({}));
    }
  }, []);

  useMemo(() => {
    if (props.companyMainDetail?.success) {
      const listValue = props.companyMainDetail.companyMainDetail;
      let companyMainInfoLocation = listValue.locations[0]; //ana şirketin bilgilerini aldım ,,phone number  için
      // alert("companyInfoLocation* * *" + JSON.stringify(companyInfoLocation));
      setCompanyMainInfoLocation(companyMainInfoLocation);
    }
  }, [props.companyMainDetail.success]);

  useEffect(() => {
    if (!props.sectorList.success) {
      props.dispatch(DropListAction.sectorList({}));
    }
  }, [props.sectorList.success]);
  async function checkControlTaxNumber(value) {
    api
      .post("account/employer/taxnumbervalidation/" + value)
      .then((response) => {
        if (response.succeeded == true) {
          getCompanyName(value);
        } else {
          setValue("taxNumber", "");
          setValue("firmaName", "");
          toast.error(response.error.message, {
            autoClose: 3000,

            onClose: () => { },
          });
        }
      })
      .catch((err) => {
        setName("");
      });
  }

  async function getCompanyName(value) {
    api
      .get("common/commercialcompanyby/" + value)
      .then((response) => {
        var companyN = response.data.title;
        if (companyN == undefined || companyN == null) {
          setValue("firmaName", "");
        } else {
          setValue("firmaName", companyN);
        }
      })
      .catch((err) => {
        setValue("firmaName", "");
      });
  }

  const handleCity = (selected) => {
    props.dispatch(DropListAction.countyList(selected.value));
  };
  const handleDistry = (selected) => {
    props.dispatch(DropListAction.districtList(selected.value));
  };
  async function getLatLngApi(adress) {
    DropListServices.handleGetLocation(adress).then((location) => {
      // alert("lat " + location.lat + " " + "lng " + location.lng);
      if (location) {
        var latitute = location.lat;
        var longtitute = location.lng;

        setLat(latitute);
        setLng(longtitute);

        setLat(latitute);
        setLng(longtitute);
      } else {
      }
    });
  }

  const handleTaxCounty = (e) => {
    const selectedValue = e;

    const selectedCity = props.cityList.cityList.find(
      (item) => item.code.toString() === selectedValue.code.toString()
    );
    const cityCode = selectedCity ? selectedCity.code : null;
    // setCityId(cityCode);
    props.dispatch(TaxInfoAction.taxOffices(cityCode));
  };
  function addCompanyFetch(arg) {
    //grup şirketi ekle
    return api
      .post("/company/create", arg)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  const [brandCompanyName, setBrandCompanyName] = useState(null); //marka adı
  function addCompany(formData) {
    let sectorIds = [];

    sectorIds.push(formData.sector.value);
    // alert("sectorIds* * * " + JSON.stringify(sectorIds));
    let sendData = {
      id: companyMainInfoLocation.companyId,
      name: formData.firmaName.toString(),
      alias:
        formData.brandCompanyName == null ||
          formData.brandCompanyName == undefined ||
          formData.brandCompanyName == ""
          ? formData.firmaName.toString()
          : formData.brandCompanyName.toString(),
      taxNumber: formData.taxNumber.toString(),
      taxOfficeId: formData.taxOfficeId.value,
      sectorIds: sectorIds,
      phoneNumber: companyMainInfoLocation.phoneNumber.toString(),
      locationName: "", //companyMainInfoLocation.locationName.toString(),
      countryId: formData.countryId.value,
      cityId: parseInt(formData.cityId.value.toString()),
      countyId: parseInt(formData.countyId.value.toString()),
      districtId: parseInt(formData.districts.value.toString()),
      address: formData.adress.toString(),
      latitude: lat.toString(),
      longitude: lng.toString(),
    };

    addCompanyFetch(sendData).then(
      (addCompanyGetRequest) => {
        // alert("updateCompanyData* * * " + JSON.stringify(addCompanyGetRequest));
        if (addCompanyGetRequest) {
          if (addCompanyGetRequest.succeeded === true) {
            handleClose(false);
            toast.success("Grup Şirketi Başarılı Şekilde Eklendi", {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  window.location.href = window.location.href;
                }, 100);
              },
            });
          } else {
            toast.info(addCompanyGetRequest.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  window.location.href = window.location.href;
                }, 100);
              },
            });
          }
        } else {
          toast.info("Hata Oluştu", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      },
      (error) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      }
    );
  }
  const [checkedTaxTypeIndex, setCheckedTaxTypeIndex] = useState(1);
  var adress = watch("adress");
  var firmaName = watch("firmaName");
  var countryId = watch("countryId");
  var cityId = watch("cityId");
  var countyId = watch("countyId");
  var districtsId = watch("districts");

  useEffect(() => {
    if (show) {
      if (countryId && cityId && countyId && districtsId&&firmaName) {
        var createLatLngLastVersion =
          cityId.label + ","+
        "+" +
          countyId.label + ","+
        "+" +
          districtsId.label + ","+
        "+" +
          firmaName;
        getLatLngApi(createLatLngLastVersion);
      } else {
        var createLatLngLastVersion = firmaName;
        getLatLngApi(createLatLngLastVersion);
      }

    }
  }, [show, firmaName, countryId, cityId, countyId, districtsId]);



  return (
    <Modal
      className="fade bd-example-modal-lg"
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          borderBottom: "none",
          justifyContent: "center",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <Modal.Title aria-labelledby="contained-modal-title-vcenter" centered>
          <h4 style={{ color: "#001542", fontWeight: "600" }}>
            {" "}
            Grup Şirketi Ekle
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="grid-example"
        style={{
          marginTop: "10px",
          paddingTop: "10px",
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <div className="container">
          <form onSubmit={handleSubmit(addCompany)}>
            <div className="row">
              <div
                className="col-xs-6 col-md-6"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCheckedTaxTypeIndex(1);
                  setValue("taxNumber", "");
                  setValue("firmaName", "");
                  setValue("taxCounty", "");
                  setValue("taxOfficeId", "");
                  setValue("sector", "");
                  setValue("countryId", "");
                  setValue("cityId", "");
                  setValue("districts", "");
                  setValue("countyId", "");
                  setValue("adress", "");
                }}
              >
                <div style={{}}>
                  {" "}
                  <RadioButtonWidget
                    name={"Tüzel Şirket"}
                    id={1}
                    value={1}
                    activeId={checkedTaxTypeIndex}
                    onChange={(value) => {
                      setCheckedTaxTypeIndex(1);
                      setValue("taxNumber", "");
                      setValue("firmaName", "");
                      setValue("taxCounty", "");
                      setValue("taxOfficeId", "");
                      setValue("sector", "");
                      setValue("countryId", "");
                      setValue("cityId", "");
                      setValue("districts", "");
                      setValue("countyId", "");
                      setValue("adress", "");
                    }}
                    checked={checkedTaxTypeIndex == 1 ? true : false}
                    text={
                      <p
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCheckedTaxTypeIndex(1);
                          setValue("taxNumber", "");
                          setValue("firmaName", "");
                          setValue("taxCounty", "");
                          setValue("taxOfficeId", "");
                          setValue("sector", "");
                          setValue("countryId", "");
                          setValue("cityId", "");
                          setValue("districts", "");
                          setValue("countyId", "");
                          setValue("adress", "");
                        }}
                      >
                        Tüzel Şirket
                      </p>
                    }
                  />
                </div>
              </div>
              <div
                className="col-xs-6 col-md-6"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCheckedTaxTypeIndex(2);
                  setValue("taxNumber", "");
                  setValue("firmaName", "");
                  setValue("taxCounty", "");
                  setValue("taxOfficeId", "");
                  setValue("sector", "");
                  setValue("countryId", "");
                  setValue("cityId", "");
                  setValue("districts", "");
                  setValue("countyId", "");
                  setValue("adress", "");
                }}
              >
                <RadioButtonWidget
                  name={"Tüzel Şirket"}
                  id={2}
                  value={2}
                  onChange={(value) => {
                    setCheckedTaxTypeIndex(2);
                    setValue("taxNumber", "");
                    setValue("firmaName", "");
                    setValue("taxCounty", "");
                    setValue("taxOfficeId", "");
                    setValue("sector", "");
                    setValue("countryId", "");
                    setValue("cityId", "");
                    setValue("districts", "");
                    setValue("countyId", "");
                    setValue("adress", "");
                  }}
                  activeId={checkedTaxTypeIndex}
                  checked={checkedTaxTypeIndex == 2 ? true : false}
                  text={
                    <p
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setCheckedTaxTypeIndex(2);
                        setValue("taxNumber", "");
                        setValue("firmaName", "");
                        setValue("taxCounty", "");
                        setValue("taxOfficeId", "");
                        setValue("sector", "");
                        setValue("countryId", "");
                        setValue("cityId", "");
                        setValue("districts", "");
                        setValue("countyId", "");
                        setValue("adress", "");
                      }}
                    >
                      Şahıs Şirket
                    </p>
                  }
                />
              </div>
              <CustomDesign
                label={"Vergi Numarası"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <>
                    <input
                      style={{
                        marginTop: "0px",
                      }}
                      type="text"
                      id="inputClass"
                      className="form-control"
                      placeholder="Vergi No"
                      name="taxNumber"
                      {...register("taxNumber", { required: true })}
                      maxLength={checkedTaxTypeIndex == 1 ? 10 : 11}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          const input = e.target;
                          const value = input.value;
                          if (checkedTaxTypeIndex == 1) {
                            if (parseInt(input.value.length) === 10) {
                              checkControlTaxNumber(input.value);
                            } else {
                              setValue("firmaName", "");
                            }
                          } else {
                            if (parseInt(input.value.length) === 11) {
                              checkControlTaxNumber(input.value);
                            } else {
                              setValue("firmaName", "");
                            }
                          }
                        }
                      }}
                    />{" "}
                    {errors.taxNumber && (
                      <div className="text-danger fs-12">
                        Vergi Numarası boş olamaz
                      </div>
                    )}
                  </>
                }
              />
              <CustomDesign
                label={"Firma Adı"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <>
                    {" "}
                    <input
                      style={{
                        marginTop: "0px",
                      }}
                      type="text"
                      className="form-control"
                      id="inputClass"
                      rules={{ required: true }}
                      name="firmaName"
                      {...register("firmaName", { required: true })}
                      placeholder="Firma Adı"
                    ></input>
                    {errors.firmaName && (
                      <div className="text-danger fs-12">
                        Firma Adı Boş Olamaz
                      </div>
                    )}
                  </>
                }
              />
              <CustomDesign
                label={"Firma Marka Adı"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <input
                    style={{
                      marginTop: "0px",
                    }}
                    type="text"
                    className="form-control"
                    id="inputClass"
                    name="brandCompanyName"
                    {...register("brandCompanyName", { required: false })}
                    placeholder="Firma Marka Adı"
                  ></input>
                }
              />
              <CustomDesign
                label={"Vergi Dairesi İli"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-6 col-md-6"}
                child={
                  <BitonisSingleDropdown
                    items={
                      props.cityList.cityList &&
                      props.cityList.cityList.map(({ id, name, code }) => ({
                        value: id,
                        label: name,
                        code: code,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"taxCounty"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Vergi Dairesi İli Seç"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                        setValue("taxOfficeId", "");
                        handleTaxCounty(e);
                      }
                    }}
                    required={true}
                    getValues={getValues}
                    errorMessage={"Vergi Dairesi İli Boş Olamaz"}
                  />
                }
              />

              {props.taxOffices.success && (
                <CustomDesign
                  label={"Vergi Dairesi"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-6 col-md-6"}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.taxOffices.taxOffices &&
                        props.taxOffices.taxOffices.pagedData.map(
                          ({ id, name }) => ({
                            value: id,
                            label: name,
                          })
                        )
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"taxOfficeId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Vergi Dairesi Seç"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Vergi Dairesi Boş Olamaz"}
                    />
                  }
                />
              )}
              <CustomDesign
                label={"Sektör"}
                required={true}
                isColumn={true}
                columnClassName={
                  !props.taxOffices.success
                    ? "col-xs-12 col-md-12"
                    : "col-xs-6 col-md-6"
                }
                child={
                  <BitonisSingleDropdown
                    items={
                      props.sectorList.success &&
                      props.sectorList.sectorList &&
                      props.sectorList.sectorList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      }))
                    }
                    register={register}
                    watch={watch}
                    companyClassName={"sector"}
                    control={control}
                    setValue={setValue}
                    errors={errors}
                    placeholder="Sektör Seç"
                    onValueChanged={(e) => {
                      console.log(e);
                      if (e) {
                      }
                    }}
                    required={true}
                    getValues={getValues}
                    errorMessage={"Sektör Boş Olamaz"}
                  />
                }
              />
            </div>

            <SelectSingleRoute
              props={props}
              register={register}
              watch={watch}
              control={control}
              setValue={setValue}
              errors={errors}
              getValues={getValues}
              getLatLngApi={getLatLngApi}
              preview={false}
              employerCompanyName={firmaName}
            />
          

            <div className="row">
              <CustomDesign
                label={"Şirket Adresi"}
                required={true}
                isColumn={true}
                columnClassName={"col-xs-12 col-md-12"}
                child={
                  <>
                    {
                      <textarea
                        name="adress"
                        id="noter-text-area-active"
                        {...register("adress", {
                          required: true,
                        })}
                      ></textarea>
                    }
                    {errors.adress && (
                      <div className="text-danger fs-12">
                        {"Şirket Adresi Boş Olamaz "}
                      </div>
                    )}
                  </>
                }
              />
            </div>
            <GoogleMapWidget
              initialLatLng={{
                lat: lat,
                lng: lng,
              }}
              onChangedPositioned={(position) => {
                setLat(position.lat);
                setLng(position.lng);
              }}
              heightMap={"60vh"}
            />
          </form>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          justifyContent: "center",
          borderTop: "none",
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        <div
          className="col-xs-6 col-md-6"
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            marginTop: "10px",
          }}
        >
          <form onSubmit={handleSubmit(addCompany)}>
            <CustomButton
              title={"Kaydet"}
              width={{
                xs: "400px",
                sm: "400px",
                md: "400px",
                lg: "400px",
                xl: "400px",
              }}
            />
          </form>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default EmployerAddGroupCompanies;
