import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import { AccountServices } from "../../../../../../services/AccountServices";
import "../../../../../../css/bitoniscss/Home/cardsettings.css";
import { UserService } from "../../../../../../services/UserService";
import LoadingDotsAnimation from "../../../../../widgets/LoadingDotsAnimation";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

const FreezeAccount = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  const [reasonList, setReasonList] = useState(null);
  const [selectReasonWhy, setSelectReasonWhy] = useState(null);
  useEffect(() => {
    disableReasonsListGet();
  }, []);

  function disableReasonsListGet() {
    AccountServices.disableReasonsListGet(2).then(
      async (getResponse) => {
        if (getResponse.succeeded === true) {
          if (getResponse.data && getResponse.data.length > 0) {
            setReasonList([]);
            getResponse.data.map((val) => {
              setReasonList((prev) => [...prev, val]);
            });
          } else {
            setReasonList([]);
          }
        } else {
          setReasonList([]);
        }
      },
      (error) => {}
    );
  }

  function onSubmit(value) {
    if (
      selectReasonWhy === null ||
      selectReasonWhy === undefined ||
      selectReasonWhy === ""
    ) {
      toast.error("Lütfen Hesabınızı Neden Dondurmak İstediğinizi Seçin", {
        autoClose: 2000,
        onClose: () => {
          setTimeout(() => {}, 100);
        },
      });
    } else {
      var sendData = {
        disableReasonId: selectReasonWhy.id,
        disableReasonDescription: selectReasonWhy.description,
      };
      AccountServices.disableFreezeDelete(sendData).then(
        async (getResponse) => {
          if (getResponse.succeeded === true) {
            toast.info(getResponse.result.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  UserService.logout();
                  history.push("/");
                  window.location.href = window.location.href;
                }, 100);
              },
            });
          } else {
            toast.info(getResponse.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        },
        (error) => {}
      );
    }
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        id="settingBody"
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        {" "}
        <div
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            textAlign:"center"
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "600",
              fontSize:"20px",
            }}
          >Hesap Dondur</p>
        </div>
        <div
          className="card"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <div
            className="card-header"
            style={{
              borderBottom: "none",
              marginLeft: "18px",
              paddingLeft: "18px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p></p>
            </div>
          </div>
          <div
            className="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            {" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <label className="mb-1">
                <strong className="labelClass" style={{color:"#001542"}}>
                  Hesabını Neden Dondurmak İstiyorsun?
                </strong>
              </label>
              {reasonList === undefined || reasonList === null ? (
                <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation/>
          </div>


              ) : (
                reasonList &&
                reasonList.map((value, index) => {
                  return (
                    <div style={{}}>
                      <div
                        onClick={() => {
                          setSelectReasonWhy(value);
                        }}
                        style={
                          selectReasonWhy && selectReasonWhy.id === value.id
                            ? {
                                display: "flex",
                                border: "1px solid #F6F5F5",
                                padding: "10px",
                                borderRadius: "8px",
                                height: "50px",
                                margin: "10px",
                                backgroundColor: "#F3F8FF",
                                cursor: "pointer",
                              }
                            : {
                                display: "flex",
                                border: "1px solid #F6F5F5",
                                padding: "10px",
                                borderRadius: "8px",
                                height: "50px",
                                margin: "10px",
                                cursor: "pointer",
                              }
                        }
                      >
                        <RadioButtonWithTitle
                          title={value.description}
                          onChange={() => {
                            setSelectReasonWhy(value);
                          }}
                          checked={
                            selectReasonWhy && selectReasonWhy.id === value.id
                          }
                        />
                      </div>
                    </div>
                  );
                })
              )}{" "}
              {reasonList && reasonList.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",   marginTop:"15px"
                  }}
                >
                  {" "}
                
                  <CustomButton title={"Hesabını Dondur"} width={{
                    xs: "350px",
                    sm: "350px",
                    md: "350px",
                    lg: "350px",
                    xl: "350px",
                  }} />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreezeAccount;
function RadioButtonWithTitle({ title, checked, onChange }) {
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="radio"
        checked={checked}
        onChange={onChange}
        style={{
          width: "20px",
          height: "20px",
          cursor: "pointer",
        }}
      />
      <label
        className="form-check-label"
        style={{
          fontSize: "13px",
          color: "#001542",
          fontWeight: 450,
          margin: "5px",
          cursor: "pointer",
        }}
      >
        {title}
      </label>
    </div>
  );
}
