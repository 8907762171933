import React, { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropListAction } from "../../../../../../../../action";
import "../../../../../../../../css/bitoniscss/modalcss.css";
import { DropListServices } from "../../../../../../../../services";
import getMultiSemt from "../functions/preferredGetMultiSemt";
import BitonisMultiDropdown from "../../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../../../widgets/buttons/CustomButton";
function EditPreferredLocationModal({
  show,
  handleClose,
  job,
  onSubmit,
  props,
  customStyles,
  customStylesMulti,
  inputStyle,
  register,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  semtNewList,
  setSemtNewList,
  indexItem,
  setIndexItem,
  location,
  preferredLocationRef,
  getValues,
  isOnIntern,
}) {
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [showSemt, setShowSemt] = useState(null);
  const [showCountiesAndDistricts, setShowCountiesAndDistricts] =
    useState(true);
  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    if (show) {
      if (!props.countryList.success) {
        props.dispatch(DropListAction.countryList());
      }
      DropListServices.allCityList({
        countryId: 1,
      }).then(
        (cityData) => {
          setCityList([]);
          if (cityData && cityData.length > 0) {
            cityData.map((value) => setCityList((prev) => [...prev, value]));
          }
        },
        (error) => {}
      );
    }
  }, [show]);
  useEffect(() => {
    if (show) {
      setShowCountiesAndDistricts(true);
      setValue("countryId", location.countryId);
      setValue("cityId", location.cityId);

      if (location.cityId.value.toString() == "83") {
        setShowCountiesAndDistricts(false);
      } else {
        setShowCountiesAndDistricts(true);
      }
      setValue(
        "countyId",
        location.countyId == [] || location.countyId == ""
          ? []
          : location.countyId
      );
      if (location.countyId == [] || location.countyId == "") {
      } else {
        setSemtNewList([]);
        getMultiSemt(false, location.countyId, semtNewList, setSemtNewList);
      }
      setValue("districts", location.districts == [] ? [] : location.districts);
      if (location.districts === undefined || location.districts == []) {
        var adres = location.countryId.label + "/" + location.cityId.label;
        getLatLngApi(adres);
      } else {
        if (location && location.countyId && location.countyId) {
          var adres =
            location.countryId.label +
              "/" +
              location.cityId.label +
              "/" +
              location.countyId.length >
              0 &&
            location.countyId &&
            location.countyId[0].label;
        } else {
          var adres = location.countryId.label + "/" + location.cityId.label;
        }

        getLatLngApi(adres);
      }
    }
  }, [show, location]);
  const handleCity = (selected) => {
    if (selected.value.toString() == "83") {
      setShowCountiesAndDistricts(false);
    } else {
      setShowCountiesAndDistricts(true);
    }
    props.dispatch(DropListAction.allCountyList(selected.value));
  };

  async function getLatLngApi(adress) {
    DropListServices.handleGetLocation(adress).then(
      (loc) => {
        if(loc){
          var l = loc.lat;
          var lg = loc.lng;
          setLat(l);
          setLng(lg);
        }
      
      },
      (error) => {}
    );
  }
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          {" "}
          <Modal.Header closeButton style={{ borderBottom: "0px" }}>
            <Modal.Title
              aria-labelledby="contained-modal-title-vcenter"
              style={{
                width: "100%",
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              {isOnIntern && isOnIntern == true
                ? "Stajyer Aradığınız Güzergahlar"
                : "Eleman Aradığınız Güzergahlar"}
            </Modal.Title>
          </Modal.Header>
          <div className="">
            <Modal.Body
              style={{
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  if (isValid) {
                    //   handleClose(); // Close the modal after form submission
                  }
                }}
              >
                <CustomDesign
                  label={"Ülke"}
                  required={true}
                  isColumn={true}
                  child={
                    <BitonisSingleDropdown
                      items={
                        props.countryList.countryList &&
                        props.countryList.countryList.map(({ id, name }) => ({
                          value: id,
                          label: name,
                        }))
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"countryId"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      placeholder="Ülke Seçin"
                      onValueChanged={(e) => {
                        console.log(e);
                        if (e) {
                          props.dispatch(
                            DropListAction.allCityList({
                              countryId: e.value,
                            })
                          );
                          setValue(`cityId`, ""); //
                          setValue(`countyId`, ""); //
                          setValue(`districts`, "");

                          if (e) {
                            var adres = e.label;
                            getLatLngApi(adres);
                          }
                        }
                      }}
                      required={true}
                      getValues={getValues}
                      errorMessage={"Ülke boş bırakılamaz"}
                    />
                  }
                />

                {cityList && (
                  <CustomDesign
                    label={"İl/Şehir"}
                    required={true}
                    isColumn={true}
                    child={
                      <BitonisSingleDropdown
                        items={
                          cityList &&
                          cityList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"cityId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="İl/Şehir Seçin"
                        infoText={"İl Farketmez, seçtiğiniz an Türkiye genelindeki tüm iş arayanlarla eşleşmeniz sağlanır."}
                        onValueChanged={(e) => {
                          console.log(e);
                          setValue("countyId", "");
                          setValue("districts", "");
                          setSemtNewList([]);
                          if (e) {
                            handleCity(e);

                            var country = watch("countryId");

                            if (country && e) {
                              var adres = country.label + "/" + e.label;
                              getLatLngApi(adres);
                            }
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"İl/Şehir boş bırakılamaz"}
                      />
                    }
                  />
                )}

                {props.countyList.countyList && showCountiesAndDistricts && (
                  <CustomDesign
                    label={"İlçe"}
                    required={true}
                    isColumn={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          props.countyList.countyList &&
                          props.countyList.countyList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"countyId"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={"İlçe Boş Olamaz "}
                        placeholder="İlçe Seç"
                        onValueChanged={(e) => {
                          if (e) {
                            const selectedValues = e.map(
                              (option) => option.value
                            );
                            if (selectedValues.includes(990)) {
                              setValue("districts", "");
                              setSemtNewList([]);
                              const selectedOption = e.find(
                                (option) => option.value === 990
                              );
                              if (selectedOption) {
                              } else {
                              }
                            } else {
                              setSemtNewList([]);
                              getMultiSemt(
                                false,
                                e,
                                semtNewList,
                                setSemtNewList
                              );
                              setValue("districts", "");
                            }
                          } else {
                            setValue("districts", "");
                            setSemtNewList([]);
                          }
                        }}
                        required={true}
                        selectedId={990}
                        getValues={getValues}
                      />
                    }
                  />
                )}

                {semtNewList && semtNewList.length > 0 && (
                  <CustomDesign
                    label={"Semt/Mahalle"}
                    required={true}
                    isColumn={true}
                    child={
                      <BitonisMultiDropdown
                        items={
                          semtNewList &&
                          semtNewList.map(({ id, name }) => ({
                            value: id,
                            label: name,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"districts"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        errorMessage={""}
                        placeholder="Semt/Mahalle Seç"
                        onValueChanged={(e) => {
                          if (e) {
                            var country = watch("countryId");
                            var city = watch("cityId");
                            var county = watch("countyId");
                            if (country && city && county && e) {
                              var adres =
                                country.label +
                                "/" +
                                city.label +
                                "/" +
                                county.label +
                                e.label;
                              getLatLngApi(adres);
                            }
                          }
                        }}
                        getValues={getValues}
                      />
                    }
                  />
                )}
              </form>
            </Modal.Body>
            <Modal.Footer
              style={{
                display: "flex",
                flexDirection: "Row",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
                borderTop: "none",
                margin: "0px",
                paddingTop: "0px",
              }}
            >
              {" "}
              {preferredLocationRef.current.length !== 1 && (
                <form
                  onSubmit={(e) => {
                    var transletCountyId = null;
                    var transletDistrictsId = null;
                    e.preventDefault();
                    handleSubmit(onSubmit)();
                    const countryId = watch("countryId");
                    const cityId = watch("cityId");
                    const countyId = watch("countyId");
                    const districts = watch("districts");
                    if (countryId && cityId && cityId.value === 83) {
                      isValid = true;
                    } else {
                      if (countryId && cityId && countyId) {
                        isValid = true;
                      } else {
                        isValid = false;
                      }
                    }
                    if (isValid) {
                      if (countryId && cityId && cityId.value === 83) {
                        preferredLocationRef.current =
                          preferredLocationRef.current.filter(
                            (selectedItem) => selectedItem !== location
                          );
                        handleClose();
                      } else {
                        if (countryId && cityId && countyId) {
                          preferredLocationRef.current =
                            preferredLocationRef.current.filter(
                              (selectedItem) => selectedItem !== location
                            );
                          handleClose();
                        }
                      }
                    }
                  }}
                >
                  <CustomButton
                    title={"Sil"}
                    color={"white"}
                    backgroundColor={"red"}
                    hoverBackgroundColor={"rgb(231, 32, 32)"}
                    border={"none"}
                    width={{
                      xs: "420px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                      xl: "430px",
                    }}
                  />
                </form>
              )}
              <form
                onSubmit={(e) => {
                  var transletCountyId = null;
                  var transletDistrictsId = null;
                  e.preventDefault();
                  handleSubmit(onSubmit)();
                  const countryId = watch("countryId");
                  const cityId = watch("cityId");
                  const countyId = watch("countyId");
                  const districts = watch("districts");
                  if (countryId && cityId && cityId.value === 83) {
                    isValid = true;
                  } else {
                    if (countryId && cityId && countyId) {
                      isValid = true;
                    } else {
                      isValid = false;
                    }
                  }
                  if (isValid) {
                    if (countryId && cityId && cityId.value === 83) {
                      /* const updatedPreferredLocation = [
                        ...preferredLocationList,
                      ];*/
                      preferredLocationRef.current[indexItem].countryId =
                        countryId;
                      preferredLocationRef.current[indexItem].cityId = cityId;
                      if (countyId) {
                        transletCountyId = countyId.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }));
                      }
                      preferredLocationRef.current[indexItem].countyId =
                        transletCountyId;
                      if (districts) {
                        transletDistrictsId = districts.map((item) => ({
                          value: item.value,
                          label: item.label,
                        }));
                        preferredLocationRef.current[indexItem].districts =
                          transletDistrictsId;
                      } else {
                        preferredLocationRef.current[indexItem].districts = [];
                      }
                      preferredLocationRef.current[indexItem].lat =
                        lat && lat.toString();
                      preferredLocationRef.current[indexItem].lng =
                        lng && lng.toString();
                    } else {
                      if (countryId && cityId && countyId) {
                        preferredLocationRef.current[indexItem].countryId =
                          countryId;
                        preferredLocationRef.current[indexItem].cityId = cityId;
                        if (countyId) {
                          transletCountyId = countyId.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }));
                        }
                        preferredLocationRef.current[indexItem].countyId =
                          transletCountyId;
                        if (districts) {
                          transletDistrictsId = districts.map((item) => ({
                            value: item.value,
                            label: item.label,
                          }));
                          preferredLocationRef.current[indexItem].districts =
                            transletDistrictsId;
                        } else {
                          preferredLocationRef.current[indexItem].districts =
                            [];
                        }
                        preferredLocationRef.current[indexItem].lat =
                          lat && lat.toString();
                        preferredLocationRef.current[indexItem].lng =
                          lng && lng.toString();
                      }
                    }
                    handleClose(); // Close the modal after form submission
                  } else {
                  }
                }}
              >
                <CustomButton
                  title={"Kaydet"}
                  width={{
                      xs: "420px",
                      sm: "430px",
                      md: "430px",
                      lg: "430px",
                      xl: "430px",
                    }}
                />
              </form>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default EditPreferredLocationModal;
