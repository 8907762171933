import React from "react";
import { Box, Typography,Tooltip } from "@mui/material";

const GameCard = () => {
  return (
    <Tooltip
    title=""
    arrow
    sx={{
      "&:hover": {
        color: "white",
      },
    }}
  >
    <Typography
      component="span"
      sx={{
        position: "absolute",
        top: {
          xs: "40px",
          sm: "40px",
          md: "40px",
          lg: "60px",
          xl: "60px",
        },

        right: {
          xs: "5px",
          sm: "5px",
          md: "5px",
          lg: "5px",
          xl: "5px",
        },
        backgroundColor: "#ff4757",
        color: "white",
        padding: {
          xs: "2px 15px",
          sm: "2px 15px",
          md: "2px 15px",
          lg: "2px 15px",
          xl: "2px 15px",
        },
        fontSize: { xs: "10px", md: "12px" },
        transform: "rotate(45deg)",
        transformOrigin: "top right",
        zIndex: 10,
        textTransform: "uppercase",
        borderRadius: "10px",
        fontWeight: 500,
      }}
    >
      Sınırsız
    </Typography>
    </Tooltip>
  );
};

export default GameCard;
