import React from "react";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";
import PageInfoTitle from "../../../../../../../../widgets/PageInfoTitle";
import { Box, Typography } from "@mui/material";
import ProfileMatchProgress from "../../../../../../../../widgets/components/ProfileMatchProgress";

const WhiteInformation = ({
  cardStyle,
  labelStyle,
  inputStyle,
  img,
  whiteMatchDetail,
  matchPoint,
}) => {
  return (
    <div>
      <>
        <div
          class=""
          style={{
            margin: "0px",
            padding: "0px",
          }}
        >
          {whiteMatchDetail && whiteMatchDetail.linkedinProfile && (
            <a
              href={whiteMatchDetail && whiteMatchDetail.linkedinProfile}
              style={{
                position: "absolute",
                top: "20px",
                left: "20px",
                backgroundColor: "#0073b1",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "4px",
              }}
            >
              <i
                class="fa-brands fa-linkedin"
                style={{
                  color: "white",
                  margin: "5px",
                  fontSize: "15px",
                }}
              ></i>
            </a>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "3%",
              marginBottom: "0px",
            }}
          >

            {whiteMatchDetail && whiteMatchDetail.profileImageUrl !== null ? (
              <div>
                <ProfileMatchProgress img={img && img} progress={whiteMatchDetail && whiteMatchDetail.matchPoint} />
              </div>

            ) : (
              <div style={{
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                border: "4px solid orange",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>

                <i
                  class="fa-regular fa-user"
                  style={{
                    fontSize: "30px",
                  }}
                ></i>
              </div>
            )}

          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#001542",
                  marginTop: "5px",
                  marginBottom: "0px",
                  padding: "0px",
                }}
              >
                {whiteMatchDetail && whiteMatchDetail.candidateName}
              </a>
              {whiteMatchDetail && whiteMatchDetail.email && (
                <a>
                  <i class="fa-solid fa-envelope"></i>{" "}
                  {whiteMatchDetail && whiteMatchDetail.email}
                </a>
              )}
            </div>
          </div>
          <div
            style={
              whiteMatchDetail && whiteMatchDetail.email == null
                ? {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  color: "#001542",
                  width: "100%",
                }
                : {
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "20px",
                  width: "100%",
                }
            }
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                width: "30%",
              }}
            >
              <a
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#001542",
                  marginTop: "5px",
                  marginBottom: "0px",
                  padding: "0px",
                }}
              >
                Yaş
              </a>
              <a
                style={{
                  fontSize: "15px",
                  fontWeight: 400,
                  color:"#001542",

                  marginTop: "5px",
                  marginBottom: "0px",
                  padding: "0px",
                }}
              >
                {whiteMatchDetail && whiteMatchDetail.birthDate}
              </a>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                width: "auto",
                textAlign: "center",
              }}
            >
              <a
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#001542",
                  marginTop: "0px",
                  padding: "0px",
                  width: "100%",
                }}
              >
                {whiteMatchDetail &&
                  whiteMatchDetail.pozitionMatchDetailCandidate}
              </a>
              <a
                style={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: "#001542",
                  marginTop: "0px",
                  padding: "0px",
                  width: "100%",
                }}
              >
                {whiteMatchDetail && whiteMatchDetail.mobilephone}
              </a>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "30%",
              }}
            >
              <a
                style={{
                  fontSize: "14px",
                  fontWeight: 600,
                  color: "#001542",
                  marginTop: "5px",
                  marginBottom: "0px",
                  padding: "0px",
                }}
              >
                Toplam Deneyim
              </a>
              <a
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color:"#001542",
                  marginTop: "5px",
                  marginBottom: "0px",
                  padding: "0px",
                }}
              >
                {whiteMatchDetail &&
                  whiteMatchDetail.yearofexperienceWhiteMatch === 0.0 ? (
                  <p>Tecrübesiz</p>
                ) : (
                  whiteMatchDetail &&
                  whiteMatchDetail.yearofexperienceWhiteMatch &&
                  whiteMatchDetail && (
                    <p>
                      {whiteMatchDetail &&
                        whiteMatchDetail.yearofexperienceWhiteMatch}{" "}
                      Yıl
                    </p>
                  )
                )}
              </a>
            </div>
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {(whiteMatchDetail && whiteMatchDetail.aboutNote === null) ||
              (whiteMatchDetail && whiteMatchDetail.aboutNote === "") ? (
              <DividerDotted />
            ) : (
              <div></div>
            )}

            {whiteMatchDetail && whiteMatchDetail.aboutNote && (
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    margin: "0px 0px 5px 0px",
                  }}
                >
                  <PageInfoTitle title={"Hakkında"} bottomPadding={"0px"} />
                </div>
                <Box
                  sx={{
                    border: "2px dotted #fdc303",
                    borderRadius: "8px",
                    padding: "10px",
                    backgroundColor: "#F7F7F7",
                    margin: "0px 30px 15px 15px",
                  }}
                >
                  {
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "12px",
                          sm: "14px",
                          md: "14px",
                          lg: "14px",
                          xl: "14px",
                        },
                        fontWeight: 450,
                        color: {
                          xs: "#001542",
                          sm: "#001542",
                          md: "#001542",
                          lg: "#001542",
                          xl: "#001542",
                        },
                      }}
                    >
                      {" "}
                      {whiteMatchDetail && whiteMatchDetail.aboutNote}{" "}
                    </Typography>
                  }
                </Box>
              </div>
            )}
          </div>
          <div
            class="card-header"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginTop: "0px",
              padding: "0px",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <h5
              class="card-title"
              style={{
                fontSize: "17px",
              }}
            >
              Kişisel Bilgileri
            </h5>
          </div>
          <div
            class="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "10px",
            }}
          >
            <CustomPreview
              name={"Uyruk"}
              value={whiteMatchDetail && whiteMatchDetail.nationalityName}
              matchPointStatus={
                matchPoint &&
                matchPoint.isNationMatched &&
                matchPoint.isNationMatched
              }
            />{" "}
            <CustomPreview
              name={"Cinsiyet"}
              value={whiteMatchDetail && whiteMatchDetail.genderName}
              matchPointStatus={
                matchPoint &&
                matchPoint.isGenderMatched &&
                matchPoint.isGenderMatched
              }
            />
            {whiteMatchDetail && whiteMatchDetail.gender == 1 ? (
              <div></div>
            ) : (
              <CustomPreview
                name={"Askerlik Durumu"}
                value={whiteMatchDetail && whiteMatchDetail.militaryStatusName}
                matchPointStatus={
                  matchPoint &&
                  matchPoint.isMilitaryStatusMatched &&
                  matchPoint.isMilitaryStatusMatched
                }
              />
            )}
            <CustomPreview
              name={"Medeni Hal"}
              value={whiteMatchDetail && whiteMatchDetail.maritalStatusName}
              matchPointStatus={
                matchPoint &&
                matchPoint.isMaritalStatusMatched &&
                matchPoint.isMaritalStatusMatched
              }
            />
            <CustomPreview
              name={"Sigara Kullanımı"}
              value={whiteMatchDetail && whiteMatchDetail.isSmokingName}
              matchPointStatus={
                matchPoint &&
                matchPoint.isSmokeMatched &&
                matchPoint.isSmokeMatched
              }
            />
            <CustomPreview
              name={"Sürücü Belgesi"}
              value={whiteMatchDetail && whiteMatchDetail.driverLicenseName}
              matchPointStatus={
                matchPoint &&
                matchPoint.isDrivingLicenseTypeMatched &&
                matchPoint.isDrivingLicenseTypeMatched
              }
            />
            {(whiteMatchDetail && whiteMatchDetail.driverLicensesId === 2) ||
              (whiteMatchDetail && whiteMatchDetail.driverLicensesId === null) ||
              (whiteMatchDetail && whiteMatchDetail.driverLicensesId === 0) ? (
              <div></div>
            ) : (
              <CustomPreview
                name={"Sürücü Belgesi Türü"}
                value={
                  whiteMatchDetail &&
                  whiteMatchDetail.driverLicenseTypeName &&
                  whiteMatchDetail.driverLicenseTypeName
                    .map((val) => val.drivingLicenceTypeName)
                    .join(", ")
                }
                matchPointStatus={
                  matchPoint &&
                  matchPoint.isDrivingLicenseTypeMatched &&
                  matchPoint.isDrivingLicenseTypeMatched
                }
              />
            )}
            <CustomPreview
              name={"Engellilik Durumu"}
              value={whiteMatchDetail && whiteMatchDetail.disabilityName}
            />{" "}
            {(whiteMatchDetail && whiteMatchDetail.disabilityId == 1) ||
              (whiteMatchDetail && whiteMatchDetail.disabilityId == 0) ? (
              <div></div>
            ) : (
              <CustomPreview
                name={"Engellilik Seviyesi"}
                value={
                  whiteMatchDetail && whiteMatchDetail.disabilityDegreeName
                }
              />
            )}
            {whiteMatchDetail &&
              whiteMatchDetail.programs &&
              whiteMatchDetail.programs.length > 0 ? (
              <CustomPreview
                name={"Programlar"}
                value={
                  whiteMatchDetail &&
                  whiteMatchDetail.programs &&
                  whiteMatchDetail.programs
                    .map((val) => val.programName)
                    .join(", ")
                }
                matchPointStatus={
                  matchPoint &&
                  matchPoint.isProgramsMatched &&
                  matchPoint.isProgramsMatched
                }
              />
            ) : (
              <div></div>
            )}
            {whiteMatchDetail &&
              whiteMatchDetail.certificates &&
              whiteMatchDetail.certificates.length > 0 ? (
              <CustomPreview
                name={"Sertifikalar"}
                value={
                  whiteMatchDetail &&
                  whiteMatchDetail.certificates &&
                  whiteMatchDetail.certificates
                    .map((val) => val.certificateName)
                    .join(", ")
                }
                matchPointStatus={
                  matchPoint &&
                  matchPoint.isCertificatesMatched &&
                  matchPoint.isCertificatesMatched
                }
              />
            ) : (
              <div></div>
            )}
            {whiteMatchDetail &&
              whiteMatchDetail.competencies &&
              whiteMatchDetail.competencies.length > 0 ? (
              <CustomPreview
                name={"Yetkinlikler"}
                value={
                  whiteMatchDetail &&
                  whiteMatchDetail.competencies &&
                  whiteMatchDetail.competencies
                    .map((val) => val.competenceName)
                    .join(", ")
                }
                matchPointStatus={
                  matchPoint &&
                  matchPoint.isCompetencesMatched &&
                  matchPoint.isCompetencesMatched
                }
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
        <DividerDotted />
      </>
    </div>
  );
};

export default WhiteInformation;
