import React, { Fragment, useState, useEffect } from "react";
import { useSelector, connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import "../../../../css/bitoniscss/packages/buyPage.css"; //login csslarımiz
import { useForm, Controller } from "react-hook-form";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { Form } from "react-bootstrap";
import { Link } from "@material-ui/core";
import { DashboardAction, DropListAction } from "../../../../action";
import { ToastContainer, toast } from "react-toastify";
import CustomDesign from "../../../widgets/CustomDesign";
import BitonisSingleDropdown from "../../../widgets/BitonisSingleDropdown";
import useIsMobile from "../../../hooks/useIsMobile";
import { DashboardServices, ProfileDetailServices } from "../../../../services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DOMPurify from "dompurify";
import SignalService from "./SignalRService";
import { HubConnection } from "@microsoft/signalr";
import SmsPacketModal from "./SmsPacketModal";

const BuyPackets = (props) => {
  const [html, setHtml] = useState(null);
  const history = useHistory();
  const isMobile = useIsMobile();
  const packetDetail = useSelector((state) => state.selectPacket.packetDetail);
  const dispatch = useDispatch();
  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  useEffect(() => {
    if (!props.companyList.success) {
      dispatch(DropListAction.companyMainList());
    }
  }, []);
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    control,
    isValid,
    watch,
    formState: { errors },
  } = useForm();

  const [expreiedList, setExpreiedlist] = useState([
    {
      name: "Ocak",
      id: "01",
    },
    {
      name: "Mart",
      id: "03",
    },
    {
      name: "Nisan",
      id: "04",
    },
    {
      name: "Mayıs",
      id: "05",
    },
    {
      name: "Haziran",
      id: "06",
    },
    {
      name: "Temmuz",
      id: "07",
    },
    {
      name: "Ağustos",
      id: "08",
    },
    {
      name: "Eylül",
      id: "09",
    },
    {
      name: "Ekim",
      id: "10",
    },
    {
      name: "Kasım",
      id: "11",
    },
    {
      name: "Aralık",
      id: "12",
    },
  ]);
  const [expreiedYearList, setExpreiedYearList] = useState([
    {
      name: "2021",
      id: "21",
    },
    {
      name: "2022",
      id: "22",
    },
    {
      name: "2023",
      id: "23",
    },
    {
      name: "2024",
      id: "24",
    },
    {
      name: "2025",
      id: "25",
    },
    {
      name: "2026",
      id: "26",
    },
    {
      name: "2027",
      id: "27",
    },
    {
      name: "2028",
      id: "28",
    },
    {
      name: "2029",
      id: "29",
    },
    {
      name: "2030",
      id: "30",
    },
  ]);
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [month, setMonth] = useState("");
  const [expiry, setExpiry] = useState("");
  const [cvc, setCvc] = useState("");
  const [focus, setFocus] = useState("");
  const [sanitizedUrl, setSanitizedUrl] = useState(null);
  //--------------------------------------------------------------------------

  const [numberError, setNumberError] = useState(false);
  const [IyzicoError, setIyzicoError] = useState(false);
  const [iyzicoCkeck, setIyzicoCkeck] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [expiryError, setExpiryError] = useState(false);
  const [monthError, setMonthError] = useState(false);
  const [cvcError, setCvcError] = useState(false);
  const [expiryYearError, setExpiryYearError] = useState(false);
  const [cardExpiry, setCardExpiry] = useState("");
  const [price, setPrice] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const [couponCodeValue, setCouponCodeValue] = useState(null);

  const location = useLocation(); // useLocation hook'u ile location alınır

  const { dataMap } = location.state;

  const id = dataMap.get("planIdSend");
  const planItem = dataMap.get("planItem");

  useEffect(() => {
    if (planItem) {
      setPrice(planItem && planItem.price);
    }
  }, [planItem]);

  // const { id } = useParams();

  const [isPaymentSucces, setIsPaymentSucces] = useState(false);
  const [succes, setSucces] = useState("Ödeme Başarılı");

  // SignalService sınıfını başlatın

  const [hubConnection, setHubConnection] = useState(null);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [succesPay, setSuccesPay] = useState(null);
  const signalService = new SignalService();
  signalService.startConnection();

  const paymentResultHandler = (res) => {
    handleClose();
    if (res.status === "success") {
      setSuccesPay(true);
      setIsPaymentSuccess(true);

      toast.success(
        "Teşekkür ederiz! Ödemeniz başarılı bir şekilde gerçekleştirilmiştir.",
        {
          autoClose: 3000,
          onClose: () => {
            setTimeout(() => {
            history.push("/activepackets");
            }, 100);
          },
        }
      );
    } else if (res.status === "failure") {
      setIsPaymentSuccess(false);
      toast.error("Ödeme İşlemi Sırasında Bir Hata Oluştu", {
        autoClose: 3000,
        onClose: () => {
          setTimeout(() => {
             window.location.href = window.location.href;
          }, 100);
        },
      });
    } else {
         setIsPaymentSuccess(false);
         toast.error("Ödeme İşlemi Sırasında Bir Hata Oluştu", {
           autoClose: 3000,
           onClose: () => {
             setTimeout(() => {
               window.location.href = window.location.href;
             }, 100);
           },
         });
    }
  };
  signalService.paymentResult(paymentResultHandler);

  async function buyPacket(form) {
    if (
      form.cardName.length === 0 ||
      form.cardName.trim().split(" ").length === 1
    ) {
      setNameError(true);
    } else {
      setNameError(false);

      var sendData = {
        companyId: props.companyList.companyList.id,
        employerId: props.companyList.companyList.employerId,
        paymentPlanId: id,
        cardHolderName: form.cardName,
        cardNumber: form.cardNumber,
        cardExpireMonth: form.month.value,
        cardExpireYear: form.expiry.value,
        cardCVC: form.cvc,
        paymentChannel: 1,
        couponCode:
          couponCodeValue == undefined || couponCodeValue == null
            ? ""
            : couponCodeValue,
      };
      DashboardServices.payment(sendData).then(
        (payment) => {
          if (payment && payment.succeeded) {
            signalService.registerTransactionId(payment.data.conversationId);
            const blob = new Blob([payment.data.htmlContent], {
              type: "text/html",
            });
            var obj = URL.createObjectURL(blob);
            const createSanitizedUrl = DOMPurify.sanitize(obj);
            setSanitizedUrl(createSanitizedUrl);
            setShow(true);
          } else {
            toast.error("Ödeme İşlemi Sırasında Bir Hata Oluştu", {
              autoClose: 3000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  const calculateDiscountedPrice = (fiyat, yuzde) => {
    return fiyat - (fiyat * yuzde) / 100;
  };

  function checkCoupon(coupon) {
    var sendCoupon = {
      couponCode: coupon,
    };
  
    ProfileDetailServices.couponCheck(sendCoupon).then(
      (couponResponse) => {
        if (couponResponse) {
          if (couponResponse && couponResponse.succeeded) {
            toast.success("Kupon Başarılı Bir Şekilde Uygulandı");
            let lastPrice;
  
            if (couponResponse.data.couponType === 1) {
              // Yüzdelik indirim
              lastPrice = calculateDiscountedPrice(
                planItem && planItem.price,
                couponResponse.data.couponAmount
              );
            } else {
              // Sabit miktar indirim
              lastPrice = planItem && planItem.price - couponResponse.data.couponAmount;
            }
  
            setPrice(lastPrice);
            setCouponCodeValue(coupon);
          } else {
            setCouponCodeValue(null);
            setPrice(planItem && planItem.price);
            toast.error(couponResponse.error.message);
          }
        }
      },
      (error) => {
        setCouponCodeValue(null);
        setPrice(planItem && planItem.price);
      }
    );
  }
  

  return (
    <Fragment>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div style={{}}>
        <div>
          <div
            style={{
              background: "white",
              width: "80%",
              borderRadius: "10px",
              marginLeft: "15%",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
              }}
            >
              <div
                style={{
                  width: isMobile ? "100%" : "50%",

                  display: "flex",
                  flexDirection: "column",
                  padding: "2%",
                }}
              >
                <h6
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    fontWeight: 600,
                    fontSize: isMobile ? "14px" : "18px",
                    color: "#001542",
                  }}
                >
                  {planItem && planItem.planName}
                  <i
                    class="fa-solid fa-circle-check"
                    style={{
                      marginLeft: "5px",
                      color: "green",
                    }}
                  ></i>
                </h6>
                <div
                  style={{
                    padding: "0px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                  }}
                >
                  {planItem.properties.map((feature, i) => (
                    <li key={i}>
                      <i
                        className="fa-solid fa-tag"
                        style={{
                          color: "#FDC303",
                          margin: "8px 5px 8px 0px",
                        }}
                      ></i>
                      <span
                        style={{
                          color: "#001542",
                          fontWeight: 400,
                          fontSize: isMobile ? "12px" : "14px",
                          marginLeft: "5px",
                        }}
                      >
                        {feature.value}
                      </span>
                    </li>
                  ))}
                </div>
              </div>
              <div
                style={{
                  width: isMobile ? "100%" : "1px",
                  height: isMobile ? "1px" : "",
                  color: "black",
                  border: "1px solid #e7e7e7",
                }}
              ></div>
              <div
                style={{
                  width: isMobile ? "100%" : "50%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "2%",
                  boxShadow: " 0 0 3px 0 #001542",
                  borderRadius: isMobile
                    ? "0px 0px 8px 8px"
                    : "0px 8px 8px 0px",
                }}
              >
                <h6
                  style={{
                    padding: "10px",
                    fontWeight: 600,
                    fontSize: isMobile ? "14px" : "18px",
                    color: "#001542",
                  }}
                >
                  Ödenecek Tutar
                </h6>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                    marginBottom: "5px",
                  }}
                >
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: 600,
                      fontSize: isMobile ? "14px" : "20px",
                      color: "orange",
                      marginBottom: isMobile ? "" : "10%",
                      padding: "8px",
                      margin: "0px",
                    }}
                  >
                    {price && price.toLocaleString("tr-TR")} TL
                  </h6>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <h6
                    style={{
                      padding: "5px",
                      fontWeight: 600,
                      fontSize: isMobile ? "14px" : "18px",
                      color: "#001542",
                    }}
                  >
                    İndirim Kodu
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="inputClass"
                        style={{
                          height: "45px",
                          fontSize: isMobile ? "13px" : "13px",
                        }}
                        placeholder="İndirim Kodu Gir"
                        name="cardName"
                        value={couponCode}
                        onChange={(val) => {
                          if (val) {
                            setCouponCode(val.target.value);
                          }
                        }}
                      ></input>
                      <div
                        onClick={() => {
                          if (
                            couponCode === undefined ||
                            couponCode === null ||
                            couponCode === ""
                          ) {
                          } else {
                            checkCoupon(couponCode);
                          }
                        }}
                        id="inputClass"
                        style={{
                          cursor: "pointer",
                          width: "50%",
                          height: "45px",
                          backgroundColor: "#001542",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          color: "white",
                          fontWeight: 400,
                          margin: "5px",
                          fontSize: isMobile ? "11px" : "12px",
                        }}
                      >
                        Uygula
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              background: "white",
              width: "80%",
              borderRadius: "10px",
              marginLeft: "15%",
              height: "100%",
              marginTop: "2%",
              padding: "5%",
              boxShadow: " 0 0 6px 0 #001542",
              marginBottom: "10px",
            }}
          >
            <h2
              style={{
                textAlign: "center",
                fontSize: isMobile ? "15px" : "20px",
                marginTop: "0px",
                paddingTop: "0px",
                margin: "0px",
                padding: "0px",
                marginBottom: "30px",
              }}
            >
              Güvenli Ödeme
            </h2>
            <form onSubmit={handleSubmit(buyPacket)}>
              <div className="row">
                <CustomDesign
                  label={"Kart Numarası"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-6 col-md-6"}
                  child={
                    <>
                      <input
                        type="text"
                        id="inputClass"
                        className="form-control"
                        placeholder="Kart Numarası"
                        name="cardNumber"
                        maxLength={16}
                        style={{
                          height: "57px",
                        }}
                        {...register("cardNumber", {
                          required: true,
                          maxLength: 16,
                          pattern: /^[0-9]{16}$/, // Sadece 16 haneli sayısal giriş
                        })}
                        onFocus={(e) => setFocus(e.target.name)}
                        onInput={(e) => {
                          // Sadece sayısal değerlerin girilmesine izin ver
                          e.target.value =
                            e.target.value &&
                            e.target.value.replace(/[^0-9]/g, "");
                        }}
                        onChange={(val) => {
                          if (val) {
                            setNumber(val.target.value);
                          }
                        }}
                      />

                      {errors.cardNumber && (
                        <div className="text-danger fs-12">
                          Kart Numarası Zorunludur
                        </div>
                      )}
                    </>
                  }
                />
                <CustomDesign
                  label={"Kart Sahibinin Adı ve Soyadı"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-6 col-md-6"}
                  child={
                    <>
                      <input
                        type="text"
                        className="form-control"
                        id="inputClass"
                        {...register("cardName", {
                          required: true,
                        })}
                        style={{
                          height: "57px",
                        }}
                        placeholder="Kart Sahibi Adı ve Soyadı"
                        name="cardName"
                        onFocus={(e) => setFocus(e.target.name)}
                        onChange={(val) => {
                          if (val) {
                            setName(val.target.value);
                            if (
                              val.target.value.length === 0 ||
                              val.target.value.trim().split(" ").length === 1
                            ) {
                              setNameError(true);
                            } else {
                              setNameError(false);
                            }
                          }
                        }}
                      ></input>
                      {errors && errors.cardName && (
                        <div className="text-danger fs-12">
                          Kart sahibinin Adı ve Soyadını Boş Olamaz
                        </div>
                      )}
                      {nameError && (
                        <div className="text-danger fs-12">
                          Lütfen Kart sahibinin Adı ve Soyadını arasında boşluk
                          olacak şekilde girin
                        </div>
                      )}
                    </>
                  }
                />
              </div>
              <div className="row">
                <CustomDesign
                  label={"Ay"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-4 col-md-4"}
                  child={
                    <BitonisSingleDropdown
                      items={
                        expreiedList &&
                        expreiedList.map((value) => {
                          return {
                            label: value.name,
                            value: value.id,
                          };
                        })
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"month"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      errorMessage={"Son kullanma tarihi boş geçilemez"}
                      placeholder="Ay Seçiniz"
                      onValueChanged={(val) => {
                        console.log(val);
                        if (val) {
                          var expiry = watch("expiry");
                          if (expiry) {
                            setCardExpiry(`${val.value}${expiry.value}`);
                          } else {
                            setCardExpiry(`${val.value}`);
                          }
                        }
                      }}
                      required={true}
                      getValues={getValues}
                    />
                  }
                />{" "}
                <CustomDesign
                  label={"Yıl"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-4 col-md-4"}
                  child={
                    <BitonisSingleDropdown
                      items={
                        expreiedYearList &&
                        expreiedYearList.map((value) => {
                          return {
                            label: value.name,
                            value: value.id,
                          };
                        })
                      }
                      register={register}
                      watch={watch}
                      companyClassName={"expiry"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      errorMessage={"Son Kullanma Tarihinde Yıl Boş Geçilemez"}
                      placeholder="Yıl Seçiniz"
                      onValueChanged={(val) => {
                        console.log(val);
                        if (val) {
                          var month = watch("month");
                          if (month) {
                            setCardExpiry(`${month.value}${val.value}`);
                          } else {
                            setCardExpiry(`${val.value}`);
                          }
                        }
                      }}
                      required={true}
                      getValues={getValues}
                    />
                  }
                />
                <CustomDesign
                  label={"CVC/CVV"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-4 col-md-4"}
                  child={
                    <>
                      <input
                        type="text"
                        className="form-control"
                        style={{
                          border: "1px solid rgb(203, 200, 200)",
                          padding: "10px",
                          marginLeft: "0px",
                          backgroundColor: "#f8f8f9",
                          borderRadius: "6px",
                          width: "100%",
                          color: "#001542",
                          fontWeight: 500,
                          fontSize: "14px",
                          height: "57px",
                        }}
                        placeholder="Cvc/Cvv"
                        {...register("cvc", {
                          required: true,
                          maxLength: 3,
                          pattern: /^[0-9]{3}$/, // Sadece 3 haneli sayısal giriş
                        })}
                        onInput={(e) => {
                          // Sadece sayısal değerlerin girilmesine izin ver
                          e.target.value =
                            e.target.value &&
                            e.target.value.replace(/[^0-9]/g, "");
                        }}
                        name="cvc"
                        maxLength="3"
                        pattern="\d*"
                        value={cvc}
                        onChange={(val) => {
                          if (val) {
                            setCvc(val.target.value);
                          }
                        }}
                      ></input>

                      {errors && errors.cvc && (
                        <div className="text-danger fs-12 mb-2">
                          CVV Boş Geçilemez
                        </div>
                      )}
                    </>
                  }
                />
                <div
                  className="row"
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <CustomDesign
                    label={""}
                    required={false}
                    isColumn={true}
                    columnClassName={"col-xs-12 col-md-12"}
                    child={
                      <div
                        style={{
                          marginLeft: "5px",
                        }}
                      >
                        {" "}
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          {...register("iyzicoCheck", {
                            required: true,
                          })}
                          style={{
                            transform: "scale(1.3)",
                          }}
                        />
                        <label
                          className="form-check-label text-light"
                          for="exampleCheck1"
                          id="aHrefcheck"
                        >
                          <Link
                            id="aHrefcheck"
                            href="https://bitonis.com/sozlesmeler/satis-sozlesmesi"
                          >
                            <label
                              className="text-underline pr-1"
                              style={{
                                display: "inline",
                                paddingRight: "3px",
                              }}
                            >
                              Ön Bilgilendirme Koşullarını
                            </label>
                            &ensp;ve&ensp;
                            <label className="text-underline" id="aHrefcheck">
                              Mesafeli Satış Sözleşmesi'
                            </label>
                            ni okudum, onaylıyorum.
                          </Link>
                        </label>
                        {errors && errors.iyzicoCheck && (
                          <div className="text-danger fs-12 mb-2">
                            Mesafeli Satış Sözleşmesini Onaylayın
                          </div>
                        )}
                      </div>
                    }
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  marginTop: "0%",
                }}
              >
                <button
                  id="inputClass"
                  type="submit"
                  style={{
                    cursor: "pointer",
                    width: "50%",
                    height: "50px",
                    backgroundColor: "#001542",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    color: "white",
                    fontWeight: 400,
                    marginTop: "5%",
                    fontSize: isMobile ? "11px" : "12px",
                  }}
                >
                  Ödemeyi Tamamla
                </button>
              </div>
            </form>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/images/logo_band_colored.svg"
                className="App-logo"
                alt="logo"
                style={{
                  marginTop: "40px",
                  marginBottom: "0px",
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  width: isMobile ? "300px" : "400px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <SmsPacketModal
        show={show}
        handleClose={handleClose}
        sanitizedUrl={sanitizedUrl}
      />
    </Fragment>
  );
};
const formatCurrency = (amount) => {
  return new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
  }).format(amount);
};
function mapState(state) {
  let payment = state.payment;
  let companyList = state.companyListMain;
  let planList = state.planList;
  return {
    payment,
    companyList,
    planList,
  };
}
export default connect(mapState)(BuyPackets);
