import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { DropListAction } from "../../../../../../../action";
import { DropListServices } from "../../../../../../../services";
import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import api from "../../../../../../../utility/Utils";
import "react-toastify/dist/ReactToastify.css";
import "../../../../../../../css/bitoniscss/modal.css";
import BitonisMultiDropdown from "../../../../../../widgets/BitonisMultiDropdown";
import BitonisSingleDropdown from "../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../widgets/CustomDesign";
import PageInfoTitle from "../../../../../../widgets/PageInfoTitle";
import { Box } from "@mui/material";
import CustomButton from "../../../../../../widgets/buttons/CustomButton";
import SelectMultiRoute from "../../../../../../widgets/components/common/SelectMultiRoute";

function AddRoutesModal({
  show,
  handleClose,
  props,
  customStyles,
  inputStyle,
}) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [selectCompanyList, setSelectCompanyList] = useState([]);
  const [semtNewList, setSemtNewList] = useState([]);
  const [showCountiesAndDistricts, setShowCountiesAndDistricts] =
    useState(true);
  const [cityList, setCityList] = useState([]);
  useEffect(() => {
    if (show) {
      setShowCountiesAndDistricts(true);
      setCityList([]);
      if (!props.countryList.success) {
        props.dispatch(DropListAction.countryList());
      }

      DropListServices.allCityList({
        countryId: 1,
      }).then(
        (cityData) => {
          if (cityData && cityData.length > 0) {
            cityData.map((value) => setCityList((prev) => [...prev, value]));
          }
        },
        (error) => {}
      );
    }
  }, [show]);
  const handleCity = (selected) => {
    if (selected.value.toString() == "83") {
      setShowCountiesAndDistricts(false);
    } else {
      setShowCountiesAndDistricts(true);
    }
    props.dispatch(DropListAction.allCountyList(selected.value));
  };

  async function getLatLngApi(adress) {
    DropListServices.handleGetLocation(adress).then((location) => {
      // alert("lat " + location.lat + " " + "lng " + location.lng);
      var latitute = location.lat;
      var longtitute = location.lng;
      setLat(latitute);
      setLng(longtitute);
    });
  }
  async function getCompanyActive() {
    setSelectCompanyList([]);
    api
      .get("employer/companies")
      .then((response) => {
        if (response.succeeded == true) {
          //   alert("response * * * " + JSON.stringify(response))
          //  setSelectCompanyList(response.data);
          response.data.map((value) => {
            if (value["isActive"].toString() === "true") {
              setSelectCompanyList((prev) => [...prev, value]);
            }
          });
        } else {
          setSelectCompanyList([]);
        }
      })
      .catch((err) => {
        setSelectCompanyList([]);
      });
  }
  useEffect(() => {
    getCompanyActive();
  }, []);
  const handleCounty = (selected) => {
    setSemtNewList([]);
    if (selected) {
      semtFGetApi(selected);
    }
  };
  function semtFGetApi(selected) {
    for (var i = 0; i < selected.length; i++) {
      DropListServices.districtList(selected[i].value).then(
        (districtList) => {
          if (
            districtList == null ||
            districtList == undefined ||
            districtList == []
          ) {
          } else {
            var semtListPre = [];
            semtListPre = districtList;

            // alert("semtListPre * * * " + JSON.stringify(semtListPre));

            for (var fetSemt in semtListPre) {
              // Aynı ID'ye sahip semt zaten listeye eklenmişse, eklemeyi atla
              if (semtNewList.some((semt) => semt.id == fetSemt.value)) {
                // print("aynı id ye sahip var* * ** * * ");
                continue;
              }
              // Aynı ID'ye sahip  listeye eklenmemişse, ekle
              setSemtNewList((prevList) => [...prevList, semtListPre[fetSemt]]);
            }
          }
        },
        (error) => {
          setSemtNewList([]);
        }
      );
    }
  }

  function addRoutes(form) {
    var sendData = {
      name: form["servisRouteName"],
      companyId: form["companyName"]["value"],
      companyPrefLocationReq: {
        countryId: form["countryId"]["value"],
        cityId: form["cityId"]["value"],
        counties:
          form["countyId"] == [] ||
          form["countyId"] == undefined ||
          form["countyId"] == ""
            ? []
            : form["countyId"].map((count) =>
                parseInt(count["value"].toString())
              ),
        districts:
          form["districts"].toString() == "[]" ||
          form["districts"] == undefined ||
          form["districts"] == ""
            ? []
            : form["districts"].map((count) =>
                parseInt(count["value"].toString())
              ),
        latitude: lat.toString(),
        longitude: lng.toString(),
      },
    };
    addCompaniesRoute(sendData);
  }
  async function addCompaniesRoute(routeData) {
    setSelectCompanyList([]);
    api
      .post("company/preferredlocation", routeData)
      .then((response) => {
        if (response.succeeded == true) {
          handleClose(true);
          toast.success(response.result.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        } else {
          toast.info(response.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {
                window.location.href = window.location.href;
              }, 100);
            },
          });
        }
      })
      .catch((err) => {
        toast.info("Hata Oluştu", {
          autoClose: 2000,
          onClose: () => {
            setTimeout(() => {
              window.location.href = window.location.href;
            }, 100);
          },
        });
      });
  }
  var countySelected = watch("countyId");
  return (
    <>
      <div>
        <Modal
          className="fade bd-example-modal-lg"
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              borderBottom: "none",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <PageInfoTitle title={"Servis Güzergahı Ekle"} />
          </Modal.Header>
          <Modal.Body
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              marginBottom: "0px",
              paddingBottom: "20px",
            }}
          >
            <form onSubmit={handleSubmit(addRoutes)}>
              <div className="row">
                <CustomDesign
                  label={"Firma Seç"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-6 col-md-6"}
                  child={
                    selectCompanyList && (
                      <BitonisSingleDropdown
                        items={
                          selectCompanyList &&
                          selectCompanyList.map(({ id, name, code }) => ({
                            value: id,
                            label: name,
                            code: code,
                          }))
                        }
                        register={register}
                        watch={watch}
                        companyClassName={"companyName"}
                        control={control}
                        setValue={setValue}
                        errors={errors}
                        placeholder="Firma Seç"
                        onValueChanged={(e) => {
                          console.log(e);
                          if (e) {
                          }
                        }}
                        required={true}
                        getValues={getValues}
                        errorMessage={"Bu alan Boş Olamaz"}
                      />
                    )
                  }
                />

                <CustomDesign
                  label={"Güzergah Başlığı"}
                  required={true}
                  isColumn={true}
                  columnClassName={"col-xs-6 col-md-6"}
                  child={
                    <>
                      {" "}
                      <input
                        type="text"
                        className="form-control"
                        id="inputdropdownmodal"
                        rules={{ required: true }}
                        name="servisRouteName"
                        {...register("servisRouteName", { required: true })}
                        placeholder="Güzergah Başlığı"
                      ></input>
                      {errors.servisRouteName && (
                        <div className="text-danger fs-12">
                          Bu alan Boş Olamaz
                        </div>
                      )}
                    </>
                  }
                />
              </div>
              <SelectMultiRoute
                props={props}
                register={register}
                watch={watch}
                control={control}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                getLatLngApi={getLatLngApi}
                preview={false}
                setSemtNewList={setSemtNewList}
                semtNewList={semtNewList}
                handleCity={handleCity}
                handleCounty={handleCounty}
                showCountiesAndDistricts={showCountiesAndDistricts}
                setShowCountiesAndDistricts={setShowCountiesAndDistricts}
                cityList={cityList}
                setCityList={setCityList}
              />

            </form>
          </Modal.Body>
          <Modal.Footer
            style={{
              borderTop: "none",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              borderTop: "none",
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            <form onSubmit={handleSubmit(addRoutes)}>
            <Box
                      sx={{
                        margin: "0px",
                        padding: {
                          xs: "5px",
                          sm: "5px",
                          md: "5px",
                          lg: "5px",
                          xl: "5px",
                        },
                      }}
                    >
                      <CustomButton
                        title={"Kaydet"}
                        width={{
                          xs: "400px",
                          sm: "350px",
                          md: "350px",
                          lg: "350px",
                          xl: "350px",
                        }}
                      />
                    </Box>
            </form>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AddRoutesModal;


