import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import CustomButton from "../../../../../../../widgets/buttons/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const MatchDetailInfoModal = ({ show, handleClose }) => {
    const history = useHistory();
    return (
        <div
            onClick={(e) => {
                //bu kısım kesinlikle olmalı
                e.stopPropagation();
                handleClose();
            }}
        >
            <>
                <div >
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static" // Modal'ın dışına tıklanarak kapanmasını engeller
                        keyboard={false} // Escape tuşuna basarak kapanmasını engeller
                        aria-labelledby="contained-modal-title-vcenter"
                        centered

                    >
                        <Modal.Header
                            closeButton
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={{ borderBottom: "0px" }}
                        ></Modal.Header>

                        <Modal.Body
                            style={{
                                marginTop: "0px",
                                paddingTop: "0px",
                                margin: "0px",
                                padding: "0px",
                                marginTop: "15px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignContent: "center",
                                alignItems: "center",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: "60px",
                                        sm: "60px",
                                        md: "60px",
                                        lg: "60px",
                                        xl: "70px",
                                    },
                                    fontWeight: 600,
                                }}
                            >
                                🔐

                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: {
                                        xs: "20px",
                                        sm: "22px",
                                        md: "22px",
                                        lg: "22px",
                                        xl: "22px",
                                    },
                                    fontWeight: "550",
                                    color: "#001542",
                                    textAlign: "center"
                                }}
                            >
                                Aday Bilgilerine Erişim
                            </Typography>
                            <Box sx={{
                                margin: "0px 60px 20px 60px",
                                textAlign: "center",
                                marginTop: "10px"
                            }}>

                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: "15px",
                                            sm: "15px",
                                            md: "15px",
                                            lg: "15px",
                                            xl: "15px",
                                        },

                                        color: "#001542",
                                        marginBottom: "5px"


                                    }}
                                >
                                    Bu adayın kişisel bilgilerine erişim sağlamak için bir paket
                                    satın almanız gerekiyor.
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: "15px",
                                            sm: "15px",
                                            md: "15px",
                                            lg: "15px",
                                            xl: "15px",
                                        },

                                        color: "#001542", textAlign: "center"
                                    }}
                                >
                                    Satın aldığınız paketle adayın iletişim bilgilerini
                                    görüntüleyebilir, Video CV'lerini izleyebilir ve Video Konferans
                                    düzenleyebilirsiniz. Ayrıca, ilgili talebinizle eşleşen tüm
                                    adayların bilgilerine de erişim sağlayabilirsiniz.
                                </Typography>

                            </Box>
                            <Box sx={{
                                margin: "0px 10px 25px 10px"
                            }}>
                                <CustomButton
                                    showShadow={true}
                                    hoverBackgroundColor={"#CD5C08"}
                                    backgroundColor={"orange"}
                                    height={55}
                                    onTap={
                                        () => {
                                            handleClose();
                                            history.push("/activepackets")


                                        }
                                    }
                                    title={"Paket Satın Al"}
                                    width={{
                                        xs: "300px",
                                        sm: "300px",
                                        md: "300px",
                                        lg: "350px",
                                        xl: "350px",
                                    }}
                                />
                            </Box>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        </div>
    );
};

export default MatchDetailInfoModal;
