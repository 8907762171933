import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import { connect, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DemandsServices } from "../../../../../../services";
import CustomButton from "../../../../../widgets/buttons/CustomButton";

function AddPositionNotFoundModal({ show, handleClose, candidateType }) {
  function onHide() {
    handleClose(false);
  }

  var props = {
    show,
    onHide,
    candidateType,
  };

  return (
    <>
      <div>
        <MyVerticallyCenteredModal props={props} />
      </div>
    </>
  );
}

function MyVerticallyCenteredModal({ props }) {
  const { show, onHide, candidateType } = props;
  const [inputText, setInputText] = useState(null);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    if (show) {
      setInputText("");
    }
  }, [show]);
  function addSkills(form) {
    if (inputText === undefined || inputText === null || inputText === "") {
    } else {
      var sendData =
        candidateType === "blue"
          ? "Blue Position " + inputText
          : "White Position " + inputText;

      DemandsServices.addNewPcc(sendData).then(
        (response) => {
          if (response) {
            if (response.succeeded === true) {
              toast.success(response.result.message, {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {}, 100);
                },
              });
            } else {
              toast.info(response.error.message, {
                autoClose: 2000,
                onClose: () => {
                  setTimeout(() => {}, 100);
                },
              });
            }
          }
        },
        (error) => {}
      );
    }

    onHide();
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="full-screen-modal"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      }}
      backdrop="static"
    >
      {" "}
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title
          aria-labelledby="contained-modal-title-vcenter"
          style={{
            width: "100%",
            textAlign: "center",
            margin: "0 auto",
          }}
        >
          Pozisyon Ekle
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              backgroundColor: "#F7F7F7",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              borderRadius: "8px",
              color: "#001542",
              fontSize: "13px",
            }}
          >
            <div
              style={{
                padding: "10px",
                color: "#fdc303",
                fontSize: "22px",
              }}
            >
              📌
            </div>

            <TopTitle />
          </div>
          <TopContent />
          <textarea
            type="text"
            name={`inputText`}
            key={`inputText`}
            value={inputText}
            onChange={(val) => {
              setInputText(val.target.value);
            }}
            id="noter-text-area-active"
            className="form-control"
            placeholder="Mesajınız..."
            style={{
              backgroundColor: "rgb(237, 237, 239)",
              border: "1px solid grey",
              fontSize: "14px",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          />{" "}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
          borderTop: "none",
          marginTop: "0px",
          paddingTop: "0px",
        }}
      >
        <CustomButton
          onTap={addSkills}
          height={50}
          title={"Gönder"}
          width={{
            xs: "300px",
            sm: "300px",
            md: "350px",
            lg: "350px",
            xl: "350px",
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

const TopContent = () => {
  return (
    <div>
      <div
        style={{
          color: "#001542",
          fontWeight: "600px",
          fontSize: "14px",
          marginBottom: "5px",
          marginTop: "15px",
        }}
      >
        Pozisyon Geri Bildirim Bilgisi
      </div>
      <div
        style={{
          color: "#001542",
          fontWeight: "600px",
          fontSize: "14px",
          marginBottom: "10px",
        }}
      >
        Bize Ulaşın ve Pozisyonu Ekleyelim!
      </div>
    </div>
  );
};

const TopTitle = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: "10px",
        }}
      >
        Bitoniş'te aradığınız pozisyonu bulamadınız mı? Size daha iyi hizmet
        verebilmemiz için, bulamadığınız pozisyonları mesaj bölümüne yazarak
        bize iletin. Bitoniş ekibi, sizin için ekleyip gerekli bilgileri
        sağlayacaktır.{" "}
      </div>
    </div>
  );
};
export default AddPositionNotFoundModal;
