import React, { useEffect, useState } from "react";
import demandNotFound from "../../../../../../../images/svg/demandNotFound.svg";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import { DemandsServices } from "../../../../../../../services";
import WhiteDemandItem from "./widgets/WhiteDemandItem";
import PopupLongMenu from "../../../../../../widgets/PopupLongMenu";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import DemandCopyApproved from "./Modal/DemandCopyApproved";
import DemandNotFound from "./Common/DemandNotFound";
import PaginationPage from "../../../../../../widgets/PaginationPage";
import "../../../../../../../css/bitoniscss/demand/demandCopy.css";
import LoadingDotsAnimation from "../../../../../../widgets/LoadingDotsAnimation";
import { JobConstants } from "../../../../../../../constants";
import { useDispatch } from "react-redux";
import FilterWhiteModal from "./Modal/FilterWhiteModal";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { DoneOutline, DoneOutlineRounded } from "@mui/icons-material";
import AvatarGlow from "../../../../../../widgets/components/AvatarGlow";
import Tooltip from "@mui/material/Tooltip";
import InfoDemandAllShow from "../../../../../../widgets/components/common/InfoDemandAllShow";
import PackageCandidateDemandUsageScreen from "./PackageCandidateDemandUsageScreen";

const WhiteAllShow = () => {
  const dispatch = useDispatch();
  const [whiteDemandList, setWhiteDemandList] = useState(null);
  const [show, setShow] = useState(null);
  const [search, setSearch] = useState("");
  const [showDemandCopy, setShowDemandCopy] = useState(null);
  const [demandItem, setDemandItem] = useState(null);
  const [showPackageUseScreen, setShowPackageUseScreen] = useState(null);

  function handlePackageUseScreenClose() {
    setShowPackageUseScreen(false);
  }
  function handleCloseDemandCopy() {
    setShowDemandCopy(false);
  }
  var demandFilterList = [
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState([
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ]);
  useEffect(() => {
    getAllShowWhite(null, true);
  }, []);

  function getAllShowWhite(isActive, loadingPage) {
    var sendData = { isActive: isActive, positionType: 2 };
    DemandsServices.demandsWhite(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setWhiteDemandList([]);
          demand.data.map((value) => {
            setWhiteDemandList((prev) => [...prev, value]);
          });
          if (loadingPage && loadingPage === true) {
          } else {
            if (isActive == null) {
              setPage(null);
            }
          }
        }
      },
      (error) => {}
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  function handleClose() {
    setShow(false);
  }
  function copyDemandItem(job) {
    var sendData = { jobId: parseInt(job.jobId.toString()) };
    DemandsServices.copyDemandWhite(sendData).then(
      (copyDemand) => {
        setShowDemandCopy(false);
        if (copyDemand && copyDemand.succeeded === true) {
          getAllShowWhite(null);
          toast.success("Talep Başarılı bir şekilde kopyalandı", {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        } else {
          toast.info(copyDemand.error.message, {
            autoClose: 2000,
            onClose: () => {
              setTimeout(() => {}, 100);
            },
          });
        }
      },
      (error) => {}
    );
  }
  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    whiteDemandList && page === null
      ? whiteDemandList
      : whiteDemandList &&
        whiteDemandList.slice((page - 1) * pageSize, page * pageSize);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        {whiteDemandList && whiteDemandList.length >= 0 && (
          <Tooltip
            title="Tıklayın"
            arrow
            sx={{
              "&:hover": {
                color: "white", // Hover sırasında metin rengi değiştir
              },
            }}
          >
            <Fab
              onClick={() => {
                setShowPackageUseScreen(true);
              }}
              sx={{
                position: "fixed", // absolute yerine fixed kullanıldı
                bottom: (theme) => theme.spacing(3), // Alt köşeye sabitler
                right: {
                  xs: "0px",
                  sm: "0px",
                  md: "20px",
                  lg: "35px",
                  xl: "35px",
                },
                backgroundColor: "white",
                border: "1px dotted #001542",
              }}
              color="transparent"
            >
              <AvatarGlow
                initialRadius={25}
                valueMaxScale={4}
                glowColor="#001542"
                waveDuration={3}
                startColor="#001542"
                endColor="#001542"
                width={50}
                height={50}
              >
                <Fab
                  sx={{
                    position: "relative", // İç bileşende position relative kullanımı
                    backgroundColor: "white",
                    width: "50px",
                    height: "50px",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#001542",
                    },
                  }}
                  color="primary"
                >
                  <DoneOutlineRounded
                    sx={{
                      color: "#001542",
                      "&:hover": {
                        color: "#001542",
                      },
                    }}
                  />
                </Fab>
              </AvatarGlow>
            </Fab>
          </Tooltip>
        )}
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="Pozisyona göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />

            <Button
              variant="outline"
              onClick={() => {
                setShow(true);
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid #F5F5F5",
                marginLeft: "30px",
                backgroundColor: "white",
              }}
            >
              <i
                class="fa-solid fa-arrow-down-wide-short"
                style={{
                  color: "#fdc303",
                  fontSize: "20px",
                }}
              ></i>
              <a
                style={{
                  color: "#001542",
                  fontWeight: "600px",
                  margin: "5px",
                }}
              >
                Filtrele
              </a>
            </Button>
          </InputGroup>
        </div>
        {whiteDemandList === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : whiteDemandList && paginatedList && paginatedList.length > 0 ? (
          <div>
            <InfoDemandAllShow
              onTap={(val) => {
                setShowPackageUseScreen(true);
              }}
            />
            {paginatedList &&
              paginatedList.map((demand, index) => {
                return paginatedList &&
                  paginatedList[index].positionName
                    .toLowerCase()
                    .includes(search.toLowerCase()) ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <WhiteDemandItem
                      onTap={() => {
                        const dataMap = new Map();
                        dataMap.set("jobId", demand.jobId);
                        dataMap.set("isActive", Boolean(demand.isActive));
                        dispatch({ type: JobConstants.GET_CLEAR });
                        history.push({
                          pathname: "/demands/WhiteDemandPreview/",
                          state: { dataMap },
                        });
                      }}
                      demand={demand}
                      demandCopyOnChanged={(value) => {
                        if (value && value == true) {
                          //talep kopyalandığında bu kısım çalışır.
                          getAllShowWhite(null);
                        }
                      }}
                    />
                    <div onClick={() => {}} id="demandCopy">
                      <div>
                        <PopupLongMenu
                          options={[
                            {
                              title: "Talep Kopyala",
                              id: 0,
                            },
                          ]}
                          onChanged={(value) => {
                            if (value) {
                              if (value.onTapStatus == true && value.id === 0) {
                                setShowDemandCopy(true);
                                setDemandItem(demand);
                              }
                            }
                          }}
                          icon={
                            <i className="fa-solid fa-ellipsis-vertical menu-icon-demand"></i>
                          }
                          iconItem={<i className="fa-regular fa-copy"></i>}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                );
              })}
          </div>
        ) : (
          <div>
            <DemandNotFound candidateType={"white"} />
          </div>
        )}
        {whiteDemandList &&
          whiteDemandList.length > 0 &&
          whiteDemandList.length > pageSize && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PaginationPage
                items={whiteDemandList && whiteDemandList}
                page={page}
                pageSize={pageSize}
                onChange={(value) => {
                  if (value) {
                    setPage(value);
                  }
                }}
                onTapAllShowButton={() => {
                  setPage(null);
                }}
              />
            </div>
          )}
      </div>
      <FilterWhiteModal
        show={show}
        handleClose={handleClose}
        approval={(value) => {
          if (value) {
            if (value.length == 2) {
              getAllShowWhite(null);
              setSelectedFilter([
                {
                  title: "Aktif",
                  id: 0,
                },
                {
                  title: "Pasif",
                  id: 1,
                },
              ]);
              setShow(false);
            } else {
              if (value[0].id == 0) {
                //aktif
                getAllShowWhite(true);
                setSelectedFilter([
                  {
                    title: "Aktif",
                    id: 0,
                  },
                ]);
                setShow(false);
              } else {
                //pasife aldım
                getAllShowWhite(false);
                setSelectedFilter([
                  {
                    title: "Pasif",
                    id: 1,
                  },
                ]);
                setShow(false);
              }
            }
          } else {
            setSelectedFilter([
              {
                title: "Aktif",
                id: 0,
              },
              {
                title: "Pasif",
                id: 1,
              },
            ]);
            setShow(false);
            getAllShowWhite(null);
          }
        }}
        demandFilterList={demandFilterList}
        selectedStatus={selectedFilter}
      />
      <DemandCopyApproved
        handleClose={handleCloseDemandCopy}
        show={showDemandCopy}
        demandItem={demandItem}
        approval={(value) => {
          if (value && value == true) {
            copyDemandItem(demandItem);
          }
        }}
        isOnIntern={false}
      />
      <PackageCandidateDemandUsageScreen
        show={showPackageUseScreen}
        handleClose={handlePackageUseScreenClose}
        candidateType={"white"}
      />
    </>
  );
};
//
export default WhiteAllShow;
