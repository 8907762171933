import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import Select from "react-select";
import { Alert, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropListAction } from "../../../../../../../../action";
import { getValue } from "@syncfusion/ej2-base";
import BitonisSingleDropdown from "../../../../../../../widgets/BitonisSingleDropdown";
import CustomDesign from "../../../../../../../widgets/CustomDesign";
import SelectCompanyToLocation from "../../../../../../../widgets/components/common/SelectCompanyToLocation";
import CustomButton from "../../../../../../../widgets/buttons/CustomButton";

function EditCompanyLocationModal({
  show,
  handleClose,
  companyList,
  job,
  onSubmit,
  props,
  register,
  customStyles,
  handleSubmit,
  control,
  setValue,
  errors,
  watch,
  isValid,
  labelStyle,
  getValues,
  searchCompanyList,
  isIntern,
}) {
  useEffect(() => {
    if (show) {
      setValue("companyId", {
        value: job.companyId,
        label: job.companyName,
      });
      setValue("companyLocationId", {
        value: job.companyLocationId,
        label: job.companyLocationName,
      });
    }
    if (getValues("companyId") && getValues("companyLocationId")) {
      isValid = true;
    } else {
      isValid = false;
    }
  }, [show]);
  return (
    <>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          }}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="full-screen-modal"
        >
          <Modal.Header closeButton style={{ borderBottom: "none" }}>
            <Modal.Title
              aria-labelledby="contained-modal-title-vcenter"
              style={{
                width: "100%",
                textAlign: "center",
                margin: "0 auto",
              }}
            >
              Görüşme Lokasyonu
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              marginBottom:"0px",
              paddingBottom:"15px"
            }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(onSubmit)();
                if (watch("companyId") && watch("companyLocationId")) {
                  handleClose();
                }
              }}
            >
              <SelectCompanyToLocation
                props={props}
                register={register}
                watch={watch}
                control={control}
                setValue={setValue}
                errors={errors}
                getValues={getValues}
                companyLabel={
                  isIntern && isIntern === true
                    ? "Stajyer Aradığınız Firma"
                    : "Eleman Aradığınız Firma"
                }
                companyLocationLabel={
                  isIntern && isIntern === true
                    ? "Görüşme Lokasyonu"
                    : "İş Görüşme Lokasyonu"
                }
                companyErrorMessage={
                  isIntern && isIntern === true
                    ? "Stajyer Aradığınız Firma Boş Geçilemez"
                    : "Eleman Aradığınız Firma Boş geçilemez"
                }
                companyLocationErrorMessage={
                  isIntern && isIntern === true
                    ? "Görüşme Lokasyonu Boş geçilemez"
                    : "İş Görüşme Lokasyonu Boş geçilemez"
                }
                companyPlaceholder={
                  isIntern && isIntern === true
                    ? "Stajyer Aradığınız Firma"
                    : "Eleman Aradığınız Firma"
                }
                companyLocationPlaceholder={
                  isIntern && isIntern === true
                    ? "Görüşme Lokasyonu"
                    : "İş Görüşme Lokasyonu"
                }
              />
            </form>
          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              alignContent: "center",
              borderTop: "none",
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(onSubmit)();
                if (watch("companyId") && watch("companyLocationId")) {
                  handleClose();
                }
              }}
              style={{
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <CustomButton
                title={"Kaydet"}
                width={{
                  xs: "380px",
                  sm: "380px",
                  md: "430px",
                  lg: "380px",
                  xl: "380px",
                }}
              />
            </form>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default EditCompanyLocationModal;
{
  
}
