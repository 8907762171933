import { Box, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import CustomDesign from "../../../../../widgets/CustomDesign";
import CustomButton from "../../../../../widgets/buttons/CustomButton";
import { DemandsServices } from "../../../../../../services";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ContactUs = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  function onSubmit(form) {
    form;
    var sendData = "Employer Report Problem And Get Support : " + form.message;
    DemandsServices.addNewPcc(sendData).then(
      (response) => {
        if (response) {
          if (response.succeeded === true) {
            toast.success(response.result.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {
                  history.push("/");
                }, 100);
              },
            });
          } else {
            toast.info(response.error.message, {
              autoClose: 2000,
              onClose: () => {
                setTimeout(() => {}, 100);
              },
            });
          }
        }
      },
      (error) => {}
    );
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        id="settingBody"
        style={{
          marginBottom: "0px",
          paddingBottom: "0px",
        }}
      >
        <div
          style={{
            marginBottom: "0px",
            paddingBottom: "0px",
            textAlign: "center",
          }}
        >
          <p
            style={{
              color: "#001542",
              fontWeight: "600",
              fontSize: "20px",
            }}
          >
            Bize Ulaşın
          </p>
        </div>
        <div
          className="card"
          style={{
            marginTop: "0px",
            paddingTop: "0px",
          }}
        >
          <div
            className="card-header"
            style={{
              borderBottom: "none",
              marginLeft: "18px",
              paddingLeft: "18px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p></p>
            </div>
          </div>
          <div
            className="card-body"
            style={{
              marginTop: "0px",
              paddingTop: "0px",
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                marginTop: "0px",
                paddingTop: "0px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    marginBottom: "15px",
                    fontWeight: 400,
                    color: "#001542",
                  }}
                >
                  Herhangi bir sorunuz, öneriniz veya yaşadığınız bir sorun için
                  bize buradan ulaşabilirsiniz.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    marginBottom: "15px",
                    fontWeight: "bold",
                    color: "#001542",
                  }}
                >
                  Ne İsteyebilirsiniz?
                </Typography>

                <ContactUsItem
                  title={"Destek:"}
                  subtitle={
                    "Teknik sorunlar veya uygulama ile ilgili yardımlar."
                  }
                />
                <ContactUsItem
                  title={"Öneriler:"}
                  subtitle={"Yeni özellikler veya geliştirme fikirleri."}
                />
                <ContactUsItem
                  title={"Geri Bildirim:"}
                  subtitle={"Kullanım deneyiminiz hakkında yorumlar."}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    marginBottom: "0px",
                    marginTop: "10px",
                    fontWeight: 400,
                    color: "#001542",
                  }}
                >
                  Formu doldurup gönderin, size en kısa sürede dönüş yapalım!
                </Typography>
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "100%",
                      md: "100%",
                      lg: "100%",
                      xl: "100%",
                    },
                  }}
                >
                  <textarea
                    className="form-control textAreaId"
                    id={"inputClass"}
                    rows="4"
                    name="message"
                    {...register("message", { required: true })}
                    style={{
                      fontSize: "13px",
                      color: "#001542",
                      opacity: "1px",
                      fontWeight: "400",
                      width: "100%",
                    }}
                    placeholder="Mesajınız"
                  />
                  {errors.message && (
                    <div className="text-danger fs-12">Mesaj Boş Olamaz</div>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  margin: "15px",
                }}
              >
                <CustomButton
                  onTap={(val) => {}}
                  title={"Gönder"}
                  width={{
                    xs: "400px",
                    sm: "400px",
                    md: "400px",
                    lg: "400px",
                    xl: "400px",
                  }}
                />
              </Box>
            </form>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

const ContactUsItem = ({ title, subtitle }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "flex-start",
        alignItems: "flex-start",
        margin: "0px 5px 5px 5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "10px",
            height: "10px",
            borderRadius: "50%",
            backgroundColor: "#001542",
            marginRight: "10px",
          }}
        ></Box>
        <Typography
          sx={{
            fontSize: {
              xs: "15px",
              sm: "15px",
              md: "15px",
              lg: "15px",
              xl: "15px",
            },

            fontWeight: 500,
            color: "#001542",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Typography
        sx={{
          fontSize: {
            xs: "15px",
            sm: "15px",
            md: "15px",
            lg: "15px",
            xl: "15px",
          },
          marginLeft: "5px",
          fontWeight: 400,
          color: "#001542",
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default ContactUs;
