import React, { useEffect, useState } from "react";
import { DemandsServices } from "../../../../../../../../services";
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import ItemByStatus from "./widgets/ItemByStatus";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { setFilterWhite } from "../../../../../../../../redux/redux-toolkit/filterMatch";
import MatchNotFound from "../BlueMatchShow/MatchNotFound";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";
import { ToastContainer } from "react-toastify";
import WhiteDemandItem from "../../DemandAllShow/widgets/WhiteDemandItem";
import DemandNotFound from "../../DemandAllShow/Common/DemandNotFound";
import WhiteDemandMatchPageItem from "../../DemandAllShow/widgets/WhiteDemandMatchPageItem";
import { toast } from "react-toastify";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { DoneOutline, DoneOutlineRounded } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import AvatarGlow from "../../../../../../../widgets/components/AvatarGlow";
import PackageCandidateDemandUsageScreen from "../../DemandAllShow/PackageCandidateDemandUsageScreen";
const ShowWhiteMatchShow = () => {
  useCurrentUserActionPageLog(15, null); //loglama bilgisini apiye gönderir
  const dispatch = useDispatch();
  const [whiteDemandList, setWhiteDemandList] = useState(null);
  const [show, setShow] = useState(null);
  const [search, setSearch] = useState("");
  const [showDemandCopy, setShowDemandCopy] = useState(null);
  const [demandItem, setDemandItem] = useState(null);
  function handleCloseDemandCopy() {
    setShowDemandCopy(false);
  }
  const [showPackageUseScreen, setShowPackageUseScreen] = useState(null);

  function handlePackageUseScreenClose() {
    setShowPackageUseScreen(false);
  }
  var demandFilterList = [
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ];
  const [selectedFilter, setSelectedFilter] = useState([
    {
      title: "Aktif",
      id: 0,
    },
    {
      title: "Pasif",
      id: 1,
    },
  ]);
  useEffect(() => {
    getAllShowWhite(null, true);
  }, []);

  function getAllShowWhite(isActive, loadingPage) {
    var sendData = { isActive: isActive, positionType: 2 };
    DemandsServices.demandsWhite(sendData).then(
      (demand) => {
        if (demand && demand.succeeded === true) {
          setWhiteDemandList([]);
          demand.data.map((value) => {
            setWhiteDemandList((prev) => [...prev, value]);
          });
          if (loadingPage && loadingPage === true) {
          } else {
            if (isActive == null) {
              setPage(null);
            }
          }
        }
      },
      (error) => {}
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  function handleClose() {
    setShow(false);
  }

  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    whiteDemandList && page === null
      ? whiteDemandList
      : whiteDemandList &&
        whiteDemandList.slice((page - 1) * pageSize, page * pageSize);
  return (
    <>
      <div>
      {whiteDemandList && whiteDemandList.length >= 0 && (
          <Tooltip
            title="Tıklayın"
            arrow
            sx={{
              "&:hover": {
                color: "white", // Hover sırasında metin rengi değiştir
              },
            }}
          >
            <Fab
              onClick={() => {
                setShowPackageUseScreen(true);
              }}
              sx={{
                position: "fixed", // absolute yerine fixed kullanıldı
                bottom: (theme) => theme.spacing(3), // Alt köşeye sabitler
                right: {
                  xs: "0px",
                  sm: "0px",
                  md: "20px",
                  lg: "35px",
                  xl: "35px",
                },
                backgroundColor: "white",
                border: "1px dotted #001542",
              }}
              color="transparent"
            >
              <AvatarGlow
                initialRadius={25}
                valueMaxScale={4}
                glowColor="#001542"
                waveDuration={3}
                startColor="#001542"
                endColor="#001542"
                width={50}
                height={50}
              >
                <Fab
                  sx={{
                    position: "relative", // İç bileşende position relative kullanımı
                    backgroundColor: "white",
                    width: "50px",
                    height: "50px",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "#001542",
                    },
                  }}
                  color="primary"
                >
                  <DoneOutlineRounded
                    sx={{
                      color: "#001542",
                      "&:hover": {
                        color: "#001542",
                      },
                    }}
                  />
                </Fab>
              </AvatarGlow>
            </Fab>
          </Tooltip>
        )}
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="Pozisyona göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />
          </InputGroup>
        </div>
        {whiteDemandList === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : whiteDemandList && paginatedList && paginatedList.length > 0 ? (
          <div>
            {paginatedList &&
              paginatedList.map((demand, index) => {
                return paginatedList &&
                  paginatedList[index].positionName
                    .toLowerCase()
                    .includes(search.toLowerCase()) ? (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <WhiteDemandMatchPageItem
                      onTap={() => {
                        if (demand.didDemandExpired === false) {
                          const dataMap = new Map();
                          dataMap.set("jobId", demand.jobId);
                          dataMap.set("positionName", demand.positionName);
                          history.push({
                            pathname: "/demands/whiteCurrentDemandMatchShow",
                            state: { dataMap },
                          });
                        } else {
                          toast.error(
                            "Talebinizle eşleşen adayları görme süreniz doldu. Yeni bir talep oluşturarak eşleşme sağlayabilirsiniz.",
                            {
                              autoClose: 5000,
                              onClose: () => {
                                setTimeout(() => {}, 1000);
                              },
                            }
                          );
                        }
                      }}
                      demand={demand}
                    />
                  </div>
                ) : (
                  <div></div>
                );
              })}
          </div>
        ) : (
          <div>
            <MatchNotFound newMatch={true} />
          </div>
        )}
        {whiteDemandList &&
          whiteDemandList.length > 0 &&
          whiteDemandList.length > pageSize && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PaginationPage
                items={whiteDemandList && whiteDemandList}
                page={page}
                pageSize={pageSize}
                onChange={(value) => {
                  if (value) {
                    setPage(value);
                  }
                }}
                onTapAllShowButton={() => {
                  setPage(null);
                }}
              />
            </div>
          )}
      </div>
      <PackageCandidateDemandUsageScreen
        show={showPackageUseScreen}
        handleClose={handlePackageUseScreenClose}
        candidateType={"white"}
      />
    </>
  );
};

export default ShowWhiteMatchShow;

/*
import React, { useEffect, useState } from "react";
import { DemandsServices } from "../../../../../../../../services";
import noMatchFound from "../../../../../../../../images/svg/noMatchFound.svg";
import { Form, InputGroup, Dropdown, Button } from "react-bootstrap";
import ItemByStatus from "./widgets/ItemByStatus";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { setFilterWhite } from "../../../../../../../../redux/redux-toolkit/filterMatch";
import MatchNotFound from "../BlueMatchShow/MatchNotFound";
import PaginationPage from "../../../../../../../widgets/PaginationPage";
import LoadingDotsAnimation from "../../../../../../../widgets/LoadingDotsAnimation";
import useCurrentUserActionPageLog from "../../../../../../../hooks/useCurrentUserActionPageLog";

const ShowWhiteMatchShow = () => {
  useCurrentUserActionPageLog(15, null); //loglama bilgisini apiye gönderir

  var matchSelectedList = [
    { title: "Yeni Eşleşme", status: 0 },
    /*{ title: "Beklemede", status: 1 },
    { title: "İşyerinde Görüşme Günü Onay Bekliyor", status: 2 },
    { title: "İşyerinde Görüşme Günü Onaylandı", status: 3 },
    { title: "Ek Video CV Bekleniyor", status: 4 },
    { title: "Görüşme Reddedildi", status: 5 },
    { title: "Video Konf. Görüşme Günü Onay Bekliyor", status: 8 },
    { title: "Video Konf. Görüşme Günü Onaylandı", status: 9 },
    { title: "Gelen Görüşme Günü Onaylandı", status: 10 },
    { title: "İş Arayan Ek Video CV'sini Gönderdi", status: 12 }
    
  ];
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  const [demandWhiteMatchData, setDemandWhiteMatchData] = useState(null);

  // const { whiteSelectedStatus } = useSelector((state) => state.selectedTab);
  useEffect(() => {
    getMatchWhite();
  }, []);
  function getMatchWhite() {
    DemandsServices.demandMatchWhite([{ status: 0 }]).then(
      (demandMatch) => {
        if (demandMatch && demandMatch.succeeded === true) {
          setDemandWhiteMatchData([]);
          setDemandWhiteMatchData(demandMatch.data.pagedData);
        }
      },
      (error) => {}
    );
  }
  function searchHandleCandidate(value) {
    setSearch(value);
  }

  const [show, setShow] = useState(null);
  function handleClose() {
    setShow(false);
  }
  const history = useHistory();
  const [page, setPage] = useState(1);
  const pageSize = 10; // Her sayfada gösterilecek eleman sayısı

  const paginatedList =
    demandWhiteMatchData && page === null
      ? demandWhiteMatchData
      : demandWhiteMatchData &&
        demandWhiteMatchData.slice((page - 1) * pageSize, page * pageSize);

  return (
    <>
      <div>
        <div className="">
          <InputGroup className="mb-3" style={{}}>
            <Form.Control
              style={{
                borderRadius: "20px",
                backgroundColor: "#F5F5F5",
                marginLeft: "10px",
              }}
              type="search"
              placeholder="İsme göre ara"
              value={search}
              onChange={(e) => searchHandleCandidate(e.target.value)}
            />

          
          </InputGroup>
        </div>

        {demandWhiteMatchData === null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <LoadingDotsAnimation />
          </div>
        ) : demandWhiteMatchData &&
          paginatedList &&
          paginatedList.length > 0 ? (
          paginatedList && (
            <div
              style={{
                marginBottom: "10%",
                marginTop: "3%",
              }}
            >
              {paginatedList.map((matchData, index) => {
                return paginatedList[index].positionName
                  .toLowerCase()
                  .includes(search.toLowerCase()) ? (
                  <div
                    onClick={() => {
                      const dataMap = new Map();
                      dataMap.set("candidateId", matchData.candidateId);
                      dataMap.set("matchId", matchData.matchId);
                      dataMap.set("status", matchData.status);
                      dataMap.set("cameWithoutMatchingPage", true);
                      history.push({
                        pathname: "matchShow/whiteMatchDetail",
                        state: { dataMap },
                      });
                    }}
                  >
                    <ItemByStatus
                      matchData={matchData}
                      key={matchData}
                      comeCurrentDemand={false}
                    />
                  </div>
                ) : (
                  <div></div>
                );
              })}{" "}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {demandWhiteMatchData &&
                  demandWhiteMatchData.length > 0 &&
                  demandWhiteMatchData.length > pageSize && (
                    <PaginationPage
                      items={demandWhiteMatchData}
                      page={page}
                      pageSize={pageSize}
                      onChange={(value) => {
                        if (value) {
                          setPage(value);
                        }
                      }}
                      onTapAllShowButton={() => {
                        setPage(null);
                      }}
                    />
                  )}
              </div>
            </div>
          )
        ) : (
          <MatchNotFound title={""} newMatch={true} />
        )}
      </div>
      
    </>
  );
};

export default ShowWhiteMatchShow;


*/
