import React, { useState } from "react";
import moment from "moment";
import CustomPreview from "../../../../../../../../widgets/CustomPreview";
import CustomPreviewShowPoint from "../../../../../../../../widgets/CustomPreviewShowPoint";
import { Box, Typography } from "@mui/material";
import PageInfoTitle from "../../../../../../../../widgets/PageInfoTitle";
import DividerDotted from "../../../../../../../../widgets/DividerDotted";

const BlueInformation = ({
  cardStyle,
  labelStyle,
  inputStyle,
  blueMatchDetail,
  componentRef,
  matchPointStatus,
  userImage,
}) => {
  return (
    <div>
      <>
        <div
          class=""
          style={{
            padding: "10px",
            margin:"20px"
          }}
        >
          <div
            class="card-header"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderBottom: "none",
              marginBottom: "0px",
              paddingBottom: "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "column",
                  xl: "column",
                },
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    border: "5px solid #001542",
                    borderRadius: "50%",
                    width: "112px",
                    height: "112px",
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexDirection: "column",
                    position: "relative",
                    marginBottom: "0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",

                      marginBottom: "0px",
                    }}
                  >
                    {blueMatchDetail &&
                      blueMatchDetail.profileImageUrl !== null ? (
                      <img
                        src={userImage && userImage}
                        style={{
                          objectFit: "cover",
                          width: "98px",
                          height: "98px",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "50%",
                        }}
                      ></img>
                    ) : (
                      <i
                        class="fa-regular fa-user"
                        style={{
                          fontSize: "30px",
                        }}
                      ></i>
                    )}
                  </div>
                </div>

                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: 450,
                    color: {
                      xs: "#001542",
                      sm: "#001542",
                      md: "#001542",
                      lg: "#001542",
                      xl: "#001542",
                    },
                    marginBottom: "10px",
                    marginTop: "5px",
                  }}
                >
                  {" "}
                  {blueMatchDetail && blueMatchDetail.candidateName}{" "}
                  {blueMatchDetail && blueMatchDetail.mobilephone && (
                <a
                style={{
                  fontSize: "14px",
                  display: 'flex',
                  flexDirection: 'column',
                  fontWeight: 400,
                  color: "#001542",
                  marginTop: "0px",
                  padding: "0px",
                  width: "100%",
                  textAlign:"center",
              
                }}
              >📱
                {blueMatchDetail && blueMatchDetail.mobilephone}
              </a>
              )}
              
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "space-between",
                  alignItems: "space-between",
                  margin: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",

                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "14px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                      fontWeight: 500,
                      color: {
                        xs: "#001542",
                        sm: "#001542",
                        md: "#001542",
                        lg: "#001542",
                        xl: "#001542",
                      },
                      marginBottom: "0px",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Eşleştiği Pozisyon
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "14px",
                      },

                      textAlign: "center",
                      fontWeight: 400,
                      color: {
                        xs: "#001542",
                        sm: "#001542",
                        md: "#001542",
                        lg: "#001542",
                        xl: "#001542",
                      },
                      margin: "0px",
                      width: {
                        xs: "230px",
                        sm: "250px",
                        md: "300px",
                        lg: "300px",
                        xl: "300px",
                      },
                    }}
                  >
                    {blueMatchDetail &&
                      blueMatchDetail.matchedJobList &&
                      blueMatchDetail.matchedJobList
                        .map((val) => val.jobPosition)
                        .join(", ")}
                  </Typography>
                </Box>{" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    margin: "10px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "14px",
                        sm: "15px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                      fontWeight: 500,
                      color: {
                        xs: "#001542",
                        sm: "#001542",
                        md: "#001542",
                        lg: "#001542",
                        xl: "#001542",
                      },
                      width: "100%",
                      marginBottom: "0px",
                      textAlign: "center",
                    }}
                  >
                    Çalışma Şekli
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "14px",
                        lg: "14px",
                        xl: "14px",
                      },

                      textAlign: "center",
                      fontWeight: 400,
                      color: {
                        xs: "#001542",
                        sm: "#001542",
                        md: "#001542",
                        lg: "#001542",
                        xl: "#001542",
                      },
                      margin: "0px",
                      width: {
                        xs: "230px",
                        sm: "250px",
                        md: "300px",
                        lg: "300px",
                        xl: "300px",
                      },
                    }}
                  >
                    {blueMatchDetail &&
                      blueMatchDetail.wantToWorkPositions &&
                      blueMatchDetail.wantToWorkPositions.workTypes
                        .map((val) => val.workTypeName)
                        .join(", ")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </div>

          <div
            class="card-body"
            style={{
              margin: "0px",
              padding: "0px",
            }}
          >
            {
              blueMatchDetail && blueMatchDetail.aboutNote && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                      margin: "0px 0px 5px 0px",
                    }}
                  >
                    <PageInfoTitle title={"Hakkında"} bottomPadding={"0px"} />
                  </div>
                  <Box
                    sx={{
                      border: "2px dotted #fdc303",
                      borderRadius: "8px",
                      padding: "10px",
                      margin: "0px 20px 15px 15px",
                      backgroundColor: "#F7F7F7",
                    }}
                  >
                    {
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "14px",
                            md: "14px",
                            lg: "14px",
                            xl: "14px",
                          },
                          fontWeight: 450,
                          color: {
                            xs: "#001542",
                            sm: "#001542",
                            md: "#001542",
                            lg: "#001542",
                            xl: "#001542",
                          },
                        }}
                      >
                        {" "}
                        {blueMatchDetail && blueMatchDetail.aboutNote}{" "}
                      </Typography>
                    }
                  </Box>
                </div>
              )
            }
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
                margin: "10px",
              }}
            >
              <PageInfoTitle title={"Kişisel Bilgileri "} />
            </div>
           
            <CustomPreview
              name={"Uyruk"}
              value={blueMatchDetail && blueMatchDetail.nationalityName}
            />{" "}
            <CustomPreview
              name={"Cinsiyet"}
              value={blueMatchDetail && blueMatchDetail.genderName}
            />
            {blueMatchDetail && blueMatchDetail.genderId === 1 ? (
              <div></div>
            ) : (
              <CustomPreview
                name={"Askerlik Durumu"}
                value={blueMatchDetail && blueMatchDetail.militaryStatusName}
              />
            )}
            <CustomPreview
              name={"Medeni Hal"}
              value={blueMatchDetail && blueMatchDetail.maritalStatusName}
            />
             <CustomPreview
              name={"Yaş"}
              value={
                blueMatchDetail &&
                blueMatchDetail.birthDate &&
                blueMatchDetail.birthDate.toString()
              }
            />
            <CustomPreview
              name={"Sigara Kullanımı"}
              value={blueMatchDetail && blueMatchDetail.isSmokingName}
            />
            <CustomPreview
              name={"Sürücü Belgesi"}
              value={blueMatchDetail && blueMatchDetail.driverLicenseName}
            />
            {blueMatchDetail &&
              blueMatchDetail.driverLicenseTypeName &&
              blueMatchDetail.driverLicenseTypeName.length > 0 && (
                <CustomPreview
                  name={"Sürücü Belgesi Türü"}
                  value={blueMatchDetail.driverLicenseTypeName
                    .map((val) => val.drivingLicenceTypeName)
                    .join(", ")}
                />
              )}{" "}
            <CustomPreview
              name={"Eğitim Durumu"}
              value={blueMatchDetail && blueMatchDetail.educationName}
            />
            <CustomPreview
              name={"Toplam Deneyim"}
              value={blueMatchDetail && blueMatchDetail.totalWorkExperience}
            />{" "}
            <CustomPreview
              name={"Engellilik Durumu"}
              value={blueMatchDetail && blueMatchDetail.disabilityName}
            />{" "}
            {(blueMatchDetail && blueMatchDetail.disabilityId == 1) ||
              (blueMatchDetail && blueMatchDetail.disabilityId == 0) ? (
              <div></div>
            ) : (
              <CustomPreview
                name={"Engellilik Seviyesi"}
                value={blueMatchDetail && blueMatchDetail.disabilityDegreeName}
              />
            )}
            {blueMatchDetail &&
              blueMatchDetail.programs &&
              blueMatchDetail.programs.length > 0 && (
                <CustomPreviewShowPoint
                  name={"Programlar"}
                  value={
                    blueMatchDetail &&
                    blueMatchDetail.programs
                      .map((val) => val.programName)
                      .join(",")
                  }
                  matchPointStatus={
                    matchPointStatus &&
                      matchPointStatus.isProgramsMatched == false
                      ? true
                      : false
                  }
                />
              )}{" "}
            {blueMatchDetail &&
              blueMatchDetail.certificates &&
              blueMatchDetail.certificates.length > 0 && (
                <CustomPreviewShowPoint
                  name={"Sertifikalar"}
                  value={
                    blueMatchDetail &&
                    blueMatchDetail.certificates
                      .map((val) => val.certificateName)
                      .join(",")
                  }
                  matchPointStatus={
                    matchPointStatus &&
                      matchPointStatus.isCertificatesMatched == false
                      ? true
                      : false
                  }
                />
              )}
            {blueMatchDetail &&
              blueMatchDetail.competencies &&
              blueMatchDetail.competencies.length > 0 && (
                <CustomPreviewShowPoint
                  name={"Yetkinlikler"}
                  value={
                    blueMatchDetail &&
                    blueMatchDetail.competencies
                      .map((val) => val.competenceName)
                      .join(",")
                  }
                  matchPointStatus={
                    matchPointStatus &&
                      matchPointStatus.isCompetencesMatched == false
                      ? true
                      : false
                  }
                />
              )}
          </div>
        </div>{" "}
        <div
          style={{
            display: "none",
          }}
        ></div>
      </>
    </div>
  );
};

export default BlueInformation;
