//*** Date:07-05-2024/ Code Writer Developer HÜSEYİN TELLİ ***
import { useEffect, useRef, useState } from "react";
import {
  TextField,
  Autocomplete,
  Checkbox,
  InputAdornment,
  Typography,
  Box,
} from "@mui/material";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import "./BitonisDropdownStyle.css";
import { customStylesMulti } from "../customStyleInline";
import useIsMobile from "../hooks/useIsMobile";
const BitonisSingleDropdown = ({
  items,
  onValueChanged,
  errors,
  errorMessage,
  register,
  companyClassName,
  control,
  watch, //bunları dışarıdan almamın nedeni yapılacak kontrolleri doğru bir şekilde ilgili valueye göre yapmam gerekiyor
  setValue,
  required,
  placeholder,
  getValues,
  defaultValue,
  infoText,
  showRightBox,
  onRightBoxTap,
}) => {
  const isMobile = useIsMobile();
  var initialValue = watch(companyClassName);

  useEffect(() => {
    // if (watch(companyClassName)) {
    //     const selectedItemsArray = (watch(companyClassName) as any[]).map((item) => items.find((i) => i.id === item.id));
    //     const list = selectedItemsArray.filter(item => Boolean(item));
    //     setSelected(list as any[]);
    // }
  }, [companyClassName, items, watch]);

  //-----------------------------------------------
  function changeSelected(_, e) {
    return e;
  }

  return (
    <div style={{}}>
      <div
        style={{
          borderRadius: "5px",
          marginBottom: "5px",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        {items && (
          <Controller
            name={companyClassName}
            control={control}
            defaultValue={
              defaultValue === null ||
                defaultValue === undefined ||
                defaultValue === ""
                ? ""
                : defaultValue
            }
            rules={{
              required: Boolean(required),
            }}
            render={({ field }) => {
              return (
                <Autocomplete
                  isOptionEqualToValue={(a, b) => a.value === b.value}
                  options={items}
                  onChange={(event, value) => {
                    const newValue = changeSelected(event, value);
                    field.onChange(newValue);
                    onValueChanged(newValue);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },

                    "& .MuiInputBase-root": {
                      overflowX: "hidden",
                    },
                    "& .MuiAutocomplete-inputRoot": {
                      flexWrap: "nowrap",
                      cursor: "pointer",
                    },
                    "&& .MuiAutocomplete-input": {
                      minWidth: 0,
                      width: 0,
                      overflowX: "scroll",
                    },
                    display: "flex",
                    alignItems: "center",

                    width: {
                      xs: showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "100%"
                        : "90%",
                      sm: showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "100%"
                        : "95%",
                      md: showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "100%"
                        : "95%",
                      lg: showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "100%"
                        : "95%",
                      xl: showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "100%"
                        : "95%",

                    },



                    backgroundColor: "#fcfcfc",
                    borderRadius:
                      showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "6px"
                        : "6px 0px 0px 6px",

                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    border: "1px solid #f3f3f6",
                    marginRight:
                      showRightBox === null ||
                        showRightBox === undefined ||
                        showRightBox === false
                        ? "0px"
                        : "0px",
                    border: "1px solid #dcdcdd",
                  }}
                  value={field.value || null}
                  getOptionLabel={(option) => option && option.label}
                  noOptionsText="Bulunamadı"
                  blurOnSelect={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder={placeholder}
                      className="custom-placeholder-text"
                      sx={
                        ({
                          "& input::placeholder": {
                            color: "black",
                            fontWeight: 500,
                          },
                          "& .MuiAutocomplete-tag": {
                            color: "#001542",
                            borderRadius: "5px",
                            fontWeight: 400,
                            whiteSpace: "nowrap",
                            display: "inline-flex",
                          },
                        },
                        {
                          input: {
                            cursor: "pointer",
                            fontWeight: 410,
                            color: "#001542",
                            fontSize: isMobile ? "12px" : "14px",
                            "::placeholder": {
                              fontSize: isMobile ? "11px" : "13px",
                              fontWeight: 500,
                              color: "#001542",
                            },
                            caretColor: "red",
                          },
                        })
                      }
                    />
                  )}
                  renderOption={(props, option, e) => {
                    var disable = false;
                    return (
                      <>
                        {" "}
                        {e && e.index === 0 && infoText && (
                          <li
                            style={{
                              pointerEvents: "none",
                              padding: "0px",
                              borderRadius: "8px",
                              margin: "0px 0px 0px 5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignContent: "center",
                                alignItems: "center",
                                justifyItems: "center",
                                margin: "5px 0px 0px 5px",
                              }}
                            >
                              <div
                                style={{
                                  width: "17px",
                                  height: "17px",
                                  backgroundColor: "orange",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  borderRadius: "2px",
                                }}
                              >
                                <i
                                  class="fa-solid fa-exclamation"
                                  style={{
                                    color: "white",
                                    fontSize: "12px",
                                  }}
                                ></i>
                              </div>
                              <p
                                style={{
                                  color: "001542",
                                  fontWeight: "600px",
                                  fontSize: isMobile ? "10px" : "12px",
                                  padding: "10px",
                                  margin: "0px",
                                }}
                              >
                                {infoText}
                              </p>
                            </div>
                          </li>
                        )}
                        <li
                          {...props}
                          style={{
                            opacity: disable ? 0.4 : 1,
                            pointerEvents: disable ? "none" : "all",

                            fontSize: isMobile ? "11px" : "13px",
                            fontWeight: 405,
                            padding: "10px",
                            color: "#001542",
                            display: "flex",
                            flexDirection: "row",

                            justifyContent: "space-between",
                          }}
                        >
                          {option.label}
                          <div>
                            {e.selected && (
                              <i
                                class="fa-solid fa-check"
                                style={{
                                  marginLeft: "40px",
                                }}
                              ></i>
                            )}
                          </div>
                        </li>
                      </>
                    );
                  }}
                  loading
                />
              );
            }}
          />
        )}
        
        {showRightBox &&items &&  (
          <Box
            onClick={
              onRightBoxTap === undefined || onRightBoxTap === null
                ? () => { }
                : onRightBoxTap
            }
            sx={{
              width: {
                xs: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "10%",
                sm: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "10%",
                md: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "8%",
                lg: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "8%",
                xl: showRightBox === null ||
                  showRightBox === undefined ||
                  showRightBox === false
                  ? "0%"
                  : "8%",

              },
              backgroundColor: "#fdc303",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              marginLeft: "0px",
              borderRadius: "0px 4px 4px 0px",
              cursor: "pointer",
            }}
          >
            <i
              class="fa-solid fa-exclamation"
              style={{
                fontSize: "20px",
                color: "white",
              }}
            ></i>
          </Box>
        )}
      </div>{" "}
      {required && errors[companyClassName] && (
        <div className="text-danger fs-12 mb-2">{errorMessage}</div>
      )}
    </div>
  );
};

export default BitonisSingleDropdown;
{
  /*
     PaperComponent={({ children }) => (
      <ul
        style={{
          margin: 0,
          padding: 0,
          listStyle: "none",
          backgroundColor: "white",
          borderRadius: 4, // Optional: add border radius
          border: "1px solid white",
          marginTop: "5px",
          boxShadow: "0 3px 10px rgb(0 0 0 / 0.5)",
        }}
      >
        {children}
      </ul>
    )}
    */
}
