import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../../../utility/Utils";

const InfoDemandAllShow = ({ onTap }) => {
  const [packageInformation, setPackageInformation] = useState(null);

  useEffect(() => {
    getCurrentPackets();
  }, []);

  async function getCurrentPackets() {
    setPackageInformation(null);
    api
      .get("payment/getCompanyPermission")
      .then((response) => {
        if (response.succeeded === true) {
          var data = response.data;
          setPackageInformation(data);
        }
      })
      .catch((err) => {});
  }

  return (
    <div
      onClick={onTap === undefined || onTap === null ? (val) => {} : onTap}
      style={{
        cursor: "pointer",
      }}
    >
      {packageInformation && packageInformation.currentPlanId === 5 ? (
        <div></div>
      ) : (
        packageInformation && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignContent: "flex-start",
              alignItems: "flex-start",
              backgroundColor: "#f5f5f5",
              padding: "5px",
              borderRadius: "8px",
              marginBottom: "0px",
              marginTop: "0px",
              width: "100%",
              position: "relative",
            }}
          >
            {packageInformation && (
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                    backgroundColor: "#f5f5f5",
                    padding: "5px 10px 5px 10px",
                    borderRadius: "8px",
                    marginBottom: "0px",
                    marginTop: "0px",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "flex-start",
                      alignItems: "flex-start",
                      marginLeft: "0px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "12px",
                          lg: "14px",
                          xl: "14px",
                        },
                        fontWeight: "600px",
                        color: "#001542",
                      }}
                    >
                      Mevcut paketiniz:
                    </Typography>{" "}
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "12px",
                          lg: "14px",
                          xl: "14px",
                        },
                        fontWeight: "bold",
                        color: "red",
                        marginLeft: "5px",
                      }}
                    >
                      {packageInformation && packageInformation.currentPlanName}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "12px",
                          lg: "14px",
                          xl: "14px",
                        },
                        fontWeight: "600px",
                        color: "#001542",

                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      Bu paket ile istediğiniz{" "}
                      <span
                        style={{
                          color: "red",
                          fontWeight: "bold",
                        }}
                      >
                        {packageInformation && packageInformation.creditLeft}{" "}
                        talebi
                      </span>{" "}
                      onaylayabilir ve eşleşen adayların detaylı bilgilerini
                      görüntüleyebilirsiniz.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <Box
              sx={{
                position: "absolute",
                right: {
                  xs: "10px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px",
                },
                top: "5px",
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "20px",
                    sm: "20px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  fontWeight: "600px",
                }}
              >
                📌
              </Typography>{" "}
            </Box>
          </Box>
        )
      )}
    </div>
  );
};

export default InfoDemandAllShow;
