import Modal from "react-bootstrap/Modal"; // Varsayılan olarak React Bootstrap Modal bileşeni kullanılıyor
import React, { useEffect, useMemo, useState } from "react";
import { Card } from "react-bootstrap";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CustomButton from "../../buttons/CustomButton";
import imageLogo from "../../../../images/btnislogo.svg";
import "./infohomemodal.css";
const InfoHomeModal = ({ show, handleClose }) => {
  const history = useHistory();
  return (
    <div
      onClick={(e) => {
   
        e.stopPropagation();
        handleClose();
      }}
    >
      <>
        <div>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static" // Modal'ın dışına tıklanarak kapanmasını engeller
            keyboard={false} // Escape tuşuna basarak kapanmasını engeller
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="custom-modal"
          >
            <Modal.Header
              closeButton
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ borderBottom: "0px" }}
            ></Modal.Header>

            <Modal.Body
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                margin: "0px",
                padding: "0px",
                marginTop: "15px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Box
                component="img"
                src={imageLogo}
                alt={``}
                sx={{
                  width: "80px",
                  height: "80px",
                  marginBottom:"10px",
                }}
              />
              <Typography
                sx={{
                  fontSize: {
                    xs: "19px",
                    sm: "19px",
                    md: "19px",
                    lg: "19px",
                    xl: "19px",
                  },
                  fontWeight: "550",
                  color: "#001542",
                  textAlign: "center",
                  marginBottom:"10px",

                }}
              >
                Ücretsiz Talep Ve Sınırsız Eşleşme Sizi Bekliyor! 
              </Typography>
              <Box
                sx={{
                  margin: "0px 60px 20px 60px",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },

                    color: "#001542",
                    marginBottom: "5px",
                  }}
                >
                  Bitoniş'te talep oluşturma
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "18px",
                      md: "18px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "bold",

                    color: "#001542",
                    textAlign: "center",
                  }}
                >
                  ücretsiz!
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "16px",
                      sm: "16px",
                      md: "16px",
                      lg: "16px",
                      xl: "16px",
                    },
                    color: "#001542",
                    textAlign: "center",
                  }}
                >
                  Sınırsız sayıda talep oluşturabilir ve her talebiniz için
                  sınırsız eşleşme alabilirsiniz.
                </Typography>
              </Box>
              <Box
                sx={{
                  margin: "0px 10px 25px 10px",
                }}
              >
                <CustomButton
                  showShadow={true}
                  hoverBackgroundColor={"#001542"}
                  backgroundColor={"#001542"}
                  height={55}
                  onTap={() => {
                    history.push("findJobSeeker/blueJobSeeker")
                    handleClose();
                  }}
                  title={"Hemen Talep Oluştur"}
                  width={{
                    xs: "300px",
                    sm: "300px",
                    md: "300px",
                    lg: "350px",
                    xl: "350px",
                  }}
                />
              </Box>
            </Modal.Body>
          </Modal>
        </div>
      </>
    </div>
  );
};

export default InfoHomeModal;
